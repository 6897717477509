import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EnseignantService } from '../../services/enseignant.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from 'src/app/modules/formation/services/niveau.service';


@Component({
  selector: 'app-create-enseignant',
  templateUrl: './create-enseignant.component.html',
  styleUrls: ['./create-enseignant.component.scss']
})
export class CreateEnseignantComponent implements OnInit {

  public enseignantForm: FormGroup;
  error: string;
  success: string;
  isButton : boolean = true;
  niveaux;
  enseignant:
  {

 };

 submitted = false;
 cinExist: number;
 message: '';

idUser = localStorage.getItem('id');
idEnseignant = localStorage.getItem('userable_id');
departement = 'departement';

  constructor(
      private enseignantService: EnseignantService,
      private toastr: ToastrService,
      private router : Router,
      private formBuilder: FormBuilder
    ) {

     }

  ngOnInit() {

    // let email = localStorage.getItem('email');
    // console.log(email);

    this.success = null;
    this.error = null;
    this.enseignantForm = this.formBuilder.group({
      'cin': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      'email': [null, [Validators.required,
        Validators.minLength(10), Validators.maxLength(50),
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      'nom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      'prenom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      'tel': [null, [Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15)]]
    });
  }



  get f() { return this.enseignantForm.controls; }

  createEnseignant() {

    if (document.activeElement.id === 'finish') {
        this.submitted = true;

        if (this.enseignantForm.invalid ) {
          this.error = 'invalide Form  !';
          console.log(this.enseignantForm);
          return;
        } else {
              const formData = this.enseignantForm.value;

              this.success = null;
              this.error = null;
              this.enseignantService.createEnseignant(formData).subscribe(

                (data) => {

                    this.showNotification('top', 'left', 'L\'invitation a été <b>bien Envoyée</b>.'),
                    this.router.navigate(['/enseignants', 'Enseignant']);

                },

              (error) => error
              );
        }


    }

    }

    showNotification(from, align, message) {

      const color = Math.floor((Math.random() * 5) + 1);

      this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
        timeOut: 6000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-' + from + '-' +  align
         });
      }
}

