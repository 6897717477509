import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjetService } from '../services/projet.service';
import { Projet } from '../models/projet.model';
import  saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from '../../formation/services/niveau.service';
import { FormationService } from '../../formation/services/formation.service';

@Component({
  selector: 'app-gerer-projets',
  templateUrl: './gerer-projets.component.html',
  styleUrls: ['./gerer-projets.component.scss']
})
export class GererProjetsComponent implements OnInit {

  projets;
  idUser = localStorage.getItem('id');
  profil = localStorage.getItem('profil');
  name = localStorage.getItem('name');
  niveau;
  niveauExist = false;
  idNiveau;
  codeNiveau;
  type = 'type';
  formation = 'formation';
  formations;

  constructor(

    private route: ActivatedRoute,
    private projetService: ProjetService,
    private toastr: ToastrService,
    private niveauService: NiveauService,
    private formationService: FormationService

  ) {

   }

  ngOnInit() {

    this.projetService.getProjetsByUser(this.idUser).subscribe(res => {
      this.projets = res;

    }, err => {
      console.log(err);
    });


    if (this.profil === 'Administrateur') {

      this.formationService.getFormations().subscribe(

        res => this.formations = res,
        error => console.log(error),
      );
    }
  }
  calculateDiff(dateSent1,dateSent2){
    let currentDate = new Date();
    dateSent1 = new Date(dateSent1);
    dateSent2 = new Date(dateSent2)

    return Math.floor(Date.UTC(dateSent1.getFullYear(), dateSent1.getMonth(), dateSent1.getDate()) - 
    Date.UTC(dateSent2.getFullYear(), dateSent2.getMonth(), dateSent2.getDate()) ) /(1000 * 60 * 60 * 24);
}

  returnBlob(res : Blob){

    console.log('file downloaded');
    return new Blob([res], {type: ''});
  }


 downloadFile(id, file_name) {
    this.projetService.downloadFile(id).subscribe(
      data => saveAs(data, 'rapport_' + file_name),
      error => console.error(error)
    );
}


niveauByProjet(id) {


  console.log('niveauuuuuuuuuuuuuuuuuuuuuu');
  this.niveauService.niveauByProjet(id).subscribe(
    res => {
      this.niveauExist = true;
      this.niveau = res;
      this.idNiveau = res.id;
      console.log('niveauuuuuuuuuuuuuuuuuuuuuu');
      console.log(this.niveau);
    },
    error => console.log(error),
  );
}

////// delete projet ::

delete(id) {

  if (confirm('Êtes-vous sûr de vouloir supprimer ce projet?.')) {

    this.projetService.deleteProjet(id).subscribe(
      (data) => {
        this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');

        this.ngOnInit();
      },
      (error) => this.showNotification('top', 'left', 'Un erreur a été produite lors la suppression</b>.')

    );
  }

}

showNotification(from, align, message) {

  const color = Math.floor((Math.random() * 5) + 1);

  this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
    timeOut: 6000,
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-danger alert-with-icon',
    positionClass: 'toast-' + from + '-' +  align
     });
  }



  searchByFormation(formation) {

    if (formation == null || formation === 'all' || formation === 'formation') {



        this.ngOnInit();

     } else {

        this.formation = formation;
        this.projetService.getProjetsByFormation(formation).subscribe(res => {
          console.log(this.idUser);
          console.log(res);
          this.projets = res;
        }, err => {
          console.log(err);
        });

      }
  }


  searchByTypeAdmin(type) {

    if (this.formation == null || this.formation === 'all' || this.formation === 'formation') {

          if (type == null || type === 'all' || type === 'type') {

            this.projetService.getProjetsByUser(this.idUser).subscribe(res => {
              console.log(this.idUser);
              console.log(res);
              this.projets = res;
            }, err => {
              console.log(err);
            }); } else {
              this.projetService.getProjetsByUserByType(this.idUser, type).subscribe(res => {
                console.log(this.idUser);
                console.log(res);
                this.projets = res;
              }, err => {
                console.log(err);
              });

            }
    } else {

      if (type == null || type === 'all' || type === 'type') {

        this.projetService.getProjetsByFormation(this.formation).subscribe(res => {
          console.log(this.idUser);
          console.log(res);
          this.projets = res;
        }, err => {
          console.log(err);
        });

        } else {

          this.projetService.getProjetsByFormationAndType(this.formation, type).subscribe(res => {
            console.log(this.idUser);
            console.log(res);
            this.projets = res;
          }, err => {
            console.log(err);
          });
        }
    }

  }


  searchByTypeResponsable(type) {

    if (type == null || type === 'all' || type === 'type') {

      this.projetService.getProjetsByUser(this.idUser).subscribe(res => {
        console.log(this.idUser);
        console.log(res);
        this.projets = res;
      }, err => {
        console.log(err);
      }); } else {
        this.projetService.getProjetsByUserByType(this.idUser, type).subscribe(res => {
          console.log(this.idUser);
          console.log(res);
          this.projets = res;
        }, err => {
          console.log(err);
        });

      }
  }

  editValidation(id) {

    this.projetService.editValidation(id).subscribe(
      (data) => {

        console.log(data);
        this.showNotification('top', 'left', 'La validation de ce projet a été bien mofiée.');
        this.ngOnInit();
      },
      (error) => this.showNotification('top', 'left', 'Un erreur a été produite lors la modification de la validation du projet</b>.')

    );

}
}

