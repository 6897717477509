import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
	})
};

@Injectable({
  providedIn: 'root'
})
export class EnseignantService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }


  // get all Enseignants :
  getEnseignants(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Enseignant/List');
  }

    // get all Enseignants By Responsable :
    getEnseignantsByResponsable(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Enseignant/ListByEnseignant/' + id);
    }

      // get all Enseignants By Responsable :
      getEnseignantsByDepartementByResponsable(id, departement): Observable<any> {
        return this.http.get(environment.apiUrl + '/Enseignant/ListByEnseignant/' + id + '/' + departement);
      }

    // get all Enseignants By Departement :
    getEnseignantsByDepartement(departement): Observable<any> {
      return this.http.get(environment.apiUrl + '/Enseignant/ListByDepartement/' + departement);
    }


      // get all Enseignants By Responsable :
      getEnseignantsByFormationByResponsable(id, departement): Observable<any> {
        return this.http.get(environment.apiUrl + '/Enseignant/ListByEnseignant/' + id + '/' + departement);
      }

    // get all Enseignants By Formation :
    getEnseignantsByFormation(formation): Observable<any> {
      return this.http.get(environment.apiUrl + '/Enseignant/ListByFormation/' + formation);
    }


      // get all Enseignants By Responsable :
      getEnseignantsByNiveauByResponsable(id, departement): Observable<any> {
        return this.http.get(environment.apiUrl + '/Enseignant/ListByEnseignant/' + id + '/' + departement);
      }

    // get all Enseignants By Niveau :
    getEnseignantsByNiveau(niveau): Observable<any> {
      return this.http.get(environment.apiUrl + '/Enseignant/ListEnseignantByNiveau/' + niveau);
    }

    // get elements :
    getElements(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Enseignant/Elements/' + id);
    }


  // Create Enseignants :
  createEnseignant(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/Enseignant/Save', data);
  }

  // Delete Enseignants :
  deleteEnseignant(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Enseignant/Delete/'+ id);
  }

  // Edit Enseignants :
  editEnseignant(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Enseignant/Update', data);
    }

    editProfil(data): Observable<any> {
      return this.http.put(environment.apiUrl + '/Enseignant/Edit', data);
      }


  // get Enseignant By id
  getEnseignantById(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Enseignant/' + id, httpOptions);
    }
}
