import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
	})
};

@Injectable({
  providedIn: 'root'
})
export class NiveauService {

  constructor(private http: HttpClient, private router: Router) {}

 // get all Niveaux :
 getNiveaux(): Observable<any> {
  return this.http.get(environment.apiUrl + '/Niveau/List', httpOptions);
}

 // get all Niveaux By Formation:
 NiveauxByFormation(id): Observable<any> {
  return this.http.get(environment.apiUrl + '/Niveau/NiveauxByFormation/' + id, httpOptions);
}

 // get all Niveaux By Enseignant:
 NiveauxByTypeProjet(typeProjet): Observable<any> {
  return this.http.get(environment.apiUrl + '/Niveau/NiveauxByTypeProjet/' + typeProjet, httpOptions);
}

 // get all Niveaux By Enseignant:
 NiveauxByEnseignant(id): Observable<any> {
  return this.http.get(environment.apiUrl + '/Niveau/NiveauxByEnseignant/' + id, httpOptions);
}

 // get niveau by id :
 getNiveauById(id): Observable<any> {
  return this.http.get(environment.apiUrl + '/Niveau/NiveauById/' + id, httpOptions);
}

getNiveauFormation(id): Observable<any> {
  return this.http.get(environment.apiUrl + '/Niveau/NiveauFormation/' + id, httpOptions);
}

// get modules and elements  by niveau :
 getModulesByNiveau(id): Observable<any> {
  return this.http.get(environment.apiUrl + '/Niveau/'+id+'/ListModules', httpOptions);
}

isNiveau(des): Observable<any> {
    return this.http.get(environment.apiUrl + '/Niveau/isNiveau/' + des, httpOptions);
  }


  // Create Niveau :
  createNiveau(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/Niveau/Save', data, httpOptions);
  }

  // Delete Niveau :
  deleteNiveau(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Niveau/Delete/' + id);
  }

  // Edit Niveau :
  editNiveau(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Niveau/Update', data, httpOptions);
    }

  // List Modules By Niveau
  getListNiveaux(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Niveau/ListModules', httpOptions);
  }


  niveauByProjet(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Niveau/NiveauByProjet/' + id);
  }



  getNiveauxyByAdmin(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Niveau/List');
  }

  getNiveauByRF(idUser): Observable<any> {
    return this.http.get(environment.apiUrl + '/Niveau/NiveauxByResponsableFormation/' + idUser);
  }

  getEtudiantsByNiveau(idNiveau): Observable<any> {
    return this.http.get(environment.apiUrl + '/Niveau/Etudiants/' + idNiveau);
  }


    // get all Niveaux By Formation :
    getNiveauxByFormation(formation): Observable<any> {
      return this.http.get(environment.apiUrl + '/Niveau/NiveauxByFormation/' + formation);
    }
}
