import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjetService } from '../services/projet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnseignantService } from '../../profils/services/enseignant.service';
import { Enseignant } from '../../profils/models/enseignant.model';
import { NiveauService } from '../../formation/services/niveau.service';
import { EtudiantService } from '../../profils/services/etudiant.service';
import { Niveau } from '../../formation/models/niveau.model';

@Component({
  selector: 'app-edit-projet',
  templateUrl: './edit-projet.component.html',
  styleUrls: ['./edit-projet.component.scss']
})
export class EditProjetComponent implements OnInit {


  public binome:boolean = false;
  public buttonBinome:any = 'Binôme';

  public trinome:boolean = false;
  public buttonTrinome:any = 'Trinôme';
  editProjetForm: FormGroup;
  error: string;
  success: string;
  projet;
  enseignant: Enseignant;
  idUser = localStorage.getItem('id');
  idProjet = this.route.snapshot.paramMap.get('id');
  test;
    id: number
    sujet: string
    type: string
    societe:string

    dateDepotRapport:Date
    nomEncadrantExterne:string
    emailEncadrantExterne:string
    telEncadrantExterne:string
    message: '';
    submitted = false;
    userable_id = localStorage.getItem('userable_id');
    niveaux: Niveau;
    departement;
    allEnseignants=[]
    departement2;
    enseignants;
    enseignants2;
    niveau;
    etudiants;
    encadrants
    encadrantExterne = true
    encadrant;
    encadrant2;
    etudiant;
    etudiant2;
    etudiant3;
    profil = localStorage.getItem('profil');

  constructor(
    private projetService: ProjetService,
    private enseignantService: EnseignantService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router : Router,
    private toastr: ToastrService,
    private niveauService: NiveauService,
    private etudiantService: EtudiantService

  ) {

   }

  ngOnInit() {
    this.enseignantService.getEnseignants().subscribe((res: any) => {
      this.enseignants = res;
      this.enseignants2 = res;
      this.allEnseignants = res;
    })
    if ( this.profil === 'Administrateur') {
      this.niveauService.getNiveauxyByAdmin().subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );
    } else if ( this.profil === 'ResponsableFormation' ) {

      // let idUser = localStorage.getItem('id');
      console.log('iddddd :::::::' + this.idUser);
      this.niveauService.getNiveauByRF(this.idUser).subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );
    }
    this.success = null;
    this.error = null;


    if ( this.profil === 'Administrateur' || this.profil === 'ResponsableFormation') {

      this.editProjetForm = this.formBuilder.group({
        sujet: [null, [Validators.required, Validators.maxLength(50)]],
        societe: [null, [Validators.required, Validators.maxLength(50)]],
        nomEncadrantExterne: [null, [Validators.required,Validators.maxLength(50)]],
        emailEncadrantExterne: [null, [ Validators.maxLength(50)]],
        telEncadrantExterne: [null, [ Validators.maxLength(50)]],
        type: ['', [Validators.maxLength(500)]],
        encadrant: [null, Validators.required],
        encadrant2: [null],
        etudiant: [null, Validators.required],
        etudiant2: [null],
        etudiant3: [null],
        date_debut:[null], 
        date_fin:[null],
        //'departement': [null, [Validators.required]],
        niveau : [null, Validators.required],
        id: [this.idProjet, Validators.required],
      });

    } else if ( this.profil === 'Etudiant' ) {

      this.editProjetForm = this.formBuilder.group({
        sujet: [null, [Validators.required, Validators.maxLength(50)]],
        societe: [null, [Validators.required, Validators.maxLength(50)]],
        nomEncadrantExterne: [null, [Validators.required,Validators.maxLength(50)]],
        emailEncadrantExterne: [null, [ Validators.maxLength(50)]],
        telEncadrantExterne: [null, [ Validators.maxLength(50)]],
        type: ['', [Validators.maxLength(500)]],
        encadrant: [null, Validators.required],
        encadrant2: [null],
        etudiant: [this.userable_id, Validators.required],
        etudiant2: [null],
        etudiant3: [null],
        //'departement': [null, [Validators.required]],
        niveau : [null],
        id: [this.idProjet, Validators.required],
      });

    }


    this.projetService.getProjetById(this.idProjet).subscribe(res => {

      this.projet = res;
      console.log(this.projet);

      this.projetService.getEncadrantsByProjet(this.idProjet).subscribe(res => {
        this.encadrants= res;
        console.log(this.encadrants);
        if(this.encadrants.length== 1){
          this.departement = this.encadrants[0].departement;
          this.selectEnseignantByDepartement(this.departement);
          this.encadrant= this.encadrants[0].id;

        }else {
          console.log("2 encadrants");
          this.departement = this.encadrants[0].departement;
          this.selectEnseignantByDepartement(this.departement);
          this.encadrant= this.encadrants[0].id;

          this.departement2 = this.encadrants[1].departement;
          this.selectEnseignantByDepartement2(this.departement2);
          this.encadrant2= this.encadrants[1].id;
        }
    /*    this.encadrants.forEach(element => {
            this.departement = element.departement;
            this.selectEnseignantByDepartement(element.departement);
            this.encadrant = element.id;
            console.log(this.encadrant);
          }
        );*/

      }, err => {
        console.log(err);
        this.error = 'Erreur survenue lors de la recuperation des informations du lien !';
      });

      this.projetService.getEtudiantsByProjet(this.idProjet).subscribe(res => {
        this.etudiants= res;
        console.log(this.encadrants.length);
        if(this.etudiants.length== 1){
          this.niveau = this.etudiants[0].niveau_id;
          this.selectEtudiantByNiveau(this.niveau);
          this.etudiant= this.etudiants[0].id;

        }else if(this.etudiants.length== 2){
           this.niveau = this.etudiants[0].niveau_id;
           this.selectEtudiantByNiveau(this.niveau);
           this.etudiant= this.etudiants[0].id;
           this.etudiant2= this.etudiants[1].id;
        }else{
            this.niveau = this.etudiants[0].niveau_id;
            this.selectEtudiantByNiveau(this.niveau);
            this.etudiant= this.etudiants[0].id;
            this.etudiant2= this.etudiants[1].id;
            this.etudiant3= this.etudiants[2].id;

        }

       /* this.etudiants.forEach(element => {
            this.niveau = element.niveau_id;
            this.selectEtudiantByNiveau(this.niveau);
            this.etudiant= element.id;
            console.log(this.etudiant);
          }
        );*/




      }, err => {
        console.log(err);
        this.error = 'Erreur survenue lors de la recuperation des informations du lien !';
      });


      this.editProjetForm.patchValue({
          sujet: this.projet.sujet,
          societe: this.projet.societe,
          type: this.projet.type,
          nomEncadrantExterne: this.projet.nomEncadrantExterne,
          emailEncadrantExterne: this.projet.emailEncadrantExterne,
          telEncadrantExterne: this.projet.telEncadrantExterne,
          dateDepotRapport: this.projet.dateDepotRapport,
          encadrant: this.encadrant,
          etudiant: this.projet.etudiant,
          date_debut:[this.projet.date_debut], 
          date_fin:[this.projet.date_fin],
          id: this.idProjet,


        });


      }, err => {
        console.log(err);
        this.error = 'Erreur survenue lors de la recuperation des informations du etudiant !';
      });

  }

  selectEnseignantByDepartement(departement) {
    this.enseignantService.getEnseignantsByDepartement(departement).subscribe(

      res => this.enseignants = res,
      error => console.log(error),
    );
};

removeCurrentTeacherFromTeachersOption() {
  console.log(this.encadrant)
  this.enseignants2 = this.allEnseignants.filter(enseignant => enseignant.id != this.encadrant)
}
removeSecondTeacherFromTeachersOption(){
  console.log(this.encadrant2)
  this.enseignants = this.allEnseignants.filter(enseignant => enseignant.id != this.encadrant2)
}

selectEnseignantByDepartement2(departement2) {
  this.enseignantService.getEnseignantsByDepartement(departement2).subscribe(

    res => this.enseignants2 = res,
    error => console.log(error),
  );
};

selectNiveaux() {

  if ( this.profil === 'Administrateur') {
    this.niveauService.getNiveauxyByAdmin().subscribe(

      res => this.niveaux = res,
      error => console.log(error),
    );
  } else if ( this.profil === 'ResponsableFormation' ) {

    this.niveauService.getNiveauByRF(this.idUser).subscribe(

      res => this.niveaux = res,
      error => console.log(error),
    );
  }
}

selectEtudiantByNiveau(niveau) {
  console.log(niveau);
  this.niveauService.getEtudiantsByNiveau(niveau).subscribe(

    res => this.etudiants = res,
    error => console.log(error),
  );
  console.log(this.etudiants);
};
  toggleBinome() {
    this.binome = !this.binome;
    this.trinome = false;
    // this.projetForm.get('etudiant3').value === null;

  }


  toggleTrinome() {
    this.trinome = !this.trinome;
    this.binome = false;

  }

  toggleEncadrant() {
    this.encadrant = !this.encadrant;
    // this.projetForm.get('etudiant3').value === null;

  }

  toggleEncadrantExterne() {
    this.encadrantExterne = !this.encadrantExterne;
    // this.projetForm.get('etudiant3').value === null;

  }




  get f() { return this.editProjetForm.controls; }
 
  getCorrectDateFormat(date1){
  //  let monthPrexif=date1.getMonth().length>1 ? "" : "0";
   // let dayPrexif=date1.getDay().length>1 ? "" : "0";

    
    // monthPrexif+date1.getMonth()+"-"+dayPrexif+date1.getDay()+"-"+date1.getFullYear()
    console.log(date1.getMonth())
    return date1.getFullYear()+"-"+(date1.getMonth()+1)+"-"+(date1.getDay()+1);
  }
  editProjet() {


    if(document.activeElement.id === 'finish') {
      this.submitted = true;

    

      const formData = this.editProjetForm.value;
      let date1=new Date(formData.date_debut);
      let date2=new Date(formData.date_fin);
      formData.date_debut=this.getCorrectDateFormat(date1);
      formData.date_fin=this.getCorrectDateFormat(date2)

      this.success = null;
      this.error = null;
      this.projetService.editProjet(formData).subscribe(
        (success) => {
          console.log(success);
          this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées.</b>');
          this.router.navigate(['/gererProjets']);

        },
        (error) => {
          console.log(error);
          this.showNotification('top', 'left', 'Une erreur a été produite.');
        },
      );
    }
  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}
