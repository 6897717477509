import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RessourceService } from '../../services/ressource.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-edit-ressource',
  templateUrl: './edit-ressource.component.html',
  styleUrls: ['./edit-ressource.component.scss']
})
export class EditRessourceComponent implements OnInit {
  editRessourceForm: FormGroup;
  error: string;
  success: string;
  ressource;
  idRessource = this.route.snapshot.paramMap.get('id');
  test;
    id: number
    titre: string
    description: string
    lien: string
    visibility: string
    message: '';
    submitted = false;
idCours;

  constructor(
    private ressourceService: RessourceService,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router : Router,
      private toastr: ToastrService

  ) {

   }

  ngOnInit() {
    this.success = null;
    this.error = null;
    this.editRessourceForm = this.formBuilder.group({
      titre: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
      description: ['', [Validators.maxLength(500)]],
      visibility: [null, Validators.required],
      lien: [null, Validators.required],
      id: [this.idRessource, Validators.required],
    });

    this.ressourceService.getRessourceById(this.idRessource).subscribe(res => {

      this.ressource = res;
      this.idCours = res.cour_id;
      console.log(this.ressource);


      this.editRessourceForm.patchValue({
        titre: this.ressource.titre,
        lien: this.ressource.lien,
        description: this.ressource.description,
        visibility: this.ressource.visibility,
        id: this.idRessource,

      });
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations du lien !';
    });
  }


  get f() { return this.editRessourceForm.controls; }

  editRessource() {

    if (document.activeElement.id === 'submit') {

        this.submitted = true;

        if (this.editRessourceForm.invalid) {
          this.error = 'invalide Form  !';
          console.log(this.editRessourceForm);
          return;
        }

        const formData = this.editRessourceForm.value;

        this.success = null;
        this.error = null;
        this.ressourceService.editRessource(formData).subscribe(
          (success) => {

              this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
              this.router.navigate(['/gererRessource', this.ressource.cour_id]);

          },
          (error) => {
            console.log(error);
            this.showNotification('top', 'left', 'Code Formation <b>dèja existe</b> , choisit un nouveau code');
          },
        );
    }
  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}
