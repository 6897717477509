import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NiveauService } from '../../services/niveau.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-niveau',
  templateUrl: './edit-niveau.component.html',
  styleUrls: ['./edit-niveau.component.scss']
})
export class EditNiveauComponent implements OnInit {

  editNiveauForm: FormGroup;
  error: string;
  success: string;
  niveau;
  idNiveau = this.route.snapshot.paramMap.get('id');
  test;
    id: number
    designation: string
    code: string

  message: '';
  submitted = false;

  constructor(
      private niveauService: NiveauService,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private toastr: ToastrService

  ) {

   }

  ngOnInit() {
    this.success = null;
    this.error = null;
    this.editNiveauForm = this.formBuilder.group({
      designation: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
      code: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      id: [this.idNiveau, Validators.required],
    });

    this.niveauService.getNiveauById(this.idNiveau).subscribe(res => {
      // console.log(res);
      this.niveau = res;
      console.log(this.niveau);

      // tslint:disable-next-line: radix
      this.editNiveauForm.patchValue({
        designation: this.niveau.designation,
        code: this.niveau.code,
        id: this.idNiveau,

      });
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations du lien !';
    });
  }

  get f() { return this.editNiveauForm.controls; }


  editNiveau() {

    if (document.activeElement.id === 'submit') {

        this.submitted = true;


        if (this.editNiveauForm.invalid) {
          this.error = 'invalide Form  !';
          console.log(this.editNiveauForm);
          return;
        }

        const formData = this.editNiveauForm.value;

        this.success = null;
        this.error = null;
        this.niveauService.editNiveau(formData).subscribe(
          (success) => {

              this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>'),
              this.success = 'niveau informations updated!';
              this.router.navigate(['/gererNiveau', this.niveau.formation_id]);
          },
          (error) => {
            console.log(error);
            this.error = 'Error ! failed to update !';
            this.showNotification('top', 'left', 'Code Formation <b>dèja existe</b> , choisit un nouveau code');

          },
        );
    }
  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

}

