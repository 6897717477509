import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ElementService } from '../../services/element.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Element } from '../../models/element.model';
import { ToastrService } from 'ngx-toastr';
import { ProfilService } from 'src/app/modules/profils/services/profil.service';
import { EnseignantService } from 'src/app/modules/profils/services/enseignant.service';
@Component({
  selector: 'app-edit-element',
  templateUrl: './edit-element.component.html',
  styleUrls: ['./edit-element.component.scss']
})
export class EditElementComponent implements OnInit {

  idElement = this.route.snapshot.paramMap.get('id');
  element: Element;
  elementForm: FormGroup;
  success: string;
  error: string;
  enseignant_td: 'enseignant_td';
  enseignant_cours: 'enseignant_cours';
  enseignant_tp: 'enseignant_tp';
  enseignants;
  enseignants2;
  enseignants3;
  departement = 'departement';
  departement2 = 'departement2';
  departement3 = 'departement3';


  submitted = false;
  cinExist: number;
  cinExistCours = false;
  cinExistTp = false;
  cinExistTd = false;
  message: '';

  constructor(
    private elementService: ElementService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private profilService: ProfilService,
    private toastr: ToastrService,
    private enseignantService: EnseignantService,

  ) { }

  ngOnInit() {

    this.elementForm = this.formBuilder.group({
      id: [this.idElement, Validators.required],
      code: [null, [Validators.required]],
      designation: [null, [Validators.required]],
      enseignant_cours: [null, [Validators.required]],
      enseignant_tp: [null],
      enseignant_td: [null],
      departement: [null, Validators.required],
      departement2: [null],
      departement3: [null],
    }

    );

    this.elementService.getElementById(this.idElement).subscribe(res => {
      this.element= res;

    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations du lien !';
    });

    this.elementService.getEnseignants(this.idElement).subscribe(res => {

      this.enseignants2= res;
      console.log(this.enseignants2);
      this.enseignants2.forEach(element => {

        if(element.type_enseignement == 'tp')
        {
          this.departement3 = element.departement;
          this.selectEnseignantByDepartement3(this.departement3);
          this.enseignant_tp = element.id;
          console.log('id tp:'+ this.enseignant_tp);
        }
        else  if(element.type_enseignement == 'td')
        {
          this.departement2 = element.departement;
          this.selectEnseignantByDepartement2(this.departement2);
          this.enseignant_td = element.id;
          console.log('id td:'+ this.enseignant_td);
        }else  if(element.type_enseignement == 'cours')
        {
          this.departement = element.departement;
          this.selectEnseignantByDepartement(this.departement);
          this.enseignant_cours = element.id;
          console.log('id cours:'+ this.enseignant_cours);
        }

      });


      this.elementForm.patchValue({
        code: this.element.code,
        designation: this.element.designation,
        departement: this.departement,
        departement2: this.departement2,
        departement3: this.departement3,
        enseignant_cours: this.enseignant_cours,
        enseignant_td: this.enseignant_td,
        enseignant_tp: this.enseignant_tp,
      });

   });

  }



  selectEnseignantByDepartement(enseignant) {
    this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(

      res => this.enseignants = res,
      error => console.log(error),
    );
  };

  selectEnseignantByDepartement2(enseignant) {
    this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(

      res => this.enseignants2 = res,
      error => console.log(error),
    );
  };

  selectEnseignantByDepartement3(enseignant) {
    this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(

      res => this.enseignants3 = res,
      error => console.log(error),
    );
  };

  get f() { return this.elementForm.controls; }

  EditElement() {
    if (document.activeElement.id === 'finish') {

    this.submitted = true;

    if (this.elementForm.invalid) {
      this.error = 'invalide Form  !';
      console.log(this.elementForm);
      return;
    }


    const formData = this.elementForm.value;

    this.error = null;




    this.elementService.editElement(formData).subscribe(

      (data) => {

          this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
          this.router.navigate(['/gererElement', this.element.module_id]);

      },

      (error) => {

        console.log(error);

      }
    );
    }

  }
    selectEnseignant(enseignant) {
      this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(

        res => this.enseignants = res,
        error => console.log(error),
      );
      console.log(this.enseignants);
  };


    showNotification(from, align, message) {

      const color = Math.floor((Math.random() * 5) + 1);

      this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
        timeOut: 6000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-' + from + '-' +  align
         });
      }
}
