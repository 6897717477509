import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjetService } from '../services/projet.service';
import { Projet } from '../models/projet.model';
import  saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from '../../formation/services/niveau.service';
import { FormationService } from '../../formation/services/formation.service';

@Component({
  selector: 'app-consultation-projets',
  templateUrl: './consultation-projets.component.html',
  styleUrls: ['./consultation-projets.component.scss']
})
export class ConsultationProjetsComponent implements OnInit {

  projets;
  idUser = localStorage.getItem('id');
  profil = localStorage.getItem('profil');
  name = localStorage.getItem('name');

  type = 'type';
  formation = 'formation';
  formations;

  constructor(

    private route: ActivatedRoute,
    private projetService: ProjetService,
    private toastr: ToastrService,
    private niveauService: NiveauService,
    private formationService: FormationService

  ) { }

  ngOnInit() {

    this.projetService.getProjets().subscribe(res => {
      this.projets = res;

    }, err => {
      console.log(err);
    });

    this.formationService.getFormations().subscribe(

      res => this.formations = res,
      error => console.log(error),
    );

  }



  returnBlob(res : Blob){

    console.log('file downloaded');
    return new Blob([res], {type: ''});
  }


 downloadFile(id, file_name) {
    this.projetService.downloadFile(id).subscribe(
      data => saveAs(data, 'rapport_' + file_name),
      error => console.error(error)
    );
}

  searchByFormation(formation) {

    if (formation == null || formation === 'all' || formation === 'formation') {

        this.ngOnInit();

     } else {

        this.formation = formation;
        this.projetService.getProjetsByFormation(formation).subscribe(res => {
          console.log(this.idUser);
          console.log(res);
          this.projets = res;
        }, err => {
          console.log(err);
        });

      }
  }


  searchByType(type) {

    if (this.formation == null || this.formation === 'all' || this.formation === 'formation') {

          if (type == null || type === 'all' || type === 'type') {

            this.projetService.getProjetsByUser(this.idUser).subscribe(res => {
              console.log(this.idUser);
              console.log(res);
              this.projets = res;
            }, err => {
              console.log(err);
            }); } else {
              this.projetService.getProjetsByUserByType(this.idUser, type).subscribe(res => {
                console.log(this.idUser);
                console.log(res);
                this.projets = res;
              }, err => {
                console.log(err);
              });

            }
    } else {

      if (type == null || type === 'all' || type === 'type') {

        this.projetService.getProjetsByFormation(this.formation).subscribe(res => {
          console.log(this.idUser);
          console.log(res);
          this.projets = res;
        }, err => {
          console.log(err);
        });

        } else {

          this.projetService.getProjetsByFormationAndType(this.formation, type).subscribe(res => {
            console.log(this.idUser);
            console.log(res);
            this.projets = res;
          }, err => {
            console.log(err);
          });
        }
    }

  }

}
