import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
	})
};


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient, private router: Router) { }

     // get all app module :
     getNotificationByUser(id): Observable<any> {

      return this.http.get(environment.apiUrl + '/Notification/'+ id);
    }

    getRapportNotification(id): Observable<any> {

      return this.http.get(environment.apiUrl + '/Projet/notifRapportEtudiant/'+ id);
    }

    // get all app module :
    getDocumentsNotificationByUser(id): Observable<any> {

      return this.http.get(environment.apiUrl + '/DocumentsNotification/'+ id);
    }

    updateNotification(id): Observable<any> {
      return this.http.post(environment.apiUrl + '/Notification/Update/' + id, httpOptions);
    }

    updateDocumentsNotification(id): Observable<any> {
      return this.http.post(environment.apiUrl + '/DocumentsNotification/Update/'+ id, httpOptions);

    }
       //get Nbre visites By Date
     getNbrVisitsByDate(): Observable<any> {

      return this.http.get(environment.apiUrl + '/ApplicationVisits');
    }

    getSumVisitsByDate(): Observable<any> {
      return this.http.get(environment.apiUrl + '/NombreVisites');
      }

      getDates(): Observable<any> {
        return this.http.get(environment.apiUrl + '/ListDates');
        }

        incrementNbreVisitesByDate(id): Observable<any> {
          return this.http.post(environment.apiUrl + '/nbrVisites/increment/'+ id, httpOptions);  
        }
}
