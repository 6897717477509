import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfilService } from '../services/profil.service';

@Component({
  selector: 'app-send-invitation',
  templateUrl: './send-invitation.component.html',
  styleUrls: ['./send-invitation.component.scss']
})
export class SendInvitationComponent implements OnInit {

  invitationForm: FormGroup;
  error: string;
  uploadResponse = { status: '', message: '', filePath: '' };
  idUser = localStorage.getItem('id');
  role = this.route.snapshot.paramMap.get('profil');

   constructor(
       private profilService: ProfilService,
       //private toastr: ToastrService,
       private router : Router,
       private formBuilder: FormBuilder,
       private route: ActivatedRoute,
     ) { }



   ngOnInit() {
     this.invitationForm = this.formBuilder.group({
       file: ['', Validators.required],
       profil: [this.role, Validators.required]
     });

   }

   onFileChange(event) {
     if (event.target.files.length > 0) {
       console.log(event);
       const file = event.target.files[0];
       // let testFile = this.invitationForm.get('file').setValue(file);

     }
   }

   onSubmit() {

     const formData = new FormData();
     const filetest = formData.append('file', this.invitationForm.get('file').value);
     // console.log(formData.get('file'));
     const profil = formData.append('profil', this.role);
     // console.log(formData.get('profil'));

     this.profilService.sendInvitation(formData).subscribe(
       (res) => this.uploadResponse = res,
       (err) => this.error = err
     );

   }

 }
