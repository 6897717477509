import { Component, OnInit } from '@angular/core';
import { Cours } from '../../models/cours.model';
import { CoursService } from '../../services/cours.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnseignantService } from 'src/app/modules/profils/services/enseignant.service';
import { DocumentService } from '../../services/document.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ajout-document',
  templateUrl: './ajout-document.component.html',
  styleUrls: ['./ajout-document.component.scss']
})
export class AjoutDocumentComponent implements OnInit {

  id= localStorage.getItem('id');
  email= localStorage.getItem('email');
  userable_id= localStorage.getItem('userable_id');
  cours: Cours [] = [];
  elements: any [] = [];
  i = 0;
  documentForm: FormGroup;
  profil = localStorage.getItem('profil');
  public show:boolean = false;
  public buttonName:any = 'Show';
  enseignants;
  error: string;
  uploadResponse = { status: '', message: '', filePath: '' };
  submitted = false;
  success: string;
  message: '';
  selectedRestriction = 'selectedRestriction';
  cour_id = 'cour_id';
  element = 'element';
  departement = 'departement';
  created_by = 'created_by';

  constructor(
    private documentService: DocumentService,
    private coursService: CoursService,
    private enseignantService: EnseignantService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,

  ) {

    this.coursService.getCoursByUser(this.id).subscribe(res => {

      console.log(this.id + 'get mes cours argument');

      console.log(res);

      this.cours = res;

      console.log(this.cours);

    }, err => {
      console.log(err);


    });

    this.enseignantService.getElements(this.userable_id).subscribe(res => {



      console.log(res);

      this.elements = res;

      console.log(this.elements);

    }, err => {
      console.log(err);


    });
  }

  ngOnInit() {
    console.log(this.cours);

    if ( this.profil === 'Administrateur') {
    this.documentForm = this.formBuilder.group({
      file: ['', Validators.required],
      type: ['', Validators.required],
      titre: ['',[Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
      visibility: [null, Validators.required],
      cour_id: [null, Validators.required],
      element: [null],
      description: [null, [Validators.maxLength(500)]],
      departement : [null, Validators.required],
      created_by : [null, Validators.required]
    });
    } else {
      this.documentForm = this.formBuilder.group({
        file: ['', Validators.required],
        type: ['', Validators.required],
        titre: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
        visibility: [null, Validators.required],
        cour_id: [null, Validators.required],
        element: [null],
        description: [null, [Validators.maxLength(500)]]
      });
    }

  }

  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show && this.documentForm.get('cour_id').value === 'aucun') {
      this.buttonName = "Hide";
      this.show = true;
      console.log('shoooooow 11111 ' + this.show); } else {
        this.buttonName = 'Show';
        this.show = false;
        console.log('shoooooow 11111 ' + this.show);
      }

  }

  selectEnseignant(departement) {
    this.enseignantService.getEnseignantsByDepartement(departement).subscribe(

      res => this.enseignants = res,
      error => console.log(error),
    );
};

  onFileChange(event) {
    if (event.target.files.length > 0) {
      console.log(event);
      const file = event.target.files[0];
      let testFile = this.documentForm.get('file').setValue(file);
      console.log(file);

    }
  }

  get f() { return this.documentForm.controls; }


  onSubmit() {
    if (document.activeElement.id === 'finish') {
        this.submitted = true;

        if (this.profil === 'Administrateur') {
          if (this.documentForm.invalid || this.documentForm.get('visibility').value === 'selectedRestriction' || this.documentForm.get('cour_id').value === 'cour_id' || this.documentForm.get('created_by').value === 'created_by' || this.documentForm.get('departement').value === 'departement') {
            this.error = 'invalide Form  !';
            console.log(this.documentForm);
            return;
          } else {
                const formData = new FormData();
                const filetest = formData.append('file', this.documentForm.get('file').value);
                console.log(formData.get('file'));
                formData.append('titre', this.documentForm.get('titre').value);
                console.log(formData.get('titre'));
                formData.append('type', this.documentForm.get('type').value);
                console.log(formData.get('type'));
                formData.append('visibility', this.documentForm.get('visibility').value);
                formData.append('description', this.documentForm.get('description').value);
                formData.append('cour_id', this.documentForm.get('cour_id').value);
                formData.append('element_id', this.documentForm.get('element').value);
                formData.append('created_by', this.documentForm.get('created_by').value);

                console.log(formData.get('titre'));
                console.log(formData.get('type'));
                console.log(formData.get('visibility'));
                console.log(formData.get('description'));
                console.log(formData.get('cours'));
                console.log(formData.get('element'));

                this.documentService.upload(formData).subscribe(
                  (res) => {

                    this.i = this.i + 1;
                    console.log(res);
                    console.log(this.i);
                    if (this.i === 5) {

                        this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>'),
                        this.router.navigate(['/listeDocuments']);
                    }
                  },
                  (err) =>this.showNotification('top', 'left', 'Un erreur d\'ajout')
                );
          }

        } else if ( this.profil === 'Enseignant' || this.profil === 'ResponsableFormation' ) {
          if (this.documentForm.invalid || this.documentForm.get('visibility').value === 'selectedRestriction' || this.documentForm.get('cour_id').value === 'cour_id') {
            this.error = 'invalide Form  !';
            console.log(this.documentForm);
            return;
          } else {
                const formData = new FormData();
                const filetest = formData.append('file', this.documentForm.get('file').value);
                console.log(formData.get('file'));
                formData.append('titre', this.documentForm.get('titre').value);
                console.log(formData.get('titre'));
                formData.append('type', this.documentForm.get('type').value);
                console.log(formData.get('type'));
                formData.append('visibility', this.documentForm.get('visibility').value);
                formData.append('description', this.documentForm.get('description').value);
                formData.append('cour_id', this.documentForm.get('cour_id').value);
                formData.append('element_id', this.documentForm.get('element').value);
                formData.append('created_by', this.email);

                console.log(formData.get('titre'));
                console.log(formData.get('type'));
                console.log(formData.get('visibility'));
                console.log(formData.get('description'));
                console.log(formData.get('cours'));
                console.log(formData.get('element'));

                this.documentService.upload(formData).subscribe(
                  (res) => {

                    this.i = this.i + 1;
                    console.log(res);
                    console.log(this.i);
                    if (this.i === 5) {

                        this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>'),
                        this.router.navigate(['/listeDocuments']);
                    }
                  },
                  (err) =>this.showNotification('top', 'left', 'Un erreur d\'ajout')
                );
          }
        }





        console.log('test cououuuurs');
        // this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>'),

        // this.router.navigate(['/consulterCours', this.idCours]);
    }
   }

    showNotification(from, align, message) {

      const color = Math.floor((Math.random() * 5) + 1);

      this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
        timeOut: 6000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-' + from + '-' +  align
         });
      }

  }


