import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { EtudiantService } from '../services/etudiant.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Etudiant } from '../models/etudiant.model';
import { Enseignant } from '../models/enseignant.model';

import { EnseignantService } from '../services/enseignant.service';
import { AdministratifService } from '../services/administratif.service';
import { Administratif } from '../models/administratif.model';
import { ProfilService } from '../services/profil.service';
import { Administrateur } from '../models/administrateur.model';
import { DomSanitizer } from '@angular/platform-browser';
import  saveAs from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-edit-profil',
  templateUrl: './edit-profil.component.html',
  styleUrls: ['./edit-profil.component.scss']
})
export class EditProfilComponent implements OnInit {

  idUser = localStorage.getItem('id');
  editPasswordForm: FormGroup;

  editProfilForm: FormGroup;
  error: string;
  success: string;
  etudiant: Etudiant;
  adminidtratif: Administratif;
  adminidtrateur: Administrateur;
  enseignant: Enseignant;
  profil = localStorage.getItem('profil');
  userable_id = localStorage.getItem('userable_id');
  id: number;
  message: '';
  submitted = false;
  thumbnail: any;
  imgBase64 = `R5UuH0wOEXzMECaDlMrZKs6W8o6eQgwXCCw2v6eL`;
  imageDirectoryPath: any = 'http://localhost:8000/public/image/';


  idProfil = this.route.snapshot.paramMap.get('id');

  // tslint:disable-next-line: max-line-length
  constructor(
        private profilService: ProfilService,
        private etudiantService: EtudiantService,
        private enseignantService: EnseignantService,
        private administratifService: AdministratifService,
        private administrateurService: ProfilService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private sanitizer: DomSanitizer

    ) {



 }

  ngOnInit() {
    this.success = null;
    this.error = null;




    this.editPasswordForm = this.formBuilder.group({
      'id': [this.idUser],
      'currentPassword': [null, [Validators.required, Validators.minLength(8)]],
      'newPassword': [null, [Validators.required, Validators.minLength(8)]],
      'verifyNewPassword': [null, [Validators.required, Validators.minLength(8)]],
    });

    if (this.profil === 'Etudiant') {
    this.editProfilForm = this.formBuilder.group({
      'id': [this.userable_id],
      'cin': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      'cne': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      'nom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      'prenom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      'email': [null, [Validators.required]],
      'image': [null],
      'tel': [null, [Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15)]],
     // id: [this.idEtudiant, Validators.required],
    });


    this.etudiantService.getEtudiantById(this.userable_id).subscribe(res => {
      console.log(res);
      this.etudiant = res;
      console.log(this.etudiant);
      this.thumbnail = 'data:image/png;base64,R5UuH0wOEXzMECaDlMrZKs6W8o6eQgwXCCw2v6eL';
      this.etudiantService.downloadImage(this.userable_id).subscribe(
        data => {
          console.log(data);
          this.thumbnail = 'data:image/png;base64,R5UuH0wOEXzMECaDlMrZKs6W8o6eQgwXCCw2v6eL';
          saveAs(data, this.etudiant.nom)},
        error => console.error(error)
      );
      // tslint:disable-next-line: radix
      this.editProfilForm.patchValue({
        cin: this.etudiant.cin,
        cne: this.etudiant.cne,
        nom: this.etudiant.nom,
        prenom: this.etudiant.prenom,
        email: this.etudiant.email,
        tel: this.etudiant.tel,
        id: this.userable_id,
        image: this.etudiant.image,
    //    id: this.idEtudiant,

      });
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations de l\'utilisateur !';
    });
  }
    if (this.profil === 'Enseignant' || this.profil === 'ResponsableFormation') {
    this.editProfilForm = this.formBuilder.group({
      'id': [this.userable_id],
      'cin': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      'email': [null, [Validators.required,
        Validators.minLength(10), Validators.maxLength(50),
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      'nom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      'prenom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      'tel': [null, [Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15)]],
      'departement': [null, [Validators.required]],

    });

    this.enseignantService.getEnseignantById(this.userable_id).subscribe(res => {

      console.log(res);
      this.enseignant = res;
      // tslint:disable-next-line: radix
      this.editProfilForm.patchValue({
        id: this.userable_id,
        cin: this.enseignant.cin,
        nom: this.enseignant.nom,
        prenom: this.enseignant.prenom,
        tel: this.enseignant.tel,
        email: this.enseignant.email,
        departement: this.enseignant.departement,
    //    id: this.idEtudiant,

      });
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations de l\'utilisateur !';
    });
  }

    if (this.profil === 'Administratif') {
    this.editProfilForm = this.formBuilder.group({
      'id': [this.userable_id],
      'cin': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],

      'nom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      'prenom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      'email': [null, [Validators.required]],
      'tel': [null, [Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15)]],
     // id: [this.idEtudiant, Validators.required],
    });

    this.administratifService.getAdministratifById(this.userable_id).subscribe(res => {

      console.log(res);
      this.adminidtratif = res;
      // tslint:disable-next-line: radix
      this.editProfilForm.patchValue({
        id: this.userable_id,
        cin: this.adminidtratif.cin,
        nom: this.adminidtratif.nom,
        prenom: this.adminidtratif.prenom,
        email: this.etudiant.email,
        tel: this.adminidtratif.tel,
    //    id: this.idEtudiant,

      });
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations de l\'utilisateur !';
    });
  }

    if (this.profil === 'Administrateur') {
    this.editProfilForm = this.formBuilder.group({
      'id': [this.userable_id],
      'cin': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      'email': [null, [Validators.required,
        Validators.minLength(10), Validators.maxLength(50),
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      'nom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      'prenom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      'tel': [null, [Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15)]],
      'poste': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
     // id: [this.idEtudiant, Validators.required],
    });

    this.administrateurService.getAdministrateurById(this.userable_id).subscribe(res => {

      console.log(res);
      this.adminidtrateur = res;
      // tslint:disable-next-line: radix
      this.editProfilForm.patchValue({
        id: this.userable_id,
        cin: this.adminidtrateur.cin,
        nom: this.adminidtrateur.nom,
        prenom: this.adminidtrateur.prenom,
        tel: this.adminidtrateur.tel,
        email: this.adminidtrateur.email,
        poste: this.adminidtrateur.poste,
    //    id: this.idEtudiant,

      });
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations de l\'utilisateur !';
    });
  }

    console.log('tesssst ngoniit douu5liii ');


  }


/////////////////////// pdf file ::::::::::::::::::::::::::::::::



generatePdf(){
  const documentDefinition = { content: '<html> <a style="color = red;"> lien </a> </html>' };
  pdfMake.createPdf(documentDefinition).open();
 }


  downloadFile(id, nom) {
    this.etudiantService.downloadImage(id).subscribe(
      data => {
        console.log(data);
        saveAs(data, nom)},
      error => console.error(error)
    );
}


  onFileChange(event) {
    if (event.target.files.length > 0) {
      console.log(event);
      const image = event.target.files[0];
      this.editProfilForm.get('image').setValue(image);
      console.log(image);
    }
  }

  get f() { return this.editProfilForm.controls; }

  editProfil() {

    this.submitted = true;

    if (this.editProfilForm.invalid) {
      this.error = 'invalide Form  !';
      console.log(this.editProfilForm);
      return;
    }

    const formData1 = new FormData();
    // formData1 = this.editProfilForm.value;
    console.log('formData1');
    console.log(formData1);
    this.success = null;
    this.error = null;

    if (this.profil === 'Etudiant') {

          // const formData1 = new FormData();
        // const filetest = formData1.append('image', this.editProfilForm.get('image').value);
        // console.log(formData1.get('image'));
        formData1.append('id', this.userable_id);
        console.log(formData1.get('id'));
        formData1.append('cin', this.editProfilForm.get('cin').value);
        console.log(formData1.get('cin'));
        formData1.append('cne', this.editProfilForm.get('cne').value);
        formData1.append('nom', this.editProfilForm.get('nom').value);
        formData1.append('prenom', this.editProfilForm.get('prenom').value);
        formData1.append('email', this.editProfilForm.get('email').value);
        formData1.append('tel', this.editProfilForm.get('tel').value);
        formData1.append('image', this.editProfilForm.get('image').value);

        this.etudiantService.upload(formData1).subscribe(
          (success) => {
            console.log(formData1);
            console.log(success);
            this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
            this.success = 'Etudiant informations updated!';
            // this.router.navigate(['/dashboard']);
            this.ngOnInit();

          },
          (error) => {
            console.log(error);
            this.error = 'Error ! failed to update !';
            this.showNotification('top', 'left', 'CIN ou CNE <b>dèja existe</b> , choisit un nouveau code');

          },
        );

    } else {


    if (this.profil === 'Enseignant' || this.profil === 'ResponsableFormation') {
      this.enseignantService.editProfil(formData1).subscribe(
        (success) => {
          console.log(success);
          this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
          this.success = 'Etudiant informations updated!';
          // this.router.navigate(['/dashboard']);
          this.ngOnInit();

        },
        (error) => {
          console.log(error);
          this.error = 'Error ! failed to update !';
          this.showNotification('top', 'left', 'CIN ou CNE <b>dèja existe</b> , choisit un nouveau code');

        },
      ); }
    if (this.profil === 'Administratif') {
        this.administratifService.editProfil(formData1).subscribe(
          (success) => {
            console.log(success);
            this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
            this.success = 'Etudiant informations updated!';
           // this.router.navigate(['/dashboard']);
           this.ngOnInit();

          },
          (error) => {
            console.log(error);
            this.error = 'Error ! failed to update !';
            this.showNotification('top', 'left', 'CIN ou CNE <b>dèja existe</b> , choisit un nouveau code');

          },
        ); }

    if (this.profil === 'Administrateur') {
          this.administrateurService.editProfil(formData1).subscribe(
            (success) => {
              console.log(success);
              this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
              this.success = 'Etudiant informations updated!';
             // this.router.navigate(['/dashboard']);
             this.ngOnInit();

            },
            (error) => {
              console.log(error);
              this.error = 'Error ! failed to update !';
              this.showNotification('top', 'left', 'CIN ou CNE <b>dèja existe</b> , choisit un nouveau code');

            },
          ); }

    }

  }

  editPassword() {

    const formData1 = this.editPasswordForm.value;
    console.log('formData1');
    console.log(formData1);
    this.profilService.editPassword(formData1).subscribe(

      (success) => {
        console.log(success);
        this.showNotification('top', 'left', 'Votre Mot de Passe a été <b>bien Modifié</b>');
        this.success = 'Etudiant informations updated!';
        this.router.navigate(['/dashboard']);

      },
      (error) => {
        console.log(error);
        this.error = 'Error ! failed to update !';
        this.showNotification('top', 'left', 'Données érronées');

      },
    );

  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

}
