import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnseignantService } from '../../profils/services/enseignant.service';
import { ProjetService } from '../services/projet.service';
import { SoutenanceService } from '../services/soutenance.service';

@Component({
  selector: 'app-edit-soutenance',
  templateUrl: './edit-soutenance.component.html',
  styleUrls: ['./edit-soutenance.component.scss']
})
export class EditSoutenanceComponent implements OnInit {

  idSoutenance = this.route.snapshot.paramMap.get('id');
  editSoutenanceForm: FormGroup;
  error: string;
  idUser = localStorage.getItem('id');
  success: string;
  submitted = false;
  message: '';
  public binome = false;
  public encadrantExterne = false;
  public encadrant = false;
  public buttonBinome: any = 'Binôme';

  public trinome = false;
  public buttonTrinome: any = 'Trinôme';
  userable_id = localStorage.getItem('userable_id');
  soutenance;
  niveaux;
  departement;
  departement2;
  enseignants;
  enseignants2;
  niveau;
  etudiants;
  type;
  projet_id;
  projets;

  constructor(private route: ActivatedRoute,
              private router : Router,
              private projetService: ProjetService,
              private soutenanceService: SoutenanceService,
              private formBuilder: FormBuilder,
              private toastr: ToastrService,
              private enseignantService: EnseignantService,) { }

  ngOnInit() {

    this.projetService.getProjetsByUser(this.idUser).subscribe(
      res => {
        this.projets = res;
        console.log( res);
      },
      error => console.log(error),
    );


    this.success = null;
    this.error = null;
    this.editSoutenanceForm = this.formBuilder.group({
     projet_id: [null, [Validators.required]],
     date: [null, [Validators.required]],
     heureDebut: [null, Validators.required],
     heureFin: [null, Validators.required],
     salle : [null, Validators.required],
     departement: [null, [Validators.required]],
     departement2: [null],
     president: [null, Validators.required],
     examinateur: [null],
     id: [this.idSoutenance, Validators.required]
    });

    this.soutenanceService.getSoutenanceById(this.idSoutenance).subscribe(res => {

      this.soutenance = res;
      console.log(this.soutenance);




      this.editSoutenanceForm.patchValue({
          salle: this.soutenance.salle,
          date: this.soutenance.date,
          heureDebut: this.soutenance.heureDebut,
          heureFin: this.soutenance.heureFin,
          president: this.soutenance.president,
          examinateur: this.soutenance.examinateur,
          projet_id:this.projet_id,
          id: this.idSoutenance,

        });


      }, err => {
        console.log(err);
        this.error = 'Erreur survenue lors de la recuperation des informations du etudiant !';
      });
  }

  get f() { return this.editSoutenanceForm.controls; }

  selectEnseignantByDepartement(enseignant) {
    this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(
      res => this.enseignants = res,
      error => console.log(error),
    );
};


  selectEnseignantByDepartement2(departement2) {
   this.enseignantService.getEnseignantsByDepartement(departement2).subscribe(

  res => this.enseignants2 = res,
  error => console.log(error),
   );
   };

   onSubmit() {

    if(document.activeElement.id === 'finish') {
      this.submitted = true;

      if (this.editSoutenanceForm.invalid) {
        this.error = 'invalide Form  !';
        console.log(this.editSoutenanceForm);
        return;
      }


    const formData = this.editSoutenanceForm.value;

    this.success = null;
    this.error = null;

      this.soutenanceService.editSoutenance(formData).subscribe(
        data => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>.');
          this.router.navigate(['gererSoutenances']);
        },
        error => console.log(error),
      );
    }


   }
   showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

}
