import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EtudiantService } from '../../services/etudiant.service';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from 'src/app/modules/formation/services/niveau.service';
import { Etudiant } from '../../models/etudiant.model';
import { Niveau } from 'src/app/modules/formation/models/niveau.model';

@Component({
  selector: 'app-edit-etudiant',
  templateUrl: './edit-etudiant.component.html',
  styleUrls: ['./edit-etudiant.component.scss']
})
export class EditEtudiantComponent implements OnInit {

  editEtudiantForm: FormGroup;
  error: string;
  success: string;
  etudiant: Etudiant;
  idEtudiant = this.route.snapshot.paramMap.get('id');
  id: number;
  message: '';
  submitted = false;
  niveaux: Niveau;
  niveau_id;
  cv:any=""
  // tslint:disable-next-line: max-line-length
  constructor(

        private etudiantService: EtudiantService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private niveauxService: NiveauService,
    ) {

      niveauxService.getNiveaux().subscribe(
        res => {
              console.log(res);
              this.niveaux = res;
        },

        err => {
              console.log(err);
        }
      );

 }

  ngOnInit() {
    this.success = null;
    this.error = null;
    this.editEtudiantForm = this.formBuilder.group({
      'cin': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      'niveau_id': [null, Validators.required],
      'email': [null, [Validators.required,
        Validators.minLength(10), Validators.maxLength(50),
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      'cne': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      'nom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      'prenom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
       'cv':[""],
      'tel': [null, [Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15)]],
      id: [this.idEtudiant, Validators.required],
    });

    console.log('tesssst ngoniit douu5liii ');

    this.etudiantService.getEtudiantById(this.idEtudiant).subscribe(res => {
      // console.log(res);
      this.etudiant = res;
      this.niveau_id = res.niveau_id;
      console.log(this.niveau_id);

      // tslint:disable-next-line: radix
      this.editEtudiantForm.patchValue({
        cin: this.etudiant.cin,
        niveau_id: res.niveau_id,
        cne: this.etudiant.cne,
        nom: this.etudiant.nom,
        prenom: this.etudiant.prenom,
        email: this.etudiant.email,
        tel: this.etudiant.tel,
        id: this.idEtudiant,

      });
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations de l\'utilisateur !';
    });
  }

  get f() { return this.editEtudiantForm.controls; }

  formDataObject(object:any):FormData{
    var fd=new FormData();
    for (var key in object) {
        if(object.hasOwnProperty(key))
          if(object[key]!=""  && object[key]!=null){
             fd.append(key,object[key]);
          }
    }
    return fd;
}
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const size=event.target.files[0].size;
      console.log(file);

      this.cv=file;

    }
  }  

  editEtudiant() {

    if (document.activeElement.id === 'finish') {

    this.submitted = true;

  

    const formData = this.editEtudiantForm.value;
    
    this.success = null;
    this.error = null;
    const formParsed=this.formDataObject(formData);
    formParsed.set("cv",this.cv)
    this.etudiantService.editEtudiant(formParsed).subscribe(
        (success) => {
          console.log(success);
          this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
          this.success = 'Etudiant informations updated!';
          this.router.navigate(['/etudiants', 'Etudiant']);

        },
        (error) => {
          console.log(error);
          this.error = 'Error ! failed to update !';
          this.showNotification('top', 'left', 'CIN ou CNE <b>dèja existe</b> , choisit un nouveau code');

        },
      );
      }
  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}

