import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing4',
  templateUrl: './landing4.component.html',
  styleUrls: ['./landing4.component.scss']
})
export class Landing4Component implements OnInit {
  constructor() { }
 
  ngOnInit() {
  }

}
