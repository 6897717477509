import { Component, OnInit } from '@angular/core';
import { FormationService } from '../../services/formation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { runInThisContext } from 'vm';

@Component({
  selector: 'app-gerer-formation',
  templateUrl: './gerer-formation.component.html',
  styleUrls: ['./gerer-formation.component.scss']
})
export class GererFormationComponent implements OnInit {

  isDelete = false;
  formations;
  profil = localStorage.getItem('profil');
  toggle = true;
  status = 'Enable';
  idFormation;

  var1 = false;
  var2 ;
  var3= false;


  constructor(
    private formationService: FormationService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
   }

  ngOnInit() {


   if(this.profil == 'Administrateur'){
    this.formationService.getFormations().subscribe(res => {

      console.log(res);

      this.formations = res;


    }, err => {
      console.log(err);


    });}
    else if(this.profil === 'ResponsableFormation') {
      let idResponsable = this.route.snapshot.paramMap.get('id');
      this.formationService.getMaFormation(idResponsable).subscribe(res => {

        console.log(res);

        this.formations = res;


      }, err => {
        console.log(err);


      });
    }
  }

  delete(id) {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette formation? \nLes niveaux associés seront également supprimés.')) {

      this.formationService.deleteFormation(id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          // window.location.reload();
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

      );
    }
    // this.formationService.deleteFormation(id).subscribe();
    // this.router.navigate(['/gererFormation']);

  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

    editRole(id) {

      this.formationService.editRoleResponsableFormation(id).subscribe(
        (data) => {

          console.log(data);
          this.showNotification('top', 'left', 'Le droit du Responsable de Formation a été <b>bien modifiée</b>.');
          // window.location.reload();
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

      );

  }

  toggleFormationDetails(formation) {
    this.var3= false;
    console.log(this.var2 + " "+this.var1)
    this.var2 = formation.id;
    if(formation.id!= this.var2){
      this.var1 = true;
      this.var3= true;
    }else{
      this.var1 =! this.var1;
      this.var3= true;
    }

    }

   }





