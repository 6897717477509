import { OffresService } from './../../services/offre.service';
import { Component, OnInit } from '@angular/core';
import {   } from '../../services/offre.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gerer-Offre',
  templateUrl: './gerer-Offre.component.html',
  styleUrls: ['./gerer-Offre.component.scss']
})
export class GererOffreComponent implements OnInit {

  isDelete = false;
  Offres;
  profil = localStorage.getItem('profil');
  toggle = true;
  status = 'Enable';
  idOffre;

  var1 = false;
  var2 ;
  var3= false;


  constructor(
    private OffresService: OffresService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
   }

  ngOnInit() {


   if(this.profil == 'Administrateur'){
    this.OffresService.getOffres().subscribe(res => {

      console.log(res);

      this.Offres = res;


    }, err => {
      console.log(err);


    });}
  }

  delete(id) {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette Offre? \nLes niveaux associés seront également supprimés.')) {

      this.OffresService.deleteOffres(id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

      );
    }
  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

  toggleOffreDetails(Offre) {
    this.var3= false;
    console.log(this.var2 + " "+this.var1)
    this.var2 = Offre.id;
    if(Offre.id!= this.var2){
      this.var1 = true;
      this.var3= true;
    }else{
      this.var1 =! this.var1;
      this.var3= true;
    }

    }

   }





