import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-formation',
  templateUrl: './list-formation.component.html',
  styleUrls: ['./list-formation.component.scss']
})
export class ListFormationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
