import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from 'src/app/services/token.service';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
	})
};

@Injectable({
  providedIn: 'root'
})
export class CoursService {

  constructor(private http: HttpClient, private router: Router, private _tokenService: TokenService) {}



  // get all courss :
  getCours(): Observable<any> {

    return this.http.get(environment.apiUrl + '/Cours/List', httpOptions);
  }

    // get all cours by users :
    getCoursByUser(id): Observable<any> {

      return this.http.get(environment.apiUrl + '/Cours/ListCoursByUser/' + id, httpOptions);
    }



  // Create cours :
  createCours(data): Observable<any> {

    return this.http.post(environment.apiUrl + '/Cours/Save', data, httpOptions);
  }


  // Delete cours :
  deleteCours(id): Observable<any> {
    // console.log(id + " id of cours service to delete");
    return this.http.delete(environment.apiUrl + '/Cours/Delete/' + id);
  }

  // Archive cours :
  archiveCours(id): Observable<any> {
    // console.log(id + " id of cours service to delete");
    return this.http.delete(environment.apiUrl + '/Cours/Archive/' + id);
  }

  // Unarchive cours :
  restoreCours(id): Observable<any> {
    // console.log(id + " id of cours service to delete");
    return this.http.delete(environment.apiUrl + '/Cours/Restore/'+id);
  }


  // Edit cours :
  editCours(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Cours/Update', data, httpOptions);
    }

  // get Cours By id
    getCoursById(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Cours/' + id, httpOptions);
      }


    // get Cours By Element
    getCoursByElement(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Cours/' + id+'/List', httpOptions);
      }

        // get Cours By id
    getCoursByIdGestionCours(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Cours/Consulter/' + id, httpOptions);
      }
 // get Mes Cours - Enseignant
    getMesCours(id): Observable<any> {
   return this.http.get(environment.apiUrl + '/Cours/MesCours/' + id);
  }
   
  // get Mes Cours Archives - Enseignant
  getMesCoursArchives(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Cours/MesCoursArchives/' + id);
   }

 // get Mes Cours - Enseignant
   getMesCoursByElement(id_user,id_element): Observable<any> {

  return this.http.get(environment.apiUrl+'/Cours/MesCours/User/'+id_user+'/Element/'+id_element);
 }

   // get Mes Cours - Etudiant
 getCoursByEtudiant(id): Observable<any> {
  console.log(id + ' serviiiiiice');
  return this.http.get(environment.apiUrl + '/Cours/Etudiant/' + id);
 }
   // get Cours By Niveau
   getCoursByNiveau(id,idNiveau): Observable<any> {
    return this.http.get(environment.apiUrl + '/Cours/ListByNiveau/User/' +id+'/Niveau/'+idNiveau, httpOptions );
    }


  // get Cours By Module
   getCoursByModule(id,idModule): Observable<any> {
    return this.http.get(environment.apiUrl + '/Cours/ListByModule/User/' + id+'/Module/'+idModule, httpOptions );
    }

  // get Module By Cours
  getModuleByCours(id): Observable<any> {
    return this.http.put(environment.apiUrl + '/Cours/Update', id, httpOptions);
    }

  // get Enseignants By Cours
  getEnseignantsByCours(id): Observable<any> {
    return this.http.put(environment.apiUrl + '/Cours/Update', id, httpOptions);
    }

    // get Documents By Cours
  getDocuments(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Cours/' + id + '/Documents', httpOptions);
    }

        // get Documents By Cours
    downloadFile(id) {
    return this.http.get(environment.apiUrl + '/Document/Download/' + id,
      { responseType: 'blob' ,
      headers: new HttpHeaders().append('content-type', 'application/json')});
    }

     // get Documents By Cours
   getRessources(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Cours/' + id + '/Ressources', httpOptions);
    }

  // get Niveau By Cours
    getNiveauByCours(id): Observable<any> {
    return this.http.put(environment.apiUrl + '/Cours/Update', id, httpOptions);
    }

}
