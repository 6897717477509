import { Component, OnInit } from '@angular/core';
import { CoursService } from '../../services/cours.service';
import { Cours } from '../../models/cours.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-sppression-cours',
  templateUrl: './sppression-cours.component.html',
  styleUrls: ['./sppression-cours.component.scss']
})
export class SppressionCoursComponent implements OnInit {

  profil = localStorage.getItem('profil');
  email: String = localStorage.getItem('email');
  name: String = localStorage.getItem('name');
  id = localStorage.getItem('id');
 // payload:object= localStorage.getItem('payload');
 cours: Cours [] = [];
  constructor(
    private coursService: CoursService,
    private router: Router,
    private toastr: ToastrService,
    ) {
    // console.log(this.profil+ " payload profil");
   }

  ngOnInit() {
    
    if (this.profil === 'Enseignant' || this.profil === 'ResponsableFormation') {

      this.coursService.getMesCours(this.id).subscribe(res => {
        // console.log(designation);
        console.log(this.id + 'get mes cours argument');

        console.log(res);

        this.cours = res;

      }, err => {
        console.log(err);


      });
    } else if (this.profil === 'Administrateur') {
      this.coursService.getCoursByUser(this.id).subscribe(res => {
        // console.log(designation);
        console.log(this.id + 'get mes cours argument');

        console.log(res);

        this.cours = res;

      }, err => {
        console.log(err);


      });
    }

  }

  archive(cour_id) {
    
    if (confirm('Êtes-vous sûr de vouloir supprimer ce cours? \n Les documents associés seront également supprimés.')) {

      this.coursService.archiveCours(cour_id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          // window.location.reload();
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produite lors la suppression</b>.')

      );
    }

  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

}
