import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdministratifService } from '../../services/administratif.service';
import { ToastrService } from 'ngx-toastr';
import { Administratif } from '../../models/administratif.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-administratif',
  templateUrl: './edit-administratif.component.html',
  styleUrls: ['./edit-administratif.component.scss']
})
export class EditAdministratifComponent implements OnInit {

  editAdministratifForm: FormGroup;
  error: string;
  success: string;
  administratif: Administratif;
  idAdministratif = this.route.snapshot.paramMap.get('id');
  id: number;
  message: '';
  submitted = false;

  // tslint:disable-next-line: max-line-length
  constructor(

        private administratifService: AdministratifService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
    ) {

 }

  ngOnInit() {
    this.success = null;
    this.error = null;
    this.editAdministratifForm = this.formBuilder.group({
      'cin': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      'email': [null, [Validators.required,
        Validators.minLength(10), Validators.maxLength(50),
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      'poste': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(25)]],
      'nom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      'prenom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      'tel': [null, [Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15)]],
        'id': [this.idAdministratif, [Validators.required]]


    });

    console.log('tesssst ngoniit douu5liii ');

    this.administratifService.getAdministratifById(this.idAdministratif).subscribe(res => {
       console.log(res);
       this.administratif = res;

      // tslint:disable-next-line: radix
       this.editAdministratifForm.patchValue({
        cin: this.administratif.cin,
        nom: this.administratif.nom,
        prenom: this.administratif.prenom,
        email: this.administratif.email,
        tel: this.administratif.tel,
        poste: this.administratif.poste,
        id: this.idAdministratif,

      });
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations de l\'utilisateur !';
    });
  }

  get f() { return this.editAdministratifForm.controls; }

  editAdministratif() {

    if (document.activeElement.id === 'finish') {

        this.submitted = true;

        if (this.editAdministratifForm.invalid) {
          this.error = 'invalide Form  !';
          console.log(this.editAdministratifForm);
          return;
        }

        const formData = this.editAdministratifForm.value;

        this.success = null;
        this.error = null;
        this.administratifService.editAdministratif(formData).subscribe(
          (success) => {

              this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
              this.success = 'Administratif informations updated!';
              this.router.navigate(['/administratifs', 'Administratif']);

          },
          (error) => {
            console.log(error);
            this.error = 'Error ! failed to update !';
            this.showNotification('top', 'left', 'CIN ou Email <b>dèja existe</b> , choisit un nouveau code');

          },
        );
    }
  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}


