import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ProfilService } from '../services/profil.service';
import { Lauriat } from '../models/lauriat.model';
import { LauriatService } from '../services/lauriat.service';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from '../../formation/services/niveau.service';
import { FormationService } from '../../formation/services/formation.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { FORMATION, getFilterItemInStorage, LAURIAT, NIVEAU, setFilterItemInStorage } from './lauriatFilterStorage';
//import { url } from 'inspector';
import { Etudiant } from '../models/etudiant.model';
import { ifStmt } from '@angular/compiler/src/output/output_ast';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-lauriat',
  templateUrl: './lauriat.component.html',
  styleUrls: ['./lauriat.component.scss']
})
export class LauriatComponent implements OnInit {

  invitationForm: FormGroup;
  error: string;
  userId: number = 1;
  uploadResponse = { status: '', message: '', filePath: '' };
  idUser = localStorage.getItem('id');
  role = this.route.snapshot.paramMap.get('profil');
  lauriats: any[] = [];
  i = 0;
  submitted = false;
  profil = localStorage.getItem('profil');
  userable_id = localStorage.getItem('userable_id');
  designation = getFilterItemInStorage(NIVEAU) ? getFilterItemInStorage(NIVEAU) : "designation";
  niveaux;
  formation = getFilterItemInStorage(FORMATION) ? getFilterItemInStorage(FORMATION) : "formation";
  formations;
  lauriat: any;
  etudiants = [];
  etudiant_id="etudiant";
  isNiveauSelected = getFilterItemInStorage(NIVEAU) ? true : false;
  nom;
  imageDirectoryPath: any = 'http://localhost:8000/public/image/';



  constructor(
    private profilService: ProfilService,
    //private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private lauriatService: LauriatService,
    private toastr: ToastrService,
    private niveauService: NiveauService,
    private formationService: FormationService
  ) {
  }

  ngOnInit(): void {

    if (this.profil === 'ResponsableFormation') {

      this.lauriatService.getLauriatsByResponsable(this.userable_id).subscribe(
        res => this.lauriats = res,

        err => console.log(err),
      );


      this.niveauService.getNiveauByRF(this.idUser).subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );


    } else if (this.profil === 'Administrateur') {

      this.lauriatService.getLauriats().subscribe(
        res => this.lauriats = res,

        err => console.log(err),
      );


      this.formationService.getFormations().subscribe(

        res => this.formations = res,
        error => console.log(error),
      );


      this.niveauService.getNiveauxyByAdmin().subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );
    }

    this.invitationForm = this.formBuilder.group({
      file: ['', Validators.required],
      profil: [this.role, Validators.required]
    });
    if (this.formation !== "formation") {
      this.getByFormation(this.formation);

    }
    if (this.designation !== "designation") {
      this.searchLauriatByNiveau(this.designation);
      this.etudiant_id = getFilterItemInStorage(LAURIAT) ? getFilterItemInStorage(LAURIAT) : "etudiant";
    }
    if (this.etudiant_id && this.etudiant_id !== "etudiant") {
      this.getExperienceByEtudiantId(this.etudiant_id);
    }

  }
  getByFormation(formation) {
    setFilterItemInStorage(FORMATION, formation);
    this.lauriatService.getByFormation(formation).subscribe((res: any) => {
      this.lauriats = res;
    });
    this.niveauService.NiveauxByFormation(formation).subscribe((res: any) => {
      this.niveaux = res;
    })
    this.etudiant_id =  "etudiant";
  }
  searchLauriatByNiveau(niveau_id) {
    setFilterItemInStorage(NIVEAU, niveau_id);

    console.log(niveau_id);
    this.lauriatService.getLauriatsAsEtudiants(niveau_id).subscribe((res: any) => {
      this.etudiants = res;
    })
    this.lauriatService.getLauriatByNiveau(niveau_id).subscribe((res: any) => {
      this.lauriats = res;
    })
    this.isNiveauSelected = true;
    this.etudiant_id = undefined;
  }
  getExperienceByEtudiantId(etudiant_id) {
    setFilterItemInStorage(LAURIAT, etudiant_id);
    this.lauriatService.getLauriatExperienceByEtudiantId(etudiant_id).subscribe((res: any) => {
      this.lauriats = res;
    })
  }
  generatePdf(action = 'open', idLauriat) {

    this.lauriatService.getLauriatById(idLauriat).subscribe(
      res => {

        this.lauriat = res;
        this.nom = this.lauriat.nom;
        console.log(this.nom)

        const documentDefinition = this.getDocumentDefinition(this.lauriat);
        switch (action) {
          case 'open': pdfMake.createPdf(documentDefinition).open(); break;
          case 'print': pdfMake.createPdf(documentDefinition).print(); break;
          case 'download': pdfMake.createPdf(documentDefinition).download(); break;
          default: pdfMake.createPdf(documentDefinition).open(); break;
        }
        console.log(res);


      },
      error => console.log(error),
    );

  }

  getDocumentDefinition(lauriat) {
    return {
      content: [
        {
          text: lauriat.nom + lauriat.prenom,
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [{
              text: 'Nom : ' + lauriat.nom,
              // image: [src] = (this.imageDirectoryPath+lauriat.image)
              style: 'test'
            },
            {
              text: 'Prenom : ' + lauriat.prenom,
            },
            {
              text: 'Code Apogée : ' + lauriat.codeApogee,
            },
            {
              text: 'Code CNE : ' + this.lauriat.cne,
            },
            {
              text: 'Code CIN : ' + this.lauriat.cin,
            },
            {
              text: '    ',
            },
            ],

          ]
        },
        {
          text: 'Contact ',
          style: 'header'
        },

        {
          text: 'Tel : ' + this.lauriat.tel,
        },
        {
          text: 'Email : ' + this.lauriat.email,
        },
        {
          text: 'Image : ' + this.imageDirectoryPath + this.lauriat.image,
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 20, 0, 10],
          decoration: 'underline'
        },
        name: {
          fontSize: 16,
          bold: true
        },
        test: {
          // background : url ='http://notrealdomain2.com/newbanner.png',
        },
        jobTitle: {
          fontSize: 14,
          bold: true,
          // italics: true
        },
        sign: {
          margin: [0, 50, 0, 10],
          alignment: 'right',
          italics: true
        },
        tableHeader: {
          bold: true,
        }
      }
    };
  }
  /*
  
    getProfilePicObject() {
      if (this.lauriat.image) {
        return {
          image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJgAAACHCAYAAADqQ...AABJRU5ErkJggg==' ,
          width: 75,
          alignment : 'right'
        };
      }
      return {
        image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJgAAACHCAYAAADqQ...AABJRU5ErkJggg==' ,
        width: 75,
        alignment : 'right'
      };
    }
  
    */

  searchByFormtion(formation) {

    if (formation == null || formation === 'all' || this.formation === 'formation') {

      this.formation = 'all';

      console.log('alllll');

      this.ngOnInit();

    } else {

      this.formation = formation;

      this.lauriatService.getLauriatsByFormation(formation).subscribe(
        res => this.lauriats = res,
        err => console.log(err),
      );

      this.niveauService.getNiveauxByFormation(formation).subscribe(
        res => this.niveaux = res,
        err => console.log(err),
      );

    }

  }











  onFileChange(event) {
    if (event.target.files.length > 0) {
      console.log(event);
      const file = event.target.files[0];
      let testFile = this.invitationForm.get('file').setValue(file);
      console.log(file);
      console.log(testFile);
    }
  }

  get f() { return this.invitationForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.invitationForm.invalid) {
      this.error = 'invalide Form  !';
      console.log(this.invitationForm);
      return;
    }

    const formData = new FormData();
    const filetest = formData.append('file', this.invitationForm.get('file').value);
    console.log(formData.get('file'));
    const profil = formData.append('profil', this.role);
    console.log(formData.get('profil'));

    this.profilService.sendInvitation(formData).subscribe(
      (res) => {
        console.log(res)
        this.uploadResponse = res;
        this.i = this.i + 1;
        console.log(res);
        console.log(this.i);
        if (this.i === 4) {
          this.showNotification('top', 'left', 'Les invitation ont été <b>bien envoyé</b>.')
        }
      },
      (err) => this.error = err
    );

  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' + align
    });
  }


  delete(id) {

    if (confirm('Êtes-vous sûr de vouloir supprimer cet lauriat?.')) {

      this.lauriatService.deleteLauriat(id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          // window.location.reload();
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

      );
    }

  }


}