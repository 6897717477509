import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ElementService } from '../../services/element.service';
import { ProfilService } from 'src/app/modules/profils/services/profil.service';
import { ToastrService } from 'ngx-toastr';
import { EnseignantService } from 'src/app/modules/profils/services/enseignant.service';

@Component({
  selector: 'app-create-element',
  templateUrl: './create-element.component.html',
  styleUrls: ['./create-element.component.scss']
})
export class CreateElementComponent implements OnInit {

  idModule = this.route.snapshot.paramMap.get('code');
  elementForm: FormGroup;
  success: string;
  error: string;
  cmpt: number;
  submitted = false;
  cinExist: number;
  message: '';
  enseignants;
  departement = 'departement';
  enseignant_cours = 'enseignant_cours';

  enseignants2;
  departement2 = 'departement2';
  enseignant_td = 'enseignant_td';

  enseignants3;
  departement3 = 'departement3';
  enseignant_tp = 'enseignant_tp';

  constructor(
    private elementService: ElementService,
    private enseignantService: EnseignantService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private profilService: ProfilService,
    private toastr: ToastrService

  ) {

    this.cmpt = 0;

  }

  ngOnInit() {


    this.elementForm = this.formBuilder.group({
      code: [null, [Validators.required]],
      departement: [null, Validators.required],
      departement2: [null],
      departement3: [null],
      designation: [null, [Validators.required]],
      module_id: [this.idModule, Validators.required],
      enseignant_cours: [null, [Validators.required]],
      enseignant_tp: [null],
      enseignant_td: [null],
    }

    );

    console.log(this.elementForm.get('code').value);
  }

  get f() { return this.elementForm.controls; }


  createElement() {

    if (document.activeElement.id === 'finish') {
        this.submitted = true;

        if (this.elementForm.invalid || this.elementForm.get('departement').value === 'departement' || this.elementForm.get('enseignant_cours').value === 'enseignant_cours') {
          this.error = 'invalide Form  !';
          console.log(this.elementForm);
          return;
        } else {

        const formData = this.elementForm.value;

        this.error = null;
        this.elementService.createElement(formData).subscribe(
          data => {

              let idModule = this.idModule;
              this.router.navigate(['/gererElement', idModule]);
              this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>.');

          },

          error => {
            console.log(error);
            this.showNotification('top', 'left', 'Code Element <b>dèja existe</b> , choisit un nouveau code')
          }
        );
        }

    }
  }

    selectEnseignant(enseignant) {
      this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(

        res => this.enseignants = res,
        error => console.log(error),
      );
      console.log(this.enseignants);
  };


  selectEnseignantByDepartement(enseignant) {
    this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(

      res => this.enseignants = res,
      error => console.log(error),
    );
};


selectEnseignantByDepartement2(enseignant) {
  this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(

    res => this.enseignants2 = res,
    error => console.log(error),
  );
};

selectEnseignantByDepartement3(enseignant) {
  this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(

    res => this.enseignants3 = res,
    error => console.log(error),
  );
};

    showNotification(from, align, message) {

      const color = Math.floor((Math.random() * 5) + 1);

      this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
        timeOut: 6000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-' + from + '-' +  align
         });
      }
}
