import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnseignantService } from '../../profils/services/enseignant.service';
import { ExcelSoutenancesService } from '../services/excel-soutenances.service';
import { ProjetService } from '../services/projet.service';
import { SoutenanceService } from '../services/soutenance.service';

@Component({
  selector: 'app-consultation-soutenances',
  templateUrl: './consultation-soutenances.component.html',
  styleUrls: ['./consultation-soutenances.component.scss']
})
export class ConsultationSoutenancesComponent implements OnInit {

  idUser = localStorage.getItem('id');
  profil = localStorage.getItem('profil');
  name = localStorage.getItem('name');
  soutenances;
  excel = [];
  etudiants = '';
  juries = '';
  encadrants = '';

  constructor(
      private http: HttpClient,
      private route: ActivatedRoute,
      private soutenanceService: SoutenanceService,
      private toastr: ToastrService,
      private projetService: ProjetService,
      private excelSoutenancesService: ExcelSoutenancesService
    ) { }

ngOnInit(){

  this.soutenanceService.getSoutenancesByUser(this.idUser).subscribe(res => {
    console.log(this.idUser);
    console.log(res);
    this.soutenances = res;

    this.soutenances.forEach(element => {

      element['isEdit'] = false;
      console.log(element.projet_id);
      this.projetService.getFormationByProjet(element.projet_id).subscribe(
        res => {
          console.log(res.code);
          element['formation_code'] = res.code;
        }
      );

    });
  }, err => {
    console.log(err);
  });


  this.soutenanceService.getJSON(FormData).subscribe(data => {


        data.forEach(element => {

          // etudiants :::::::::
          element.etudiants.forEach(etudiant => {
            console.log(etudiant);
              this.etudiants = this.etudiants + etudiant.nom + ' ' + etudiant.prenom + ';';

              console.log(this.etudiants);

          });

          element['etudiants'] = this.etudiants;
          console.log(this.etudiants);

          // juries :::::::::::::::::::

          element.juries.forEach(jury => {
            console.log(jury);
              this.juries = this.juries + jury.nom + ' ' + jury.prenom + ';';

              console.log(this.juries);

          });

          element['juries'] = this.juries;
          console.log(this.juries);

          // encadrants :::::::::::::::::
          element.encadrants.forEach(encadrant => {
            console.log(encadrant);
              this.encadrants = this.encadrants + encadrant.nom + ' ' + encadrant.prenom + ';';

              console.log(this.encadrants);

          });

          element['encadrants'] = this.encadrants;
          console.log(this.encadrants);
          ////////////////////////////////

        });

        console.log(data)

        data.forEach(row => {
          console.log(`testtttttttttttttttt`)

      //row['etudiants'] = data.etudiants;
        console.log(row.encadrantExterne);
      this.excel.push(row);
    })
   });


 }

 exportAsXLSX(): void {
  this.excelSoutenancesService.exportAsExcelFile(this.excel, 'sample');
}


}
