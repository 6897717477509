import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing2',
  templateUrl: './landing2.component.html',
  styleUrls: ['./landing2.component.scss']
})
export class Landing2Component implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
