import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token')
  })
};

@Injectable({
  providedIn: 'root'
})
export class LauriatService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }


  // get all Lauriats :
  getLauriats(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Lauriat');
  }

  // get all Lauriats By Responsable :
  getLauriatsByResponsable(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Lauriat/ListByLauriat/' + id);
  }

  // get all Lauriats By Responsable :
  getLauriatsAsEtudiants(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/lauriatEtudiant/niveau/' + id);
  }
  getLauriatExperienceByEtudiantId(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Lauriat/' + id);
  }

  getLauriatByNiveau(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/lauriat/niveau/' + id);
  }



  // get all Lauriats By Responsable :
  getLauriatsByDepartementByResponsable(id, departement): Observable<any> {
    return this.http.get(environment.apiUrl + '/Lauriat/ListByLauriat/' + id + '/' + departement);
  }
 

  // get all Lauriats By Departement :
  getLauriatsByDepartement(departement): Observable<any> {
    return this.http.get(environment.apiUrl + '/Lauriat/ListByDepartement/' + departement);
  }

  getByFormation(formation_id): Observable<any> {
    return this.http.get(environment.apiUrl + '/lauriat/formation/' + formation_id);
  }
  getByNiveau(niveau_id) {
    return this.http.get(environment.apiUrl + '/lauriat/niveau/' + niveau_id);

  }

  // get all Lauriats By Responsable :
  getLauriatsByFormationByResponsable(id, departement): Observable<any> {
    return this.http.get(environment.apiUrl + '/Lauriat/ListByLauriat/' + id + '/' + departement);
  }

  // get all Lauriats By Formation :
  getLauriatsByFormation(formation): Observable<any> {
    return this.http.get(environment.apiUrl + '/Lauriat/ListByFormation/' + formation);
  }


  // get all Lauriats By Responsable :
  getLauriatsByNiveauByResponsable(id, departement): Observable<any> {
    return this.http.get(environment.apiUrl + '/Lauriat/ListByLauriat/' + id + '/' + departement);
  }

  // get all Lauriats By Niveau :
  getLauriatsByNiveau(niveau): Observable<any> {
    return this.http.get(environment.apiUrl + '/Lauriat/ListLauriatByNiveau/' + niveau);
  }

  // get elements :
  getElements(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Lauriat/Elements/' + id);
  }


  // Create Lauriats :
  createLauriat(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/Lauriat', data);
  }

  // Delete Lauriats :
  deleteLauriat(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Lauriat/' + id);
  }

  // Edit Lauriats :
  editLauriat(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Lauriat/'+data.experience_id, data);
  }

  editProfil(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Lauriat/Edit', data);
  }


  // get Lauriat By id
  getLauriatById(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/singleLauriat/' + id, httpOptions);
  }
}
