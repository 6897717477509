import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursService } from 'src/app/modules/formation/services/cours.service';
import { RessourceService } from 'src/app/modules/formation/services/ressource.service';
import { Ressource } from 'src/app/modules/formation/models/ressource.model';
import saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-list-ressources',
  templateUrl: './list-ressources.component.html',
  styleUrls: ['./list-ressources.component.scss']
})
export class ListRessourcesComponent implements OnInit {

  ressources: Ressource;
  profil = localStorage.getItem('profil');


  constructor(

    private route: ActivatedRoute,
    private coursService: CoursService,
    private ressourceService: RessourceService,
    private toastr: ToastrService,

  ) {

    let id = localStorage.getItem('id');

    ressourceService.getAllRessourcesByUser(id).subscribe(res => {
      console.log(res);
      this.ressources = res;
    }, err => {
      console.log(err);
    });
   }

  ngOnInit() {
  }


showNotification(from, align, message) {

  const color = Math.floor((Math.random() * 5) + 1);

  this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
    timeOut: 6000,
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-danger alert-with-icon',
    positionClass: 'toast-' + from + '-' +  align
     });
  }
}


