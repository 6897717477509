import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CoursService } from '../../services/cours.service';
import { ElementService } from '../../services/element.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnseignantService } from 'src/app/modules/profils/services/enseignant.service';

@Component({
  selector: 'app-create-cours',
  templateUrl: './create-cours.component.html',
  styleUrls: ['./create-cours.component.scss']
})
export class CreateCoursComponent implements OnInit {

  public coursForm: FormGroup;
  error: string;
  success: string;
  isButton : boolean = true;
  elements;
  profil = localStorage.getItem('profil');
  departement = 'departement';
  selectedElement = 'selectedElement';
  selectedRestriction = 'selectedRestriction';
  email = 'email';
  enseignants;
  cours:
  {

 };

 submitted = false;
 cinExist: number;
 message: '';

idUser = localStorage.getItem('id');
idEnseignant = localStorage.getItem('userable_id');
  constructor(
      private coursService: CoursService,
      private elementsService: ElementService,
      private toastr: ToastrService,
      private router: Router,
      private formBuilder: FormBuilder,
      private enseignantService: EnseignantService,

    ) {

      if (this.profil === 'Administrateur') {

        elementsService.Elements().subscribe(
          res => {
                console.log(res);
                this.elements = res;
          },

          err => {
                console.log(err);
          }
        );

      } else {
        elementsService.getElementsByEnseignant(this.idEnseignant ).subscribe(
          res => {
                console.log(res);
                this.elements = res;
          },

          err => {
                console.log(err);
          }
        );
      }




     }

  ngOnInit() {

    let email = localStorage.getItem('email');
    console.log(email);

    this.success = null;
    this.error = null;
    if (this.profil === 'Administrateur') {
      this.coursForm = this.formBuilder.group({
        titre: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
        element_id: [null, Validators.required],
        email: [null, Validators.required],
        visibility: [null, Validators.required],
        description: [null, [Validators.maxLength(1000)]],
        departement: [null, Validators.required],
      });
    } else {
      this.coursForm = this.formBuilder.group({
        titre: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
        element_id: [null, Validators.required],
        email: [email, Validators.required],
        visibility: [null, Validators.required],
        description: [null, [Validators.maxLength(1000)]],
    })

    }

  }




  selectEnseignant(departement) {
    this.enseignantService.getEnseignantsByDepartement(departement).subscribe(

      res => this.enseignants = res,
      error => console.log(error),
    );
};

  get f() { return this.coursForm.controls; }

  createCours() {

    if ( document.activeElement.id === 'passeToDoc' || document.activeElement.id === 'finish' ) {
      this.submitted = true;

      if (this.profil === 'Administrateur') {
          if (this.coursForm.invalid || this.coursForm.get('departement').value === 'departement' || this.coursForm.get('element_id').value === 'selectedElement' || this.coursForm.get('email').value === 'email' || this.coursForm.get('visibility').value === 'selectedRestriction') {
            this.error = 'invalide Form  !';
            console.log(this.coursForm);
            return;
          } else {

              const formData = this.coursForm.value;

              this.success = null;
              this.error = null;
              this.coursService.createCours(formData).subscribe(

                (data) => {

                  let titre = formData.titre;

                  if (document.activeElement.id === 'passeToDoc') {
                this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>. Vous pouvez ajouter des document de ce cours.'),

                this.router.navigate(['/createDocument', titre, data.id]);
              } else if (document.activeElement.id === 'finish') {
                this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>');

                this.router.navigate(['/cours']);
              }


                },

              (error) => error
              );
          }
    } else if ( this.profil === 'Enseignant' || this.profil === 'ResponsableFormation' ) {

      if (this.coursForm.invalid || this.coursForm.get('element_id').value === 'selectedElement' || this.coursForm.get('visibility').value === 'selectedRestriction') {
        this.error = 'invalide Form  !';
        console.log(this.coursForm);
        return;
      } else {

          const formData = this.coursForm.value;

          this.success = null;
          this.error = null;
          this.coursService.createCours(formData).subscribe(

            (data) => {

              let titre = formData.titre;

              if (document.activeElement.id === 'passeToDoc') {
            this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>. Vous pouvez ajouter des document de ce cours.'),

            this.router.navigate(['/createDocument', titre, data.id]);
          } else if (document.activeElement.id === 'finish') {
            this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>');
            this.router.navigate(['/gestionMesCours']);
          }


            },

          (error) => error
          );
      }
    }

    }


    }

    showNotification(from, align, message) {

      const color = Math.floor((Math.random() * 5) + 1);

      this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
        timeOut: 6000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-' + from + '-' +  align
         });
      }
}
