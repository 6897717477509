import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ProfilService } from '../services/profil.service';

@Component({
  selector: 'app-response-invitation',
  templateUrl: './response-invitation.component.html',
  styleUrls: ['./response-invitation.component.scss']
})
export class ResponseInvitationComponent implements OnInit {

  public error = [];
  public form = {
    email : null,
    profil : null,
    password : null,
    password_confirmation : null,
    // resetToken : null,
  };
  constructor(
      private route: ActivatedRoute,
      private profilService: ProfilService,
      // private notify: SnotifyService,
      private router: Router,

  ) {

      route.queryParams.subscribe(params => {
        this.form.profil = params['profil'],
        this.form.email = params['email']

      });

      console.log( this.form.profil);
      console.log( this.form.email);

  }

  ngOnInit() {
  }

  onSubmit() {
    this.profilService.responseInvitation(this.form).subscribe(

      data => this.handleResponse(data),
      error => this.handleError(error)

    );
  }

  handleResponse(res) {
    this.router.navigateByUrl('/login');
  }

  handleError(error) {

    this.error = error.error.errors;
  }

}

