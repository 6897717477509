import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministrateurComponent } from './administrateur/administrateur.component';
import { AdministratifComponent } from './administratif/administratif.component';
import { EtudiantComponent } from './etudiant/etudiant.component';
import { EnseignantComponent } from './enseignant/enseignant.component';
import { AdministratifListComponent } from './administratif-list/administratif-list.component';
import { EtudiantListComponent } from './etudiant-list/etudiant-list.component';
import { EnseignantListComponent } from './enseignant-list/enseignant-list.component';
import { SendInvitationComponent } from './send-invitation/send-invitation.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ResponseInvitationComponent } from './response-invitation/response-invitation.component';
import { MatCardModule } from '@angular/material/card';
import { CreateEtudiantComponent } from './etudiant/create-etudiant/create-etudiant.component';
import { CreateEnseignantComponent } from './enseignant/create-enseignant/create-enseignant.component';
import { CreateAdministratifComponent } from './administratif/create-administratif/create-administratif.component';
import { EditAdministratifComponent } from './administratif/edit-administratif/edit-administratif.component';
import { EditEnseignantComponent } from './enseignant/edit-enseignant/edit-enseignant.component';
import { EditEtudiantComponent } from './etudiant/edit-etudiant/edit-etudiant.component';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { ImageEtudiantComponent } from './etudiant/image-etudiant/image-etudiant.component';
import { LauriatComponent } from './lauriat/lauriat.component';
import { CreateLauriatComponent } from './lauriat/create-lauriat/create-lauriat.component';
import { EditLauriatComponent } from './lauriat/edit-lauriat/edit-lauriat.component';
import { CreateOffreComponent } from './offre/offre/create-offre/create-offre.component';
import { EditOffreComponent } from './offre/offre/edit-offre/edit-offre.component';
import { GererOffreComponent } from './offre/offre/gerer-offre/gerer-offre.component';



@NgModule({
  declarations: [
    AdministrateurComponent,
    AdministratifComponent,
    EtudiantComponent,
    EnseignantComponent,
    AdministratifListComponent,
    EtudiantListComponent,
    EnseignantListComponent,
    SendInvitationComponent,
    ResponseInvitationComponent,
    CreateEtudiantComponent,
    CreateEnseignantComponent,
    CreateAdministratifComponent,
    EditAdministratifComponent,
    EditEnseignantComponent,
    EditEtudiantComponent,
    EditPasswordComponent,
    ImageEtudiantComponent,
    LauriatComponent,
    CreateLauriatComponent,
    CreateOffreComponent,
    EditOffreComponent,
    GererOffreComponent,
    EditLauriatComponent
    ],

  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCardModule,

  ],
  exports: [
    AdministrateurComponent,
    AdministratifComponent,
    EtudiantComponent,
    EnseignantComponent
  ]
})
export class ProfilsModule { }
