import { Component, OnInit } from '@angular/core';
import { CoursService } from '../../services/cours.service';
import { Cours } from '../../models/cours.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NiveauService } from '../../services/niveau.service';
import { ModuleService } from '../../services/module.service';
import { Niveau } from '../../models/niveau.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
@Component({
  selector: 'app-cours',
  templateUrl: './cours.component.html',
  styleUrls: ['./cours.component.scss']
})
export class CoursComponent implements OnInit {

 // static nb = 0;
  cours: Cours [] = [];
  role: string = 'profil';
  niveaux: Niveau [] = [];
  niveau;
  modules;
  module;
  searchByNiveauForm: FormGroup;
  error: string;
  success: string;
  module_designation: number;
  designation_module = 'designation_module';

  niveau_designation;
  myParam: string;
 designation = 'designation';
  id = localStorage.getItem('id');

  constructor(private coursService: CoursService,
              private router: Router,
              private niveauService: NiveauService,
              private moduleService: ModuleService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private location: Location
           )
  {

    // let id = this.route.snapshot.paramMap.get('id');

    this.niveau = 'all';
    this.module = 'all';
    console.log(this.niveau);
    niveauService.getNiveaux().subscribe(
      res => {

            this.niveaux = res;
      },

      err => {
            console.log(err);
      }
    );
    this.designation = null ;
    //get all modules
   this.moduleService.getModules().subscribe(
    res => this.modules = res,
    error => console.log(error),
  );

    console.log('test roooleeeeee');
    const rolee = localStorage.getItem(this.role);
    console.log(rolee);

   }



  ngOnInit() {

    // let id = this.route.snapshot.paramMap.get('id');

    this.coursService.getCoursByUser(this.id).subscribe(res => {

     this.cours = res;

    }, err => {
      console.log(err);

    });

    const rolee = localStorage.getItem(this.role);
    console.log(rolee);
  }


  public localStorageItem(role: string): string {
    const rolee = localStorage.getItem(this.role);
    //console.log('After    ' + rolee);
    return rolee;
}



searchByNiveau(niveau){

  // let id = this.route.snapshot.paramMap.get('id');
  this.niveau= niveau;
  this.module = 'all';
  //get all modules

  if (niveau == null || niveau === 'all' || niveau === 'designation') {
      this.coursService.getCoursByUser(this.id).subscribe(res => {
      this.cours = res;
    }, err => {
      console.log(err);
   });

   this.moduleService.getModules().subscribe(
    res => this.modules = res,
    error => console.log(error),
  );

  } else {
      //get Modules By Niveau
      this.niveauService.getModulesByNiveau(niveau).subscribe(

        res => this.modules = res,
        error => console.log(error),
      );

      this.coursService.getCoursByNiveau(this.id,niveau).subscribe(res => {
        this.cours = res;
      }, err => {
        console.log(err);
      });
    }
}

searchByModule(module){

  // let id = this.route.snapshot.paramMap.get('id');
  this.module= module;

  if(this.niveau ==='all' || this.niveau === null || this.niveau === 'designation'){
  if (module == null || module === 'all' || module === 'designation_module') {
      this.coursService.getCoursByUser(this.id).subscribe(res => {
      this.cours = res;
    }, err => {
      console.log(err);
   });

  } else {
    this.coursService.getCoursByModule(this.id,module).subscribe(res => {
      this.cours = res;
    }, err => {
      console.log(err);
    });
    }
  } else {
    if (module == null || module === 'all') {
      this.coursService.getCoursByNiveau(this.id,this.niveau).subscribe(res => {
      this.cours = res;
    }, err => {
      console.log(err);
   });

  } else {

    this.coursService.getCoursByModule(this.id,module).subscribe(res => {
      this.cours = res;
    }, err => {
      console.log(err);
    });

    }
  }
}



onSelect(cour) {

  this.router.navigate(['/consulterCours', cour.id]);

}


  delete() {

  }

  /*_______________ Search _______________*/

  Search() {


    if (this.designation !== '') {

      this.cours = this.cours.filter(res => {
        return res.niveau_designation.toLocaleLowerCase().match(this.designation.toLocaleLowerCase());
      });

    } else {
        this.ngOnInit();
      }

  }

}
