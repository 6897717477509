import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjetService } from 'src/app/modules/projet/services/projet.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from '../../formation/services/niveau.service';
import { Niveau } from '../../formation/models/niveau.model';
import { EnseignantService } from '../../profils/services/enseignant.service';
import { EtudiantService } from '../../profils/services/etudiant.service';

@Component({
  selector: 'app-create-projet',
  templateUrl: './create-projet.component.html',
  styleUrls: ['./create-projet.component.scss']
})
export class CreateProjetComponent implements OnInit {

  projetForm: FormGroup;
  error: string;
  idUser = localStorage.getItem('id');
  success: string;
  submitted = false;
  message: '';
  public binome = false;
  public encadrantExterne = false;
  public encadrant2Exist = false;
  public buttonBinome: any = 'Binôme';

  public trinome = false;
  public buttonTrinome: any = 'Trinôme';
  userable_id = localStorage.getItem('userable_id');
  niveaux;
  departement = 'departement';
  departement2 = 'departement2';
  enseignants;
  enseignants2;
  niveau = 'niveau';
  etudiants;
  type = 'type';
  profil = localStorage.getItem('profil');
  encadrant = 'encadrant';
  encadrant2 = 'encadrant2';
  etudiant = 'etudiant';
  etudiant2 = 'etudiant2';
  etudiant3 = 'etudiant3';
  allEnseignants = [];

  constructor(
    private projetService: ProjetService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private niveauService: NiveauService,
    private enseignantService: EnseignantService,
    private etudiantService: EtudiantService

  ) {

  }

    removeCurrentTeacherFromTeachersOption() {
      console.log(this.encadrant)
      this.enseignants2 = this.allEnseignants.filter(enseignant => enseignant.id != this.encadrant)
    }
    removeSecondTeacherFromTeachersOption(){
      console.log(this.encadrant2)
      this.enseignants = this.allEnseignants.filter(enseignant => enseignant.id != this.encadrant2)
    }
  ngOnInit() {

    if (this.profil === 'Administrateur') {
      this.enseignantService.getEnseignants().subscribe((res: any) => {
        this.enseignants = res;
        this.enseignants2 = res;
        this.allEnseignants = res;
      })

      this.niveauService.getNiveauxyByAdmin().subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );
    } else if (this.profil === 'ResponsableFormation') {

      // let idUser = localStorage.getItem('id');
      console.log('iddddd :::::::' + this.idUser);
      this.niveauService.getNiveauByRF(this.idUser).subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );
    }

    this.success = null;
    this.error = null;

    if (this.profil === 'Administrateur' || this.profil === 'ResponsableFormation') {

      this.projetForm = this.formBuilder.group({
        sujet: [null, [Validators.required]],
        type: [null, [Validators.required]],
        encadrant: [null, Validators.required],
        encadrant2: [null],
        niveau: [null, Validators.required],
        etudiant: [null, Validators.required],
        etudiant2: [null],
        etudiant3: [null],
        date_debut:[null], 
        date_fin:[null],
        nomEncadrantExterne: [null, Validators.required],
        emailEncadrantExterne: [null],
        telEncadrantExterne: [null],
        'departement': [null, [Validators.required]],
        'departement2': [null],
        societe: [null, Validators.required],


      });

    } else if (this.profil === 'Etudiant') {

      this.projetForm = this.formBuilder.group({
        sujet: [null, [Validators.required]],
        type: [null, [Validators.required]],
        encadrant: [null, Validators.required],
        encadrant2: [null],
        niveau: [null],
        etudiant: [this.userable_id, Validators.required],
        etudiant2: [null],
        etudiant3: [null],
        date_debut:[null], 
        date_fin:[null],
        nomEncadrantExterne: [null, Validators.required],
        emailEncadrantExterne: [null],
        telEncadrantExterne: [null],
        'departement': [null, [Validators.required]],
        'departement2': [null],
        societe: [null, Validators.required],


      });

    }


    /* this.niveauService.NiveauxByEnseignant(this.userable_id).subscribe(

      res => {
        console.log(res);
        this.niveaux = res;
      },
      error => console.log(error),
     );*/
  }



  selectEnseignantByDepartement(departement) {
    this.enseignantService.getEnseignantsByDepartement(departement).subscribe(

      res => this.enseignants = res,
      error => console.log(error),
    );
  };

  selectEnseignantByDepartement2(departement2) {
    this.enseignantService.getEnseignantsByDepartement(departement2).subscribe(

      res => this.enseignants2 = res,
      error => console.log(error),
    );
  };

  selectNiveaux() {

    if (this.profil === 'Administrateur') {
      this.niveauService.getNiveauxyByAdmin().subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );
    } else if (this.profil === 'ResponsableFormation') {

      this.niveauService.getNiveauByRF(this.idUser).subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );
    }
  }

  selectEtudiantByNiveau(niveau) {
    console.log(niveau);
    this.niveauService.getEtudiantsByNiveau(niveau).subscribe(

      res => this.etudiants = res,
      error => console.log(error),
    );
    console.log(this.etudiants);
  };


  toggleBinome() {
    this.binome = !this.binome;
    this.trinome = false;
    // this.projetForm.get('etudiant3').value === null;

  }


  toggleTrinome() {
    this.trinome = !this.trinome;
    this.binome = false;

  }

  toggleEncadrant() {
    this.encadrant2Exist = !this.encadrant2Exist;
    // this.projetForm.get('etudiant3').value === null;

  }

  toggleEncadrantExterne() {
    this.encadrantExterne = !this.encadrantExterne;
    // this.projetForm.get('etudiant3').value === null;

  }

  get f() { return this.projetForm.controls; }


  onSubmit() {

    if (document.activeElement.id === 'finish') {
      this.submitted = true;

      if ( this.projetForm.get('type').value === 'type' || this.projetForm.get('niveau').value === 'niveau' || this.projetForm.get('etudiant').value === 'etudiant' || this.projetForm.get('encadrant').value === 'encadrant') {
        this.error = 'invalide Form  !';
        console.log(this.projetForm);
        return;
      } else {

        const formData = this.projetForm.value;

        this.success = null;
        this.error = null;
        console.log(this.projetForm);

        this.projetService.createProjet(formData).subscribe(
          data => {
            this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>. Vous pouvez ajouter un autre projet');
            this.router.navigate(['/gererProjets']);
          },
          error => console.log(error),
        );
      }

    }


  }
  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' + align
    });
  }
}
