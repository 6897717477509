import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EnseignantService } from '../../services/enseignant.service';
import { ToastrService } from 'ngx-toastr';
import { Enseignant } from '../../models/enseignant.model';

@Component({
  selector: 'app-edit-enseignant',
  templateUrl: './edit-enseignant.component.html',
  styleUrls: ['./edit-enseignant.component.scss']
})
export class EditEnseignantComponent implements OnInit {

  editEnseignantForm: FormGroup;
  error: string;
  success: string;
  enseignant: Enseignant;
  idEnseignant = this.route.snapshot.paramMap.get('id');
  id: number;
  message: '';
  submitted = false;

  // tslint:disable-next-line: max-line-length
  constructor(

        private enseignantService: EnseignantService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
    ) {

 }

  ngOnInit() {
    this.success = null;
    this.error = null;
    this.editEnseignantForm = this.formBuilder.group({
      'cin': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      'email': [null, [Validators.required,
        Validators.minLength(10), Validators.maxLength(50),
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      'nom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      'prenom': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      'tel': [null, [Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15)]],
      id: [this.idEnseignant, Validators.required],
    });

    console.log('tesssst ngoniit douu5liii ');

    this.enseignantService.getEnseignantById(this.idEnseignant).subscribe(res => {
      // console.log(res);
      this.enseignant = res;

      // tslint:disable-next-line: radix
      this.editEnseignantForm.patchValue({
        cin: this.enseignant.cin,
        nom: this.enseignant.nom,
        prenom: this.enseignant.prenom,
        email: this.enseignant.email,
        tel: this.enseignant.tel,
        id: this.idEnseignant,

      });
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations de l\'utilisateur !';
    });
  }

  get f() { return this.editEnseignantForm.controls; }

  editEnseignant() {

    if (document.activeElement.id === 'finish') {

        this.submitted = true;

        if (this.editEnseignantForm.invalid) {
          this.error = 'invalide Form  !';
          console.log(this.editEnseignantForm);
          return;
        }

        const formData = this.editEnseignantForm.value;

        this.success = null;
        this.error = null;
        this.enseignantService.editEnseignant(formData).subscribe(
          (success) => {

              this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
              this.success = 'Enseignant informations updated!';
              this.router.navigate(['/enseignants', 'Enseignant']);

          },
          (error) => {
            console.log(error);
            this.error = 'Error ! failed to update !';
            this.showNotification('top', 'left', 'CIN ou Email <b>dèja existe</b> , choisit un nouveau code');

          },
        );
    }
  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}

