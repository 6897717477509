import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ProfilService } from '../services/profil.service';
import { Administratif } from '../models/administratif.model';
import { AdministratifService } from '../services/administratif.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-administratif',
  templateUrl: './administratif.component.html',
  styleUrls: ['./administratif.component.scss']
})
export class AdministratifComponent implements OnInit {

  invitationForm: FormGroup;
  error: string;
  userId: number = 1;
  uploadResponse = { status: '', message: '', filePath: '' };
  idUser = localStorage.getItem('id');
  role = this.route.snapshot.paramMap.get('profil');
  administratifs: Administratif [] = [];
  i = 0;
  submitted = false;


  constructor(
    private profilService: ProfilService,
    //private toastr: ToastrService,
    private router : Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private administratifService: AdministratifService,
    private toastr: ToastrService,
  ) {
   }

  ngOnInit(): void {

    this.administratifService.getAdministratifs().subscribe(
      res => {
            //console.log(res);
            this.administratifs = res;
      },

      err => {
            console.log(err);
      }
    );

    this.invitationForm = this.formBuilder.group({
      file: ['', Validators.required],
      profil: [this.role, Validators.required]
    });

  }


  onFileChange(event) {
    if (event.target.files.length > 0) {
      console.log(event);
      const file = event.target.files[0];
      let testFile = this.invitationForm.get('file').setValue(file);
      console.log(file);
      console.log(testFile);
    }
  }

  get f() { return this.invitationForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.invitationForm.invalid) {
      this.error = 'invalide Form  !';
      console.log(this.invitationForm);
      return;
    }
    const formData = new FormData();
    const filetest = formData.append('file', this.invitationForm.get('file').value);
    console.log(formData.get('file'));
    const profil = formData.append('profil', this.role);
    console.log(formData.get('profil'));

    this.profilService.sendInvitation(formData).subscribe(
      (res) => {
        console.log(res)
        this.uploadResponse = res;
        this.i = this.i + 1;
        console.log(res);
        console.log(this.i);
        if (this.i === 4) {
          this.showNotification('top', 'left', 'Les invitation ont été <b>bien envoyé</b>.')
        }
      },
      (err) => this.error = err
    );

  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

    delete(id) {

      if (confirm('Êtes-vous sûr de vouloir supprimer cet administratif?.')) {

        this.administratifService.deleteAdministratif(id).subscribe(
          (data) => {
            this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
            // window.location.reload();
            this.ngOnInit();
          },
          (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

        );
      }

    }
}

