import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';

@Injectable({
	providedIn: 'root'
})

export class AfterLoginGuard implements CanActivate {

	path: ActivatedRouteSnapshot[];
	route: ActivatedRouteSnapshot;

	result: any = [];
	key: string = 'token';
  role: string = 'profil';
  
  rolesType: any = [];


	/**
	 * Creates an instance of after login guard
	 * @param _tokenService
	 * @param router
	 */
	constructor(
		private router: Router,
		private _tokenService: TokenService,
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
		boolean | Observable<boolean> | Promise<boolean> {
		return new Promise((resolve, reject) => {
			const val = localStorage.getItem(this.key);
      const role = localStorage.getItem(this.role);
      let expectedRole = route.data.expectedRole;
       // this.expectedRole = ['Administrateur','Etudiant'];
      // console.log('expecteeed role ');
      // console.log(expectedRole);

// console.log('tableee length :::::::::' + expectedRole.length);
      for (var i=0; i < expectedRole.length ; i++) {
        this.rolesType.push( expectedRole[i] );
    }

    // console.log(this.rolesType)


			if (val !== null && val !== undefined) {


        for (let child of expectedRole ) {

          let test = expectedRole; // to see if the value is readable or not
          // console.log(test);

          if ( role === child) {
            // console.log(child);
            resolve(true);
          }
        }



			//	resolve(true);
			} else {
				//// console.log("There is no name stored");
				this.router.navigateByUrl('/login');
				resolve(false);
			}

		});
	}

}
