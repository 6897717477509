import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from  'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProjetService {

  SERVER_URL: string = "http://localhost:8000/api";


  constructor(private http: HttpClient, private router: Router) { }



    // Create Ressource :
    createProjet(data): Observable<any> {
      console.log(data.titre);
      console.log(data.description);
      return this.http.post(environment.apiUrl + '/Projet/Save', data, httpOptions);
    }

      // get Projets
  getProjets(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Projet/List', httpOptions);
    }

  // get Projet By id
  getProjetById(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Projet/' + id, httpOptions);
    }

    //get encadrants
    getEncadrantsByProjet(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Projet/' + id+'/Encadrants', httpOptions);
    }

    //get etudiants
    getEtudiantsByProjet(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Projet/' + id+'/Etudiants', httpOptions);
    }

  // Edit Projet :
  editProjet(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Projet/Update', data, httpOptions);
    }
      // Edit Administrateur :
  sendRapport(data): Observable<any> {

    console.log('teeestt uplaod');

    return this.http.post<any>(environment.apiUrl + '/Projet/AttachRapport', data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  public upload(data) {

    console.log('teeestt uplaod');

    return this.http.post<any>(environment.apiUrl + '/Projet/AttachRapport', data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  rapportsByEtudiant(id) {

    return this.http.get(environment.apiUrl + '/Projet/getProjets/' + id);
  }

  public updateProjet(data) {

    console.log(data.get('titre'));

    console.log('teeestt updaaaate');

    return this.http.post<any>(environment.apiUrl + '/Projet/Update', data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }


    // get all projets :
    getProjetsByUser(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Projet/getProjets/' + id  , httpOptions);
    }

    // get all projets :
    getProjetsByUserByType(id,type): Observable<any> {
      return this.http.get(environment.apiUrl + '/Projet/getProjets/' + id+'/'+type  , httpOptions);
    }

    // get all projets :
    getProjetsByFormation(formation): Observable<any> {
      return this.http.get(environment.apiUrl + '/Projet/ListByFormation/' + formation  , httpOptions);
    }

    // get all projets :
    getProjetsByFormationAndType(formation, type): Observable<any> {
      return this.http.get(environment.apiUrl + '/Projet/Formation/' + formation + '/Type/' + type , httpOptions);
    }



  // get Projets By User
  downloadFile(id) {
    return this.http.get(environment.apiUrl + '/Projet/Download/' + id,
      { responseType: 'blob' ,
      headers: new HttpHeaders().append('content-type', 'application/json')});
    }


  // Delete cours :
  deleteProjet(id): Observable<any> {
    // console.log(id + " id of cours service to delete");
    return this.http.delete(environment.apiUrl + '/Projet/Delete/' + id);
  }
  editValidation(id): Observable<any> {
    console.log("into service");
    console.log(id);
    return this.http.post(environment.apiUrl + '/Projet/UpdateValidation/' + id, httpOptions);

    }
  notifRapport(id) {
    console.log('iddddddd rapport' + id);
    return this.http.get(environment.apiUrl + '/Projet/countRapport/' + id);
  }

    // get all projets :
    getFormationByProjet(projet_id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Projet/Formation/' + projet_id  , httpOptions);
    }
}
