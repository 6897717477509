import { Component, OnInit } from '@angular/core';
import { FormationService } from '../../services/formation.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Formation } from '../../models/formation.model';
import { ToastrService } from 'ngx-toastr';
import { ProfilService } from 'src/app/modules/profils/services/profil.service';
import { Niveau } from '../../models/niveau.model';
import { NiveauService } from '../../services/niveau.service';
import { EnseignantService } from 'src/app/modules/profils/services/enseignant.service';

@Component({
  selector: 'app-create-formation',
  templateUrl: './create-formation.component.html',
  styleUrls: ['./create-formation.component.scss']
})
export class CreateFormationComponent implements OnInit {

  formation: Formation;
  formationForm: FormGroup;
  error: string;
  submitted = false;
  success: string;
  cinExist:number;
  message: '';
  niveaux: Niveau;
  departement = 'departement';
  enseignants;
  niveau;
  etudiants;
  responsableFormation = 'responsableFormation';
  selectedParcours = 'selectedParcours';
  droitResponsableFormation = 'droitResponsableFormation';
  departementOk = false;

  constructor(
    private formationService: FormationService,
    private profilService:ProfilService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private niveauService: NiveauService,
    private enseignantService: EnseignantService,
    ) { }

  ngOnInit() {

    this.formationForm = this.formBuilder.group({
      code: [null, [Validators.required]],
      designation: [null, [Validators.required]],
      parcours: [null, [Validators.required]],
      responsableFormation: [null, Validators.required],
      droitResponsableFormation: [null, [Validators.required]],
    });
    this.enseignantService.getEnseignants().subscribe(

      res => this.enseignants = res,
      error => console.log(error),
    );
    
  }

  selectEnseignantByDepartement() {
    this.enseignantService.getEnseignants().subscribe(

      res => this.enseignants = res,
      error => console.log(error),
    );
};
  get f() { return this.formationForm.controls; }

  createFormation() {

    if ( document.activeElement.id === 'passeToNiv' || document.activeElement.id === 'finish' ) {

    this.submitted = true;


    if (this.formationForm.invalid  || this.formationForm.get('droitResponsableFormation').value === 'droitResponsableFormation' || this.formationForm.get('responsableFormation').value === 'responsableFormation' || this.formationForm.get('parcours').value === 'selectedParcours') {
          this.error = 'invalide Form  !';
          console.log(this.formationForm);
          return;
        } else {

          const formData = this.formationForm.value;

          this.success = null;
          this.error = null;
          this.formationService.createFormation(formData).subscribe(

            (data) => {
              this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>');
              if (document.activeElement.id === 'passeToNiv') {
                this.router.navigate(['/createNiveau', this.formationForm.get('code').value]);
              } else if (document.activeElement.id === 'finish') {
                this.router.navigate(['/gererFormation']);
              };
            },
            (error) => {
              console.log(error.message);
              this.showNotification('top', 'left', 'Code Formation <b>dèja existe</b> , choisit un nouveau code');
          },

          );



        }
        }


    }


    showNotification(from, align, message) {

      const color = Math.floor((Math.random() * 5) + 1);

      this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
        timeOut: 6000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-' + from + '-' +  align
         });
      }

      showNotificationSuccess(from, align) {

        const color = Math.floor((Math.random() * 5) + 1);

        this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span> Les données sont <b>bien Ajoutées</b>', '', {
          timeOut: 6000,
          closeButton: true,
          enableHtml: true,
          toastClass: 'alert alert-danger alert-with-icon',
          positionClass: 'toast-' + from + '-' +  align
           });
        }

}
