import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModuleService } from '../../services/module.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProfilService } from 'src/app/modules/profils/services/profil.service';
import { EnseignantService } from 'src/app/modules/profils/services/enseignant.service';

@Component({
  selector: 'app-create-module',
  templateUrl: './create-module.component.html',
  styleUrls: ['./create-module.component.scss']
})
export class CreateModuleComponent implements OnInit {

  idNiveau = this.route.snapshot.paramMap.get('id');
  moduleForm: FormGroup;
  success: string;
  error: string;
  submitted = false;
  message: '';
  cinExist: number;
  enseignants;
  departement = 'departement';
  semestre = 'semestre';
  idCoordinateur = 'idCoordinateur';

  constructor(
    private moduleService: ModuleService,
    private enseignantService: EnseignantService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private profilService: ProfilService,
    private toastr: ToastrService

  ) { }

  ngOnInit() {

    this.moduleForm = this.formBuilder.group({
      'code': [null, [Validators.required]],
      'designation': [null,[Validators.required]],
      'departement': [null, Validators.required],
      'semestre': [null, Validators.required],
      'idCoordinateur': [null, [Validators.required]],
      'niveau_id': [this.idNiveau, Validators.required],

    });

    //console.log(this.moduleForm.get('code').value);
  }

  get f() { return this.moduleForm.controls; }


  createModule() {

    if (document.activeElement.id === 'finish') {

      this.submitted = true;
      if (this.moduleForm.invalid || this.moduleForm.get('departement').value === 'departement' || this.moduleForm.get('idCoordinateur').value === 'idCoordinateur' || this.moduleForm.get('semestre').value === 'semestre') {
        this.error = 'invalide Form  !';
        console.log(this.moduleForm);
        return;
      } else {
        const formData = this.moduleForm.value;
        console.log(formData);
        this.moduleService.createModule(formData).subscribe(

              data => {

                  this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>.');
                  this.router.navigate(['/gestionModules', this.idNiveau]);

              },

              error => this.showNotification('top', 'left', 'Code Module <b>dèja existe</b> , choisit un nouveau code')
            );
      }


    }
  }


      selectEnseignant(enseignant) {
          this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(

            res => this.enseignants = res,
            error => console.log(error),
          );
    };


    showNotification(from, align, message) {

      const color = Math.floor((Math.random() * 5) + 1);

      this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
        timeOut: 6000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-' + from + '-' +  align
         });
      }


}
