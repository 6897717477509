import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { NotificationService } from 'src/app/services/notification.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  profil = localStorage.getItem('profil');
  idUser = localStorage.getItem('userable_id');
  id = localStorage.getItem('id');
  idNotifCours;
  idNotifDocuments;
  notifications;
  nbrNotifCours;
  messageNotifCours;
  messageNotifDocuments;
  messageNotifRessources;
  countNotifProjet;
  visits;
  chartReady = false;
  listNbreVisites;
  listDates; 
  DocNotifications;
  nbrNotifDocs;
  messageNotifDocs;
  type: ChartType = 'bar';

  circle: ChartType = 'pie';


  labels: Label[] = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

   datasets: ChartDataSets[];

  public options: ChartOptions;

  constructor(private dashboardService: DashboardService,
     private notificationService: NotificationService,
     private router: Router) {

    console.log('id user dash :::' + this.id);

    if(this.profil ==='Etudiant'){
      notificationService.getNotificationByUser(this.id).subscribe(
        res => {this.notifications = res;
                console.log('id user dash :::' + this.id);
                console.log(this.notifications);
                this.nbrNotifCours = this.notifications.nbrNotifCours;
                this.messageNotifCours = this.notifications.messageNotifCours;
                this.messageNotifDocuments = this.notifications.messageNotifDocuments;
                this.messageNotifRessources = this.notifications.messageNotifRessources;
                this.idNotifCours = this.notifications.id;
        },
        error => console.log(error)
      );

      notificationService.getDocumentsNotificationByUser(this.id).subscribe(
        res => {this.DocNotifications = res;
                console.log('id user dash :::' + this.id);
                console.log(this.DocNotifications);
                this.nbrNotifDocs = this.DocNotifications.nbrNotifDocuments;
                this.messageNotifDocs = this.DocNotifications.messageNotifDocuments;
                this.idNotifDocuments = this.DocNotifications.id;
        },
        error => console.log(error)
      );

    
    }

    console.log(this.nbrNotifDocs);
    console.log(this.messageNotifDocs);

    notificationService.getNbrVisitsByDate().subscribe(
      res => {this.visits = res;

              console.log(this.visits)
      },
      error => console.log(error)
    );


    notificationService.getSumVisitsByDate().subscribe(
      res => {this.listNbreVisites = res;

              this.datasets = [
          {
            label: '# of Application Visits',
           data: res,
          // data: this.,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }];


              this.options = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  this.chartReady = true;
              console.log(this.listNbreVisites)
      },
      error => console.log(error)
    );

    notificationService.getDates().subscribe(
      res => {this.listDates = res;

              console.log(this.listDates)
      },
      error => console.log(error)
    );

    

  }

  ngOnInit() {



  }

  InitialiserNotifCours(){

    this.notificationService.updateNotification(this.idNotifCours)

    .subscribe(
      (success) => {
        console.log(success);
      
        this.router.navigate(['/mesCours']);


      },
      (error) => {
        console.log(error);
  

      },
    );
      
  }

  InitialiserNotifDocuments(){

    this.notificationService.updateDocumentsNotification(this.idNotifDocuments).subscribe(
      (success) => {
        console.log(success);     
        this.router.navigate(['/listeDocuments']);
      },
      (error) => {
        console.log(error);
      },
    );
      
  }

  }


