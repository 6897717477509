import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursService } from 'src/app/modules/formation/services/cours.service';
import { DocumentService } from 'src/app/modules/formation/services/document.service';
import { Document } from 'src/app/modules/formation/models/document.model';
import saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { ElementService } from '../../services/element.service';

@Component({
  selector: 'app-mes-documents',
  templateUrl: './mes-documents.component.html',
  styleUrls: ['./mes-documents.component.scss']
})
export class MesDocumentsComponent implements OnInit {


  documents: Document;
   id = localStorage.getItem('userable_id');
   idUser = localStorage.getItem('id');
   cours;
   element = 'element';
   cour = 'cour';
   elements;



  constructor(

    private route: ActivatedRoute,
    private coursService: CoursService,
    private documentService: DocumentService,
    private toastr: ToastrService,
    private elementService: ElementService

  ) {

   }

  ngOnInit() {

    this.documentService.getAllDocumentsByEnseigant(this.id).subscribe(res => {
      console.log(res);
      this.documents = res;
    }, err => {
      console.log(err);
    });

    this.elementService.getElementsByEnseignant(this.id).subscribe(res => {
      console.log(res);
      this.elements = res;
    }, err => {
      console.log(err);
    });

    this.coursService.getMesCours(this.idUser).subscribe(res => {
      console.log(res);
      this.cours = res;
    }, err => {
      console.log(err);
    });
  }

  returnBlob(res : Blob){

    console.log('file downloaded');
    return new Blob([res], {type: ''});
  }

  searchByElement(element) {
    this.element = element;

    if (element == null || element === 'all' || element === 'element') {

      this.ngOnInit();

    } else {

        this.coursService.getMesCoursByElement(this.idUser, element).subscribe(
          res => this.cours = res,
          error => console.log(error),
        );

        this.documentService.getDocumentsByElement(element, this.idUser).subscribe(res => {
          this.documents = res;
        }, err => {
          console.log(err);
        });
      }
  }

  searchByCours(cour) {

    this.cour = cour;

    if (this.element === null || this.element === 'all' || this.element === 'element') {

        if (cour === null || cour === 'all' || cour === 'cour') {

          this.ngOnInit();

        } else {
          this.documentService.getDocumentsByCours(cour).subscribe(res => {
            this.documents = res;
          }, err => {
            console.log(err);
          });
          }
    } else {
      if (cour === null || cour === 'all' || cour === 'cour') {

        this.coursService.getMesCoursByElement(this.idUser, this.element).subscribe(
          res => this.cours = res,
          error => console.log(error),
        );

        this.documentService.getDocumentsByElement(this.element, this.idUser).subscribe(res => {
          this.documents = res;
        }, err => {
          console.log(err);
        });

    } else {

      this.documentService.getDocumentsByCours(cour).subscribe(res => {
        this.documents = res;
      }, err => {
        console.log(err);
      });

      }
    }
  }


 downloadFile(id, file_name) {
    this.coursService.downloadFile(id).subscribe(
      data => saveAs(data, file_name),
      error => console.error(error)
    );
}

////// delete document ::

archive(id) {

  /*
  this.documentService.deleteDocument(id).subscribe(
    error => console.error(error)
  );
  */

  if (confirm('Êtes-vous sûr de vouloir supprimer ce document?.')) {

    this.documentService.archiveDocument(id).subscribe(
      (data) => {
        this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
        // window.location.reload();
        this.ngOnInit();
      },
      (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

    );
  }

}

showNotification(from, align, message) {

  const color = Math.floor((Math.random() * 5) + 1);

  this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
    timeOut: 6000,
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-danger alert-with-icon',
    positionClass: 'toast-' + from + '-' +  align
     });
  }
}

