import { Component, OnInit } from '@angular/core';
import { ProfilService } from '../services/profil.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {

  id =localStorage.getItem('id');
  editPasswordForm: FormGroup;

  success: any;
  error: any;
  constructor(
    private profilService: ProfilService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit() {

    this.success = null;
    this.error = null;


    this.editPasswordForm = this.formBuilder.group({
      'id': [this.id],
      'currentPassword': [null, [Validators.required, Validators.minLength(8)]],
      'newPassword': [null, [Validators.required, Validators.minLength(8)]],
      'verifyNewPassword': [null, [Validators.required, Validators.minLength(8)]],
    });
}

    editPassword(){

      if (document.activeElement.id === 'finish') {

      const formData = this.editPasswordForm.value;
      this.profilService.editPassword(formData).subscribe(

          (success) => {
            this.showNotification('top', 'left', 'Votre Mot de Passe a été <b>bien Modifié</b>');
            this.success = 'Etudiant informations updated!';
            this.router.navigate(['/dashboard']);

          },
          (error) => {
            console.log(error);
            this.error = 'Error ! failed to update !';
            this.showNotification('top', 'left', 'Données érronées');

          },
        );
      }
    }
  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

}
