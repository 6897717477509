import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjetService } from 'src/app/modules/projet/services/projet.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from '../../formation/services/niveau.service';
import { Niveau } from '../../formation/models/niveau.model';
import { EnseignantService } from '../../profils/services/enseignant.service';
import { SoutenanceService } from '../services/soutenance.service';

@Component({
  selector: 'app-create-soutenance',
  templateUrl: './create-soutenance.component.html',
  styleUrls: ['./create-soutenance.component.scss']
})
export class CreateSoutenanceComponent implements OnInit {

  soutenanceForm: FormGroup;

  departement = 'departement';
  enseignants;
  error: string;
  idUser = localStorage.getItem('id');
  userable_id = localStorage.getItem('userable_id');
  Profil = localStorage.getItem('profil');
  success: string;
  submitted = false;
  message: '';
  enseignants2;
  departement2 = 'departement2';
  projets;
  projet_id = 'projet_id';
  president = 'president';
  allEnseignants=[];
  examinateur = 'examinateur';

  constructor(
    private projetService: ProjetService,
    private soutenanceService: SoutenanceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private niveauService: NiveauService,
    private enseignantService: EnseignantService,
  ) { }

  ngOnInit() {
    this.enseignantService.getEnseignants().subscribe((res:any)=>{
      this.enseignants=res;
      this.enseignants2=res;
      this.allEnseignants=res;
    })

      this.projetService.getProjetsByUser(this.idUser).subscribe(
        res => {
          this.projets = res;
          console.log('proets user : ' + res);
        },
        error => console.log(error),
      );


      this.success = null;
      this.error = null;
      this.soutenanceForm = this.formBuilder.group({
       projet_id: [null, [Validators.required]],
       date: [null, [Validators.required]],
       heureDebut: [null, Validators.required],
       heureFin: [null, Validators.required],
       salle : [null, Validators.required],
       departement: [null, [Validators.required]],
       departement2: [null],
       president: [null, Validators.required],
       examinateur: [null],


     });
  }

  get f() { return this.soutenanceForm.controls; }
  removeCurrentTeacherFromTeachersOption() {
    console.log(this.examinateur)
    this.enseignants= this.allEnseignants.filter(enseignant => enseignant.id != this.examinateur)
  }
  removeSecondTeacherFromTeachersOption(){
    this.enseignants2 = this.allEnseignants.filter(enseignant => enseignant.id != this.president)
  }
  selectEnseignantByDepartement(enseignant) {
    this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(

      res => this.enseignants = res,
      error => console.log(error),
    );
};


selectEnseignantByDepartement2(departement2) {
this.enseignantService.getEnseignantsByDepartement(departement2).subscribe(

  res => this.enseignants2 = res,
  error => console.log(error),
);
};



onSubmit() {

  if (document.activeElement.id === 'finish') {
    this.submitted = true;

    if (this.soutenanceForm.get('examinateur').value === 'examinateur' || this.soutenanceForm.get('president').value === 'president' || this.soutenanceForm.get('projet_id').value === 'projet_id') {
      this.error = 'invalide Form  !';
      console.log(this.soutenanceForm);
      return;
    } else {

        const formData = this.soutenanceForm.value;

        this.success = null;
        this.error = null;
        console.log(this.soutenanceForm);

        this.soutenanceService.createSoutenance(formData).subscribe(
          data => {
            this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>.');
            this.router.navigate(['gererSoutenances']);
          },
          error => console.log(error),
        );
    }

  }


 }
 showNotification(from, align, message) {

  const color = Math.floor((Math.random() * 5) + 1);

  this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
    timeOut: 6000,
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-danger alert-with-icon',
    positionClass: 'toast-' + from + '-' +  align
     });
  }

}
