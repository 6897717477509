import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursService } from '../../services/cours.service';
import { Cours } from '../../models/cours.model';
import { Document } from '../../models/document.model';
import { Ressource } from '../../models/ressource.model';
import { Location } from '@angular/common';
import saveAs from 'file-saver';
import { DocumentService } from '../../services/document.service';
import { RessourceService } from '../../services/ressource.service';

const MIME_TYPES = {
  pdf : 'application/pdf',
  xls : 'application/vnd.ms-excel',
}


@Component({
  selector: 'app-consultation-cours',
  templateUrl: './consultation-cours.component.html',
  styleUrls: ['./consultation-cours.component.scss'],
  providers: [Cours]
})
export class ConsultationCoursComponent implements OnInit {

  cours: Cours;
  documents: Document [] = [];
  ressources: Ressource [] = [];
  //public coursId;

  constructor(

      private route: ActivatedRoute,
      private coursService: CoursService,
      private documentService: DocumentService,
      private ressourceService: RessourceService,
      private location: Location
  ) {

        // tslint:disable-next-line: radix
        let id = this.route.snapshot.paramMap.get('id');
        console.log('iiiiiiidddd : ' + id);
        this.coursService.getCoursByIdGestionCours(id).subscribe(
          res => {this.cours = res,
          console.log( this.cours);}
          );

        console.log('tesssssssr :::::: ' + this.documents);

        let idUser = localStorage.getItem('id');
        documentService.getDocumentsByUser(id, idUser).subscribe(res => {
        console.log(res);
        this.documents = res;
    }, err => {
      console.log(err);
    });

      ressourceService.getRessourcesByUser(id, idUser).subscribe(res => {
      console.log(res);
      this.ressources = res;
    }, err => {
      console.log(err);
    });
  }

  ngOnInit() {

       // tslint:disable-next-line: radix
       let id = this.route.snapshot.paramMap.get('id');
       console.log('iiiiiiidddd : ' + id);
       this.coursService.getCoursByIdGestionCours(id).subscribe(
         res => {this.cours = res,
         console.log( this.cours);}
         );
  }

  getCoursById(coursId){



  };

  returnBlob(res : Blob){

    console.log('file downloaded');
    return new Blob([res], {type: ''});
  }


 downloadFile(id, file_name) {
    this.coursService.downloadFile(id).subscribe(
      data => saveAs(data, file_name),
      error => console.error(error)
    );
}


}
