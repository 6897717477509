import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from  'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
  })
};

@Injectable({
  providedIn: 'root'
})
export class SoutenanceService {

  constructor( private http: HttpClient, private router: Router ) { }


    // Create Soutenance :
    createSoutenance(data): Observable<any> {
      console.log(data.sujet);
      return this.http.post(environment.apiUrl + '/Soutenance/Save', data, httpOptions);
    }

     // Edit Soutenance :
     editSoutenance(data): Observable<any> {
      console.log(data.sujet);
      return this.http.put(environment.apiUrl + '/Soutenance/Update', data, httpOptions);
    }

    getSoutenanceById(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Soutenance/' + id, httpOptions);
      }


      getSoutenanceByUser(id): Observable<any> {
        return this.http.get(environment.apiUrl + '/Soutenance/ListByUser/' + id, httpOptions);
        }


    // get all Soutenances :
    getSoutenances(): Observable<any> {
      return this.http.get(environment.apiUrl + '/Soutenance/getSoutenances'   , httpOptions);
    }


    // get all soutenances By Type :    /getSoutenances/{id}/{typeProjet}
    getSoutenancesByType(id,type): Observable<any> {
      return this.http.get(environment.apiUrl + '/Soutenance/getSoutenances/'+id+'/'+type  , httpOptions);
    }

    // get all soutenances By Formation:
    getSoutenancesByFormation(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Soutenance/getSoutenancesByForamtion/' + id  , httpOptions);
    }

    // get all Soutenance :
    getSoutenancesByUser(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Soutenance/getSoutenances/'   , httpOptions);
    }


    getJSON(data): Observable<any> {
      return this.http.get(environment.apiUrl + '/Soutenance/getSoutenances', data);

    }

    // get all soutenances By Formation And Type:
    getSoutenancesByFormationAndType(id, type): Observable<any> {
      return this.http.get(environment.apiUrl + '/Soutenance/Formation/' + id + '/Type/' + type , httpOptions);
    }

    notifSoutenance(id) {
     
      return this.http.get(environment.apiUrl + '/SoutenanceNotification/' + id);
    }

  // Delete Soutenance :
  deleteSoutenance(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Soutenance/Delete/' + id);
  }

    //get encadrants
    getJuriesBySoutenance(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Soutenance/' + id+'/Juries', httpOptions);
    }

    editValidation(id): Observable<any> {
      return this.http.post(environment.apiUrl + '/Soutenance/UpdateValidation/' + id, httpOptions);
  
      }
}
