import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { GestionModulesService } from 'src/app/services/gestion-modules.service';
import { Menu } from 'src/app/models/menu.model';
import * as $ from 'jquery';
import { NotificationService } from 'src/app/services/notification.service';
import { ProjetService } from 'src/app/modules/projet/services/projet.service';
import { SoutenanceService } from 'src/app/modules/projet/services/soutenance.service';
import { NotificationProjetService } from 'src/app/modules/projet/services/notification-projet.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  menus: Menu [] = [];
  idUserable: string;
  email = localStorage.getItem('email');
  name = localStorage.getItem('name');
  profil = localStorage.getItem('profil');
  userable_id = localStorage.getItem('userable_id');
  idUser = localStorage.getItem('userable_id');
  id = localStorage.getItem('id');
  notifications;
  NotifRapportEtudiant;
  nbrNotifCours = 0;
  messageNotifCours;
  messageNotifDocuments;
  messageNotifRessources;
  idNotification;
  notifRapport ;
  notification;
  notificationProjetOk = false;

  constructor( private router: Router,
               private userService: UserService,
               private gestionModulesService: GestionModulesService,
               private notificationService: NotificationService,
               private projetService: ProjetService,
               private soutenaceService: SoutenanceService,
               private notifProjetService: NotificationProjetService) {

      this.idUser = localStorage.getItem('id');
      this.idUserable = localStorage.getItem('userable_id');

      console.log(this.userable_id);

      console.log('id user dash :::' + this.id);

      if(this.profil === 'Etudiant' || this.profil === 'Administrateur'){

        notificationService.getNotificationByUser(this.id).subscribe(
          res => {this.notifications = res;
                  console.log('id user dash :::' + this.id);
                  console.log(this.notifications);
                  this.nbrNotifCours = this.notifications.nbrNotifCours;
                  this.messageNotifCours = this.notifications.messageNotifCours;
                  this.messageNotifDocuments = this.notifications.messageNotifDocuments;
                  this.messageNotifRessources = this.notifications.messageNotifRessources;
                  this.idNotification = this.notifications.id;
          },
          error => console.log(error)
        );

      }




      if (this.profil === 'Etudiant') {
        notificationService.getRapportNotification(this.userable_id).subscribe(
          res => this.notifRapport = res,
          error => console.log(error)
        );
      }

      if (this.profil === 'Etudiant' || this.profil === 'Enseignant' || this.profil === 'ResponsableFormation') {
        notifProjetService.getNotification(this.id).subscribe(
          res => {
            this.notificationProjetOk = true;
            this.notification = res;
            console.log(this.notification);
          },
          error => console.log(error)
        );
      }
     }


  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();


  ngOnInit() {
    console.log('name header' + this.name);



  }


  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

  logout() {
    this.userService.logoutServer()
          .subscribe(
              (user) => {
                  if (user != null) {
                      this.userService.logoutClient();
                  }

              },
              (error) => {
        console.error(error);
        this.userService.logoutClient();
              }
          );

  }

  updatenbrNotification() {
    console.log('inside updayr notif ' + this.idNotification);
    this.notificationService.updateNotification(this.idNotification).subscribe(
      res => { console.log('inside res notif ' + this.idNotification);
      },
      error => console.log(error)
    );
  }


}
