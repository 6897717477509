import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
	})
};

@Injectable({
  providedIn: 'root'
})
export class FormationService {

  constructor(private http: HttpClient, private router: Router) {}


  // get all formations :
  getFormations(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Formation/List', httpOptions);
  }
  getMaFormation(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Formation/Responsable/'+id, httpOptions);
  }

  // get nb Niveaux d'une Formation By Code :
   getNbrByCode(code): Observable<any> {
    return this.http.get(environment.apiUrl + '/Formation/'+code+'/niveaux', httpOptions);
  }

   // get FormationById :
   getFormationById(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Formation/'+id, httpOptions);
  }

  // Create Formation :
  createFormation(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/Formation/Save', data, httpOptions);
  }

  // Delete Formation :
  deleteFormation(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Formation/Delete/'+ id);
  }

  // Edit Formation :
  editFormation(data): Observable<any> {
    console.log("into service");
    console.log(data);
    return this.http.post(environment.apiUrl + '/Formation/Update', data, httpOptions);

    }

  // Edit Formation :
  editRoleResponsableFormation(id): Observable<any> {
    console.log("into service");
    console.log(id);
    return this.http.put(environment.apiUrl + '/Formation/UpdateRoleFormation/' + id, httpOptions);

    }

  // List Niveaux By Formation
  getListNiveaux(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Formation/ListNiveaux', httpOptions);
  }
}
