import { Component, OnInit } from '@angular/core';
import { NiveauService } from '../../services/niveau.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormationService } from '../../services/formation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-niveau',
  templateUrl: './create-niveau.component.html',
  styleUrls: ['./create-niveau.component.scss']
})
export class CreateNiveauComponent implements OnInit {

  code = this.route.snapshot.paramMap.get('code');
  idFormation = this.route.snapshot.paramMap.get('id');

  niveauForm: FormGroup;
  success: string;
  error: string;
  nbrNiveaux;
  cmpt:number;
  uploadResponse = { status: '', message: '', filePath: '' };
  submitted = false;
  cinExist:number;
  message: '';


  constructor(
    private niveauService: NiveauService,
    private formationService: FormationService,
    private router : Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService

  ) {

    this.cmpt=0;

  }

  ngOnInit() {
   this.formationService.getNbrByCode(this.code).subscribe(
      res => {
            //console.log(res);
            this.nbrNiveaux = res;

            //console.log(this.nbrNiveaux + "Nombre Niveaux");
      },

      err => {
            console.log(err);
      }
    );
   let  code = this.route.snapshot.paramMap.get('code');


   this.niveauForm = this.formBuilder.group({
      'code': [null, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'designation': [null, [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
      'formation_code': [code, Validators.required],
    }

    );



    // console.log(this.niveauForm.get('code').value);
  }

  get f() { return this.niveauForm.controls; }


  createNiveau() {

    if ( document.activeElement.id === 'passeToNiv' || document.activeElement.id === 'finish' ) {

        this.submitted = true;
        if (this.niveauForm.invalid) {
          this.error = 'invalide Form  !';
          console.log(this.niveauForm);
          return;
        }
        const formData = this.niveauForm.value;
        console.log(formData);
        this.niveauService.createNiveau(formData).subscribe(
          (res) => {
            console.log(res);
            if (document.activeElement.id === 'passeToNiv') {
              this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b> Vous pouvez ajouter un autre niveau.');

              // this.router.navigate(['createNiveau', this.code]);
              window.location.reload();
              // this.ngOnInit();
            } else if (document.activeElement.id === 'finish') {
              this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>');
              // this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>'),
              console.log(res.formation_id);
              this.router.navigate(['/gererNiveau', res.formation_id]);
            }

          },
          (err) => this.showNotification('top', 'left', 'Code Niveau <b>dèja existe</b> , choisit un nouveau code'),

        );

        this.error = null;
        this.cmpt ++;

      // this.router.navigate(['/createNiveau', this.code]);

    }
  }

    showNotification(from, align, message) {

      const color = Math.floor((Math.random() * 5) + 1);

      this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
        timeOut: 6000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-' + from + '-' +  align
         });
      }

}
