import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
	})
};

@Injectable({
  providedIn: 'root'
})
export class OffresService {

  constructor(private http: HttpClient, private router: Router) {}


  // get all Offress :
  getOffres(): Observable<any> {
    return this.http.get(environment.apiUrl + '/offres', httpOptions);
  }
   // get OffresById :
   getOffresById(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/offres/'+id, httpOptions);
  }

  // Create Offres :
  createOffres(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/offres', data, httpOptions);
  }

  // Delete Offres :
  deleteOffres(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/offres/'+ id);
  }

  // Edit Offres :
  editOffres(id,data): Observable<any> {
    console.log("into service");
    console.log(data);
    return this.http.put(environment.apiUrl + '/offres/'+ id, data, httpOptions);

    }
}
