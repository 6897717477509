import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModuleService } from '../../services/module.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Enseignant } from 'src/app/modules/profils/models/enseignant.model';
import { ProfilService } from 'src/app/modules/profils/services/profil.service';
import { ToastrService } from 'ngx-toastr';
import { EnseignantService } from 'src/app/modules/profils/services/enseignant.service';

@Component({
  selector: 'app-edit-module',
  templateUrl: './edit-module.component.html',
  styleUrls: ['./edit-module.component.scss']
})
export class EditModuleComponent implements OnInit {
  editModuleForm: FormGroup;
  error: string;
  success: string;
  module;
  idModule = this.route.snapshot.paramMap.get('id');
    id: number
    code: string
    designation: string
    departement: string
    semestre: string
    idCoordinateur: string
    enseignant:Enseignant;

    cinExist:number;
    message: '';
    submitted = false;
    enseignants;


  constructor(
    private moduleService: ModuleService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private profilService: ProfilService,
    private toastr: ToastrService,
    private enseignantService: EnseignantService

  ) {

   }

  ngOnInit() {
    this.success = null;
    this.error = null;
    this.editModuleForm = this.formBuilder.group({
      code: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      designation: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
      semestre: [null, Validators.required],
      departement: [null, Validators.required],
      idCoordinateur: [null, [Validators.required]],
      id: [this.idModule, Validators.required],
    });

    this.moduleService.getModuleById(this.idModule).subscribe(res => {

      this.module= res;
      console.log(this.module);

      this.profilService.getEnseignantById(this.module.idCoordinateur).subscribe(res => {

        this.enseignant= res;
        console.log(this.enseignant);


        this.enseignantService.getEnseignantsByDepartement(this.enseignant.departement).subscribe(

          res => this.enseignants = res,
          error => console.log(error),
        );

        this.editModuleForm.patchValue({
        code: this.module.code,
        departement: this.module.departement,
        designation: this.module.designation,
        semestre: this.module.semestre,
        idCoordinateur: this.enseignant.id,
        id: this.idModule,

      });

      });

    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations du departement !';
    });
  }

  selectEnseignantByDepartement(departement) {
    this.enseignantService.getEnseignantsByDepartement(departement).subscribe(

      res => this.enseignants = res,
      error => console.log(error),
    );
};
  get f() { return this.editModuleForm.controls; }


  editModule() {

    if (document.activeElement.id === 'submit') {
        this.submitted = true;

        if (this.editModuleForm.invalid) {
          this.error = 'invalide Form  !';
          console.log(this.editModuleForm);
          return;
        }

        const formData = this.editModuleForm.value;

        this.success = null;
        this.error = null;

        this.moduleService.editModule(formData).subscribe(

              (data) => {

                  this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');

                  this.router.navigate(['/gestionModules', this.module.niveau_id]);

              },

              (error) => {
                console.log(error.message);
                this.showNotification('top', 'left', 'Code Module <b>dèja existe</b> , choisit un nouveau code');
              },
            );
      }
  }


  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}

