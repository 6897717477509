import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LauriatService } from '../../services/lauriat.service';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from 'src/app/modules/formation/services/niveau.service';
import { Lauriat } from '../../models/lauriat.model';
import { Niveau } from 'src/app/modules/formation/models/niveau.model';

@Component({
  selector: 'app-edit-lauriat',
  templateUrl: './edit-lauriat.component.html',
  styleUrls: ['./edit-lauriat.component.scss']
})
export class EditLauriatComponent implements OnInit {

  public lauriatForm: FormGroup;
  error: string;
  success: string;
  isButton: boolean = true;
  niveaux;
  lauriat:
    {

    };
  profil = localStorage.getItem('profil');
  userable_id = localStorage.getItem('userable_id');

  submitted = false;
  cinExist: number;
  message: '';
  etudiantInfo:any = {};

  idUser = localStorage.getItem('id');
  idEnseignant = localStorage.getItem('userable_id');
  niveau_id = 'niveau_id';

  constructor(
    private lauriatService: LauriatService,
    private niveauxService: NiveauService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
   this.initForm();
    if (this.profil === 'Administrateur') {

      niveauxService.getNiveaux().subscribe(
        res => {
          console.log(res);
          this.niveaux = res;
        },

        err => {
          console.log(err);
        }
      );
    } else if (this.profil === 'ResponsableFormation') {

      niveauxService.NiveauxByEnseignant(this.userable_id).subscribe(
        res => {
          console.log(res);
          this.niveaux = res;
        },

        err => {
          console.log(err);
        }
      );
    }


  }

  initForm(){
    this.lauriatForm = this.formBuilder.group({
      'date_debut': [this.etudiantInfo.date_debut, [Validators.required]],
      'date_fin': [this.etudiantInfo.date_fin, [Validators.required]],
      'etudiant_id': [this.etudiantInfo.etudiant_id, Validators.required],
      'description': [this.etudiantInfo.description, [Validators.required]],
      'entreprise': [this.etudiantInfo.entreprise, [Validators.required]],
      'outils': [this.etudiantInfo.outils, [Validators.required]],
      "experience_id":[this.etudiantInfo.experience_id, [Validators.required]]
    });
  }

  ngOnInit() {
       
        this.lauriatService.getLauriatById(this.route.snapshot.params.id).subscribe((res:any)=>{
          this.etudiantInfo=res;
          this.initForm();
        })
  

       
  
     
    // let email = localStorage.getItem('email');
    // console.log(email);



    this.success = null;
    this.error = null;
 
  }



  get f() { return this.lauriatForm.controls; }
  
  test(){alert("hello")}
  editLauriat() {
    console.log("clicked")
    this.submitted = true;



    const formData = this.lauriatForm.value;

    this.success = null;
    this.error = null;
    console.log("hello")
    console.log(formData)
   this.lauriatService.editLauriat(formData).subscribe(

      (data) => {
        this.showNotification('top', 'left', 'L\'experience a était sauvugardé</b>.')
        this.router.navigate(["/lauriat"])

      },

      (error) => error
    );





  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' + align
    });
  }
}

