import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NiveauService } from '../../services/niveau.service';
import { Niveau } from '../../models/niveau.model';
import { ToastrService } from 'ngx-toastr';
import { FormationService } from '../../services/formation.service';

@Component({
  selector: 'app-gerer-niveau',
  templateUrl: './gerer-niveau.component.html',
  styleUrls: ['./gerer-niveau.component.scss']
})
export class GererNiveauComponent implements OnInit {

  niveaux: Niveau;
  formation;
  profil = localStorage.getItem('profil');
  var1 = false;
  var2 ;
  var3= false;
  Niveau;
  constructor(
    private route: ActivatedRoute,
    private niveauService: NiveauService,
    private formationService: FormationService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {

    let idFormation = this.route.snapshot.paramMap.get('idFormation');

    this.niveauService.NiveauxByFormation(idFormation).subscribe(res => {
      console.log(res);
      this.niveaux = res;
    }, err => {
      console.log(err);
    });
    this.formationService.getFormationById(idFormation).subscribe(res => {
      console.log(res);
      this.formation= res;
    }, err => {
      console.log(err);
    });
  }

  delete(id) {
    // this.niveauService.deleteNiveau(id).subscribe();
    if (confirm('Êtes-vous sûr de vouloir supprimer ce niveau? \nLes documents associés seront également supprimés.')) {

      this.niveauService.deleteNiveau(id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          // window.location.reload();
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

      );
    }
  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

    toggleDetails(Niveau) {
      this.var3= false;
      console.log(this.var2 + " "+this.var1)
      this.var2 = Niveau.id;
      if(Niveau.id!= this.var2){
        this.var1 = true;
        this.var3= true;
      }else{
        this.var1 =! this.var1;
        this.var3= true;
      }
  
      }
}
