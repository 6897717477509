// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // apiUrl: 'http://localhost:8000/api'
    apiUrl:"https://ensi.ftlv.ma/backend/public/api"
};

export const documentsUrl="http://localhost:8000/storage/documents/";

