import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursService } from 'src/app/modules/formation/services/cours.service';
import { DocumentService } from 'src/app/modules/formation/services/document.service';
import { Document } from 'src/app/modules/formation/models/document.model';
import  saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from '../../services/niveau.service';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'app-list-documents',
  templateUrl: './list-documents.component.html',
  styleUrls: ['./list-documents.component.scss']
})
export class ListDocumentsComponent implements OnInit {

  documents: Document;
  designation
  niveau_designation;
  module_designation;
  niveaux;
  niveau;
  modules;
  module;
  designation_module = 'designation_module';
  Niveau = 'Niveau';
  id = localStorage.getItem('id');
  profil = localStorage.getItem('profil');


  constructor(

    private route: ActivatedRoute,
    private coursService: CoursService,
    private niveauService: NiveauService,
    private moduleService: ModuleService,
    private documentService: DocumentService,
    private toastr: ToastrService,

  ) {

    this.niveau = 'all';
    this.module = 'all';

    documentService.getAllDocumentsByUser(this.id).subscribe(res => {
      console.log(res);
      this.documents = res;
    }, err => {
      console.log(err);
    });

    niveauService.getNiveaux().subscribe(
      res => {
            this.niveaux = res;
      },

      err => {
            console.log(err);
      }
    );

    this.moduleService.getModules().subscribe(
      res => this.modules = res,
      error => console.log(error),
    );
   }

  ngOnInit() {
  }

  returnBlob(res : Blob){

    console.log('file downloaded');
    return new Blob([res], {type: ''});
  }


 downloadFile(id, file_name) {
    this.coursService.downloadFile(id).subscribe(
      data => saveAs(data, file_name),
      error => console.error(error)
    );
}

showNotification(from, align, message) {

  const color = Math.floor((Math.random() * 5) + 1);

  this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
    timeOut: 6000,
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-danger alert-with-icon',
    positionClass: 'toast-' + from + '-' +  align
     });
  }

  searchByNiveau(niveau){


    this.niveau= niveau;
    this.module = 'all';
    //get all modules

    if (niveau == null || niveau === 'all' || niveau === 'niveau') {
        this.documentService.getAllDocumentsByUser(this.id).subscribe(res => {
        this.documents = res;
      }, err => {
        console.log(err);
     });

     this.moduleService.getModules().subscribe(
      res => this.modules = res,
      error => console.log(error),
    );

    } else {
        //get Modules By Niveau
        this.niveauService.getModulesByNiveau(niveau).subscribe(

          res => this.modules = res,
          error => console.log(error),
        );

        this.documentService.getDocumentsByNiveau(this.id,niveau).subscribe(res => {
          this.documents = res;
          console.log(this.id);
        }, err => {
          console.log(this.id);
          console.log(err);
        });
      }
  }

  searchByModule(module){

    let id = this.route.snapshot.paramMap.get('id');
    this.module= module;

    if(this.niveau ==='all'){
    if (module == null || module === 'all') {
        this.documentService.getAllDocumentsByUser(id).subscribe(res => {
        this.documents = res;
      }, err => {
        console.log(err);
     });

    } else {
      this.documentService.getDocumentsByModule(this.id,module).subscribe(res => {
        this.documents = res;
      }, err => {
        console.log(err);
      });
      }
    }else{
      if (module == null || module === 'all') {
        this.documentService.getDocumentsByNiveau(this.id,this.niveau).subscribe(res => {
        this.documents = res;
      }, err => {
        console.log(err);
     });

    } else {

      this.documentService.getDocumentsByModule(this.id,module).subscribe(res => {
        this.documents = res;
      }, err => {
        console.log(err);
      });

      }
    }
  }
}

