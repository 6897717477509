import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DocumentService } from '../../services/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss']
})
export class EditDocumentComponent implements OnInit {

  editDocumentForm: FormGroup;
  error: string;
  success: string;
  uploadResponse = { status: '', message: '', filePath: '' };
  document;
  idDocument = this.route.snapshot.paramMap.get('id');
  test;
    id: number;
    titre: string;
    description: string;
    visibility: string;
    element_id;
    type;
    i = 0;

  element;
  elements;

  message: '';
  submitted = false;
  idCours;

  // tslint:disable-next-line: max-line-length
  constructor(

        private documentService: DocumentService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router : Router,
        // private elementsService: ElementService
    ) {

 }

  ngOnInit() {
    this.success = null;
    this.error = null;
    this.editDocumentForm = this.formBuilder.group({
      titre: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
      description: ['', [Validators.maxLength(500)]],
      visibility: [null, Validators.required],
      type: [null, Validators.required],
      file: [null, Validators.required],
      id: [this.idDocument, Validators.required],
    });

    console.log('tesssst ngoniit douu5liii ');

    this.documentService.getDocumentById(this.idDocument).subscribe(res => {
      // console.log(res);
      this.document = res;
      this.idCours = res.cour_id;

      // tslint:disable-next-line: radix
      this.editDocumentForm.patchValue({
        titre: this.document.titre,
        type: this.document.type,
        description: this.document.description,
        visibility: this.document.visibility,
        id: this.idDocument,

      });
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations de l\'utilisateur !';
    });
  }


  onFileChange(event) {
    if (event.target.files.length > 0) {
      console.log(event);
      const file = event.target.files[0];
      let testFile = this.editDocumentForm.get('file').setValue(file);
      console.log(file);
      console.log(testFile);
    }
  }

  get f() { return this.editDocumentForm.controls; }

  editDocument() {

    if (document.activeElement.id === 'submit') {

        this.submitted = true;
        if (this.editDocumentForm.invalid) {
          this.error = 'invalide Form  !';
          console.log(this.editDocumentForm);
          return;
        }

        const formData = new FormData();
        const filetest = formData.append('file', this.editDocumentForm.get('file').value);
        formData.append('titre', this.editDocumentForm.get('titre').value);
        // formData.append('format', this.editDocumentForm.get('format').value);
        // console.log(formData.get("format"));
        formData.append('type', this.editDocumentForm.get('type').value);
        formData.append('id', this.idDocument);
        formData.append('visibility', this.editDocumentForm.get('visibility').value);
        formData.append('description', this.editDocumentForm.get('description').value);


        this.documentService.updateDoc(formData).subscribe(
          (res) => {
              this.i = this.i + 1;
              console.log(res);
              console.log(this.i);
              if (this.i === 5) {

                  this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
                  this.router.navigate(['/mesDocuments']);

              }

          },
          (err) => {
            this.showNotification('top', 'left', 'Code Formation <b>dèja existe</b> , choisit un nouveau code');

          }
        );
    }

  }




  onFileSelected(event) {

    const file = event.target.files[0];

    this.editDocumentForm.get('file').setValue(file);

    console.log(file);
    console.log(file);

  }
  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

}
