import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Niveau } from '../../models/niveau.model';
import { CoursService } from '../../services/cours.service';
import { ElementService } from '../../services/element.service';

@Component({
  selector: 'app-gerer-mes-cours',
  templateUrl: './gerer-mes-cours.component.html',
  styleUrls: ['./gerer-mes-cours.component.scss']
})
export class GererMesCoursComponent implements OnInit {

  profil = localStorage.getItem('profil');
  email: String = localStorage.getItem('email');
  name: String = localStorage.getItem('name');
  id = localStorage.getItem('id');

  test;
  cours;
  var1 = false;
  var2 ;
  var3= false;
  elements: Niveau [] = [];

  element;
  userable_id = localStorage.getItem('userable_id');


  constructor(
    private coursService: CoursService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private elementService: ElementService
    ) {

     }

  ngOnInit() {

    this.coursService.getMesCours(this.id).subscribe(res => {

      console.log(res);

      this.cours = res;

      this.test = localStorage.getItem('name');
      console.log('tessssst ::::: ' + this.test);

    }, err => {
      console.log(err);


    });


    this.elementService.getElementsByEnseignant(this.userable_id).subscribe(

      res => this.elements = res,
      error => console.log(error),
    );
  }

  searchByElement(element) {

    if (element == null || element === 'all' || element === 'element') {

        this.ngOnInit();
 } else {

  this.coursService.getMesCoursByElement(this.id, element).subscribe(

    res => this.cours = res,
    error => console.log(error),
  );
      }

  }
  delete(cour_id) {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette cours? \nLes documents associés seront également supprimés.')) {
      this.coursService.deleteCours(cour_id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          window.location.reload();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

      );
    }
  }


  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

    toggleDetails(cour) {
      this.var3= false;
      console.log(this.var2 + " "+this.var1)
      this.var2 = cour.cours_id;
      if(cour.cours_id!= this.var2){
        this.var1 = true;
        this.var3= true;
      }else{
        this.var1 =! this.var1;
        this.var3= true;
      }
      }

}
