import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ElementService } from '../../services/element.service';
import { DocumentService } from 'src/app/modules/formation/services/document.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-document',
  templateUrl: './create-document.component.html',
  styleUrls: ['./create-document.component.scss']
})
export class CreateDocumentComponent implements OnInit {

 titreDoc = this.route.snapshot.paramMap.get('titre');
 documentForm: FormGroup;
 error: string;
 userId: number = 1;
 uploadResponse = { status: '', message: '', filePath: '' };
 idUser = localStorage.getItem('id');
 email = localStorage.getItem('email');
 idCours = this.route.snapshot.paramMap.get('id');
 submitted = false;
 success: string;
 message: '';
 i = 0;
 selectedRestriction = 'selectedRestriction';




  constructor(
      private documentService: DocumentService,
      private elementsService: ElementService,
      private toastr: ToastrService,
      private router : Router,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
    ) { }



  ngOnInit() {
    this.documentForm = this.formBuilder.group({
      file: ['', Validators.required],
      type: ['', Validators.required],
      titre: ['',[Validators.required]],
      visibility: [null, Validators.required],
      cour_id: [this.idCours, Validators.required],
      description: [null, [Validators.maxLength(500)]],
      created_by: [this.email]
    });

  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      console.log(event);
      const file = event.target.files[0];
      let testFile = this.documentForm.get('file').setValue(file);
      console.log(file);
      console.log(testFile);
    }
  }

  get f() { return this.documentForm.controls; }


  onSubmit() {

    if ( document.activeElement.id === 'passeToNextDoc' || document.activeElement.id === 'finish' ) {

      this.submitted = true;

      if (this.documentForm.invalid || this.documentForm.get('visibility').value === 'selectedRestriction') {
        this.error = 'invalide Form  !';
        console.log(this.documentForm);
        return;
      } else {

          const formData = new FormData();
          const filetest = formData.append('file', this.documentForm.get('file').value);
          console.log(formData.get('file'));
          formData.append('titre', this.documentForm.get('titre').value);
          console.log(formData.get('titre'));
          formData.append('type', this.documentForm.get('type').value);
          console.log(formData.get('type'));
          formData.append('visibility', this.documentForm.get('visibility').value);
          formData.append('description', this.documentForm.get('description').value);
          formData.append('cour_id', this.idCours);
          formData.append('created_by', this.email);

          this.documentService.upload(formData).subscribe(
            (res) => {
              this.uploadResponse = res;
              this.i = this.i + 1;
              if (this.i === 4) {
                ////////// to chose the button :
                if (document.activeElement.id == 'passeToNextDoc'){
                  console.log('test dooooooooc');
                  this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>. Vous pouvez ajouter autre document.');
                  window.location.reload();
                  // this.router.navigate(['/createDocument', this.titreDoc, this.idCours]);
                } else if(document.activeElement.id == 'finish'){
                  console.log('test cououuuurs');
                  this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>'),

                  this.router.navigate(['/consulterCours', this.idCours]);
                }
              }

            },
            (err) => this.error = err
          );
      }


    }
  }
  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

}
