import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultModule } from './layouts/default/default.module';
import { LoginComponent } from './login/login.component';

//import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';

import { MatIconModule } from "@angular/material/icon"; // <----- Here
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormationModule } from './modules/formation/formation.module';
import { ProfilsModule } from './modules/profils/profils.module';
import { RequestPasswordRessetComponent } from './login/resetPassword/request-password-resset/request-password-resset.component';
import { ResponsePasswordResetComponent } from './login/responsePasswordReset/response-password-reset/response-password-reset.component';

import {ToastrModule, ToastrService} from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { EditProfilComponent } from './modules/profils/edit-profil/edit-profil.component';

import { ChartsModule } from 'ng2-charts';
import { CreateProjetComponent } from './modules/projet/create-projet/create-projet.component';
import { EditProjetComponent } from './modules/projet/edit-projet/edit-projet.component';
import { EditSoutenanceComponent } from './modules/projet/edit-soutenance/edit-soutenance.component';
import { CreateSoutenanceComponent } from './modules/projet/create-soutenance/create-soutenance.component';
import { GererProjetsComponent } from './modules/projet/gerer-projets/gerer-projets.component';
import { GererSoutenancesComponent } from './modules/projet/gerer-soutenances/gerer-soutenances.component';
import { AjouRapportComponent } from './modules/projet/ajou-rapport/ajou-rapport.component';
import { ImportRapportComponent } from './modules/projet/import-rapport/import-rapport.component';
import { ConsultationSoutenancesComponent } from './modules/projet/consultation-soutenances/consultation-soutenances.component';
import { ContactUsComponent } from './modules/contact-us/contact-us.component';
import { SavoirPlusComponent } from './modules/savoir-plus/savoir-plus.component'
import { CommonModule } from '@angular/common';
import { ConsultationProjetsComponent } from './modules/projet/consultation-projets/consultation-projets.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { LandingComponent } from './shared/components/landing/landing.component';

//import {BaseChartDirective} from 'ng2-charts/ng2-charts';

//import { ComponentsModule } from './components/components.module';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RequestPasswordRessetComponent,
    ResponsePasswordResetComponent,
    EditProfilComponent,
    CreateProjetComponent,
    EditProjetComponent,
    EditSoutenanceComponent,
    CreateSoutenanceComponent,
    GererProjetsComponent,
    GererSoutenancesComponent,
    AjouRapportComponent,
    ImportRapportComponent,
    ConsultationSoutenancesComponent,
    ContactUsComponent,
    SavoirPlusComponent,
    ConsultationProjetsComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    //ComponentsModule,
    RouterModule,
    AppRoutingModule,
    FormationModule,
    ProfilsModule,
    SharedModule,
    MatIconModule,
    //SnotifyModule,
    ToastrModule.forRoot(),
    ChartsModule,
    //MaterialModule.forRoot(),
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule
  ],
  providers: [

    //{ provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    //SnotifyService
    ToastrModule,
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
