import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursService } from '../../../services/cours.service';
import { DocumentService } from '../../../services/document.service';
import { Document } from '../../../models/document.model';
import  saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-gerer-documents',
  templateUrl: './gerer-documents.component.html',
  styleUrls: ['./gerer-documents.component.scss']
})
export class GererDocumentsComponent implements OnInit {

  documents: Document;
  idCours = this.route.snapshot.paramMap.get('id');
  id = localStorage.getItem('id');


  constructor(

    private route: ActivatedRoute,
    private coursService: CoursService,
    private documentService: DocumentService,
    private toastr: ToastrService,

  ) {
   }

  ngOnInit() {


    this.documentService.getDocumentsByCours(this.idCours).subscribe(res => {
      console.log(res);
      console.log(res);
      this.documents = res;
    }, err => {
      console.log(err);
    });

  }

  returnBlob(res : Blob){

    console.log('file downloaded');
    return new Blob([res], {type: ''});
  }


 downloadFile(id, file_name) {
    this.coursService.downloadFile(id).subscribe(
      data => saveAs(data, file_name),
      error => console.error(error)
    );
}

////// delete document ::

archive(id) {

  if (confirm('Êtes-vous sûr de vouloir supprimer ce document?.')) {

    this.documentService.archiveDocument(id).subscribe(
      (data) => {
        this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
        // window.location.reload();
        this.ngOnInit();
      },
      (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

    );
  }

}

showNotification(from, align, message) {

  const color = Math.floor((Math.random() * 5) + 1);

  this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
    timeOut: 6000,
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-danger alert-with-icon',
    positionClass: 'toast-' + from + '-' +  align
     });
  }
}
