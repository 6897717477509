import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Module } from '../../models/module.model';
import { ModuleService } from '../../services/module.service';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from '../../services/niveau.service';

@Component({
  selector: 'app-gerer-module',
  templateUrl: './gerer-module.component.html',
  styleUrls: ['./gerer-module.component.scss']
})
export class GererModuleComponent implements OnInit {

  modules: Module;
  Niveau;
  var1 = false;
  var2 ;
  var3= false;
 
  constructor(

    private route: ActivatedRoute,
    private moduleService: ModuleService,
    private niveauService: NiveauService,
    private toastr: ToastrService,

  ) {

  }

  ngOnInit() {


    let idNiveau = this.route.snapshot.paramMap.get('idNiveau');

    this.moduleService.ModulesByNiveau(idNiveau).subscribe(res => {
      console.log(idNiveau);
      console.log(res);
      this.modules = res;

    }, err => {
      console.log(err);
    });

    this.niveauService.getNiveauFormation(idNiveau).subscribe(res => {
      console.log(idNiveau);
      console.log(res);
      this.Niveau = res;

    }, err => {
      console.log(err);
    });
  }

  delete(id) {
   // this.moduleService.deleteModule(id).subscribe();

    if (confirm('Êtes-vous sûr de vouloir supprimer ce module? \nLes documents associés seront également supprimés.')) {

      this.moduleService.deleteModule(id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          // window.location.reload();
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

      );
    }
  }
  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

    toggleDetails(Module) {
      this.var3= false;
      console.log(this.var2 + " "+this.var1)
      this.var2 = Module.id;
      if(Module.id!= this.var2){
        this.var1 = true;
        this.var3= true;
      }else{
        this.var1 =! this.var1;
        this.var3= true;
      }
  
      }
}
