import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NiveauService } from '../../services/niveau.service';
import { Niveau } from '../../models/niveau.model';
import { Module } from '../../models/module.model';

@Component({
  selector: 'app-consultation-niveau',
  templateUrl: './consultation-niveau.component.html',
  styleUrls: ['./consultation-niveau.component.scss']
})
export class ConsultationNiveauComponent implements OnInit {
  niveaux: Niveau;
  modules: Module;


  constructor(

    private route: ActivatedRoute,
    private niveauService: NiveauService,

  ) {

    let id = this.route.snapshot.paramMap.get('id');

    niveauService.getNiveauById(id).subscribe(res => {
      console.log(res);
      this.niveaux = res;
    }, err => {
      console.log(err);
    });


    niveauService.getModulesByNiveau(id).subscribe(res => {
      console.log(res);
      this.modules = res;
    }, err => {
      console.log(err);
    });
  }

  ngOnInit() {
  }

}

