export class Cours {
  id : number;
  titre: string;
  cour_id: number;
  element_id: number;
 module_designation;
  visibility: number;
  niveau_designation;
  created_by;
  description;
  cours_id;
  created_at: Date;
  updated_at: Date;
}
