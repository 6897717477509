import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
// import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-response-password-reset',
  templateUrl: './response-password-reset.component.html',
  styleUrls: ['./response-password-reset.component.scss']
})
export class ResponsePasswordResetComponent implements OnInit {

  submitted = false;
  error = '';
  resetPasswordForm: FormGroup;
  resetToken;
  public form = {
    email : null,
    password : null,
    password_confirmation : null,
    resetToken : null,
  };
  passwordOk = false;

  constructor(
      private route: ActivatedRoute,
      private _userService: UserService,
      // private notify: SnotifyService,
      private router: Router,
      private fb: FormBuilder,
      private toastr: ToastrService,

  ) {

      route.queryParams.subscribe(params => {
        this.resetToken = params['token']
      })

      console.log( this.form.resetToken)
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.resetToken = params['token']
    })

    console.log(this.resetToken);

    this.resetPasswordForm = this.fb.group({
      email: [null, [Validators.required,
                    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: [null, [Validators.required, Validators.maxLength(50), Validators.minLength(8)]],
      password_confirmation: [null, [Validators.required,
        Validators.maxLength(50), Validators.minLength(8)]],
      resetToken: [this.resetToken, [Validators.required]],
    });
  }

  get f() { return this.resetPasswordForm.controls; }

  onSubmit() {

    console.log(this.resetPasswordForm.get('password').value);

    if (this.resetPasswordForm.get('password').value === this.resetPasswordForm.get('password_confirmation').value) {
        this.passwordOk = true;
    } else {
      this.passwordOk = false;
    }

    this.submitted = true;

    if (this.resetPasswordForm.invalid || this.passwordOk === false) {
      this.error = 'invalide Form  !';
      console.log(this.resetPasswordForm);
      return;
    } else {
      this._userService.changePassword(this.resetPasswordForm.value).subscribe(

        data => {
          console.log(data);
          this.router.navigate(['/login']);
          this.showNotification('top', 'left', 'Le mot de passe a été <b>bien modifié</b>.')
        },
        error => {
          console.log(error);
          this.showNotification('top', 'left', 'Veuillez verifier si votre email est correct.')
        }

      );
    }


  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }


}
