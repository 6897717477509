import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
  })
};

@Injectable({
  providedIn: 'root'
})
export class NotificationProjetService {

  constructor( private http: HttpClient, private router: Router ) { }

    // get Notification By id
  getNotification(idUser): Observable<any> {
    return this.http.get(environment.apiUrl + '/ProjetNotification/' + idUser, httpOptions);
    }
}
