import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoursService } from '../../services/cours.service';
import { ToastrService } from 'ngx-toastr';
import { ElementService } from '../../services/element.service';

@Component({
  selector: 'app-edit-cours',
  templateUrl: './edit-cours.component.html',
  styleUrls: ['./edit-cours.component.scss']
})
export class EditCoursComponent implements OnInit {

  editCoursForm: FormGroup;
  error: string;
  success: string;
  cours;
  idCours = this.route.snapshot.paramMap.get('id');
  test;
    id: number;
    titre: string;
    description: string;
    visibility: string;
    element_id;
    message: '';
    submitted = false;

  element;
  elements;
  idUser = localStorage.getItem('id');
  profil = localStorage.getItem('profil');

  // tslint:disable-next-line: max-line-length
  constructor(

        private coursService: CoursService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router : Router,
        private elementsService: ElementService,
    ) {

      elementsService.Elements().subscribe(
        res => {
              console.log(res);
              this.elements = res;
        },

        err => {
              console.log(err);
        }
      );

 }

  ngOnInit() {
    this.success = null;
    this.error = null;
    this.editCoursForm = this.formBuilder.group({
      titre: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
      description: ['', [Validators.maxLength(500)]],
      visibility: [null, Validators.required],
      element_id: [null, Validators.required],
      id: [this.idCours, Validators.required],
    });

    console.log('tesssst ngoniit douu5liii ');

    this.coursService.getCoursById(this.idCours).subscribe(res => {
      // console.log(res);
      this.cours = res;
      this.element_id = this.cours.element_id;
      console.log(this.element_id);

      // tslint:disable-next-line: radix
      this.editCoursForm.patchValue({
        titre: this.cours.titre,
        element_id: this.cours.element_id,
        description: this.cours.description,
        visibility: this.cours.visibility,
        id: this.idCours,

      });
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations de l\'utilisateur !';
    });
  }

  get f() { return this.editCoursForm.controls; }

  editCours() {

    if (document.activeElement.id === 'submit') {

    this.submitted = true;

    if (this.editCoursForm.invalid) {
      this.error = 'invalide Form  !';
      console.log(this.editCoursForm);
      return;
    }

    const formData = this.editCoursForm.value;

    this.success = null;
    this.error = null;
    this.coursService.editCours(formData).subscribe(
      (success) => {
        console.log(success);


        this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
        this.success = 'cours informations updated!';

        if (this.profil === 'Administrateur') {
          this.router.navigate(['/gestionCours', this.idUser]);
        } else {
          this.router.navigate(['/gestionMesCours']);
        }

        },
      (error) => {
        console.log(error);
        this.error = 'Error ! failed to update !';
        this.showNotification('top', 'left', 'Code Formation <b>dèja existe</b> , choisit un nouveau code');

      },
    );
    }
  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}
