import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-request-password-resset',
  templateUrl: './request-password-resset.component.html',
  styleUrls: ['./request-password-resset.component.scss']
})
export class RequestPasswordRessetComponent implements OnInit {

  public error;
  passwordForm: FormGroup;
  public form = {

    email : [null, [Validators.required,
      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],

  };


  submitted = false;
  isLoginError : boolean = false;

  constructor(
    private _userService: UserService,
    // private notify: SnotifyService,
    private toastr: ToastrService,
    private fb: FormBuilder,

    ) { }

  ngOnInit() {

    this.passwordForm = this.fb.group({
      email: [null, [Validators.required,
                    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
    });
  }


  get f() { return this.passwordForm.controls; }

  onSubmit(){

    this.submitted = true;

    if (this.passwordForm.invalid) {
      this.error = 'invalide Form  !';
      console.log(this.passwordForm);
      return;
    }

    this._userService.sendPasswordResetLink(this.passwordForm.value).subscribe(

      data => {
        console.log(data);
        this.showNotification('top', 'left', 'Votre demande a été <b>bien envoyé</b>. Vous pouvez consulter votre email.')
      },
      error => {
        console.log(error);
        this.showNotification('top', 'left', 'Veuillez verifier si votre email est correct.')
      }

    );
  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}
