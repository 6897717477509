import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { unsubscribeFromFilterStorage } from '../modules/profils/lauriat/lauriatFilterStorage';


const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
	})
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

	// Holds the validation errors coming from the server
	errorResp: HttpErrorResponse = null;

	// Holds if the user is logged in or not
	private loggedIn = new BehaviorSubject<boolean>(this._tokenService.isTokenValid());
	authStatus = this.loggedIn.asObservable();

	/**
	 * Creates an instance of user service.
	 * @param http
	 * @param router
	 * @param messageService
	 * @param _tokenService
	 */
	constructor(
		private http: HttpClient,
		private router: Router,
		private _tokenService: TokenService
	) { }









	/**
	 * Logins server side
	 * @param form
	 * @returns server$
	 */
	loginServer(data): Observable<User> {
    console.log(`userService => trying to loginServer : `
    // data
    );

		var email = data.email;
        var password = data.motDePasse;
        var all = email + ':' + password;
        var all_crypted = btoa(all);

        let myHeaders: HttpHeaders = new HttpHeaders();
        myHeaders = myHeaders.append('Authorization', 'Basic ' + all_crypted);
        return this.http.post(environment.apiUrl + '/auth/login',data, httpOptions);

	}

	/**
	 * Logins client side
	 * @param user
	 */
	loginClient(user: User): void {

		this.changeAuthStatus(true);
		localStorage.setItem('token', user.email);
		this.router.navigateByUrl('/dashboard');
	}

	/**
	 * Changes auth status
	 * @param value
	 */
	changeAuthStatus(value) {
		this.loggedIn.next(value);
	}








	/**
	 * Logouts server side
	 * @returns server$
	 */
	logoutServer(): Observable<User> {
		unsubscribeFromFilterStorage();
		console.log(`userService => trying to logoutServer`);

		return this.http.get(environment.apiUrl + '/login?logout', httpOptions);

	}

	/**
	 * Logouts client side
	 */
	logoutClient(): void {
		this.changeAuthStatus(false);
		localStorage.clear();
		this.router.navigateByUrl('/login');
	}











	/**
	 * Gets user from server side
	 * @returns user$
	 */
	getUserServer(): Observable<User> {
		console.log(`userService => trying to getUser`);

		return this.http.get<User>(environment.apiUrl + '/').pipe(
			tap((user: User) => console.log(`userService => got user = `, user)),
			catchError(this.handleError(`userService => user not got`, null))
		);
	}





	getUsers(): Observable<any> {
		let myHeaders: HttpHeaders = new HttpHeaders();
		myHeaders = myHeaders.append('Authorization', 'Basic YWxpOmFsaQ==');
		return this.http.get(environment.apiUrl+'/users/me', httpOptions);
	  }

	  getListeUsers(): Observable<any> {

		let myHeaders: HttpHeaders = new HttpHeaders();
		myHeaders = myHeaders.append('Authorization', 'Basic YWxpOmFsaQ==');
		return this.http.get(environment.apiUrl+'/users', httpOptions);
	  }

	  getProfile(): Observable<any> {

		let myHeaders: HttpHeaders = new HttpHeaders();
		myHeaders = myHeaders.append('Authorization', 'Basic YWxpOmFsaQ==');
		return this.http.get(environment.apiUrl+'/users/me', httpOptions);
	  }


	  editProfile(data): Observable<any> {

		let myHeaders: HttpHeaders = new HttpHeaders();
		myHeaders = myHeaders.append('Authorization', 'Basic YWxpOmFsaQ==');
		return this.http.post(environment.apiUrl+'/users/update',data, httpOptions);
	  }










	/**
	 * Gets error response
	 * @returns error response
	 */
	public getErrorResponse(): HttpErrorResponse {
		return this.errorResp;
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: T) {
		return (error: HttpErrorResponse): Observable<T> => {

			console.error(`\n ${operation} failed : ${error.message}`);

			this.errorResp = error;

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}


	getClaims(data):   Observable<any>
  {
    let postData = { }
    let myHeaders: HttpHeaders = new HttpHeaders();
    myHeaders = myHeaders.append('Authorization', 'Basic YWxpOmFsaQ==');
    return this.http.post(environment.apiUrl + '/auth/payload', postData, httpOptions);
  }




  sendPasswordResetLink(data){
    return this.http.post(environment.apiUrl + '/passwordResetLink', data, httpOptions);
  }

  changePassword(data){
    return this.http.post(environment.apiUrl + '/resetPassword', data, httpOptions);
  }
}
