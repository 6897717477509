import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
	})
};

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(private http: HttpClient, private router: Router) {}


  // get all Modules :
  getModules(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Module/List', httpOptions);
  }

  getModuleNiveauFormation(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Module/ModuleNiveauFormation/' + id, httpOptions);
  }

  isModule(des): Observable<any> {
    return this.http.get(environment.apiUrl + '/Module/isModule/' + des, httpOptions);
  }


  // Create Module :
  createModule(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/Module/Save', data, httpOptions);
  }

  // Delete Module :
  deleteModule(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Module/Delete/' + id);
  }

  // Edit Module :
  editModule(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Module/Update', data, httpOptions);
    }


 // get all Modules By Niveau:
 ModulesByNiveau(id): Observable<any> {
  return this.http.get(environment.apiUrl + '/Module/ModulesByNiveau/' + id, httpOptions);
}


  // get Module By id
  getModuleById(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Module/ModuleById/' + id, httpOptions);
    }


}
