import { Component, OnInit } from '@angular/core';
//import { ToastrService } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TokenService } from '../services/token.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../services/notification.service';
//import { AlertService } from 'app/services/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  key: string = 'token';
  submitted = false;
  error = '';
  isLoginError : boolean = false;

  constructor(
    private fb: FormBuilder,
    private _tokenService: TokenService,
    private notificationService: NotificationService,
    private _userService: UserService,
    private router : Router,
    private toastr: ToastrService
    //private alertService: AlertService,
    //private toastr: ToastrService
  ) { }

  ngOnInit() {

    // Forms initialization
    this.initLoginForm();
  }


    // Login Form
    loginForm: FormGroup;
    loginErrorMsg: string = '';
    emailErrorMsg: string = '';
    passwordErrorMsg: string = '';




    initLoginForm() {
      this.loginForm = this.fb.group({
        email: [null, [Validators.required,
                      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
        password: [null, [Validators.required, Validators.maxLength(50), Validators.minLength(8)]],
        remember: [true]
      });
    }

    get email(){
      console.log('tesssssst enaaaaaail ::::::' +  this.loginForm.get('email'))
      return this.loginForm.get('email');
    }

    get f() { return this.loginForm.controls; }

  submitLoginForm(email,password): void {

    this.submitted = true;
    if (this.loginForm.invalid) {
      if(this.loginForm.get('email').value === null){

        this.emailErrorMsg = "Email est obligatoire !";
      };

      if(this.loginForm.get('password').value === null){

        this.passwordErrorMsg = "Password est obligatoire !";
      };
      //this.loginErrorMsg = "Formulaire invalide !";

      return;
    }
    this.loginErrorMsg = null;
    this.emailErrorMsg = null;
    this.passwordErrorMsg = null;


    this._userService.loginServer(this.loginForm.value)
      .subscribe(
        (user : any) => {

          /*localStorage.setItem('user_info', user.access_token);
          const val = localStorage.getItem('user_info');*/
          console.log(user.access_token);
          this._tokenService.handle(user.access_token);
          const payload=this._tokenService.payload(user.access_token);
         // console.log(payload.email+ " the paaaaaaaaaaaaaylooooooooooooaaaad");

          let idUser = localStorage.getItem('id');

          this.notificationService.incrementNbreVisitesByDate(idUser)

         .subscribe(
           (success) => {
             console.log(success);


           },
           (error) => {
             console.log(error);


           },
         );

        /*  localStorage.setItem('profil', user.profil);
             const val2 = localStorage.getItem('profil');
          console.log( val2);*/

          if (payload.email== 'Etudiant') {
            this.router.navigate(['/etudiants/Etudiant']);
          } else if (payload.email == 'Administrateur') {

            this.router.navigate(['/etudiants/Etudiant']);

          } else {

            this.router.navigate(['/etudiants/Etudiant']);
          }


        },

        (error) => {
          this.showNotification('top', 'left');
          this.loginErrorMsg = "Aïe! une erreur c'est produite";
          console.error(error);
        });
    this.submitted = false;
  }


  /*
  error(message: string) {
    this.alertService.error(message);
}
*/



  cleanLoginPwd() {
    this.loginForm.patchValue({
      password: ''
    });
  }


  showNotification(from, align) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span> Le mot de passe ou l\'email <b>invalide</b>', '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: "alert alert-danger alert-with-icon",
      positionClass: 'toast-' + from + '-' +  align
       });
    }



}
