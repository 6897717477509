import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ProfilService } from '../services/profil.service';
import { Enseignant } from '../models/enseignant.model';
import { EnseignantService } from '../services/enseignant.service';
import { ToastrService } from 'ngx-toastr';
import { EtudiantService } from '../services/etudiant.service';
import { FormationService } from '../../formation/services/formation.service';
import { NiveauService } from '../../formation/services/niveau.service';

@Component({
  selector: 'app-enseignant',
  templateUrl: './enseignant.component.html',
  styleUrls: ['./enseignant.component.scss']
})
export class EnseignantComponent implements OnInit {

  invitationForm: FormGroup;
  error: string;
  userId: number = 1;
  uploadResponse = { status: '', message: '', filePath: '' };
  idUser = localStorage.getItem('id');
  role = this.route.snapshot.paramMap.get('profil');
  enseignants: Enseignant [] = [];
  profil = localStorage.getItem('profil');
  userable_id = localStorage.getItem('userable_id');
  niveaux;
  formations;


  i = 0;
  submitted = false;
  departement = 'departement';
  formation = 'formation';
  niveau = 'niveau';



  constructor(
    private profilService: ProfilService,
    //private toastr: ToastrService,
    private router : Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private enseignantService: EnseignantService,
    private toastr: ToastrService,
    private etudiantService: EtudiantService,
    private formationService: FormationService,
    private niveauService: NiveauService

  ) {

   }

  ngOnInit(): void {


    if (this.profil === 'ResponsableFormation') {

      this.enseignantService.getEnseignantsByResponsable(this.userable_id).subscribe(
        res => {
              //console.log(res);
              this.enseignants = res;
        },

        err => {
              console.log(err);
        }
      );

      this.niveauService.getNiveauByRF(this.idUser).subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );

      this.formationService.getMaFormation(this.userable_id).subscribe(

        res => this.formations = res,
        error => console.log(error),
      );
    } else if (this.profil === 'Administrateur') {

      this.enseignantService.getEnseignants().subscribe(
        res => {
              //console.log(res);
              this.enseignants = res;
        },

        err => {
              console.log(err);
        }
      );

      this.niveauService.getNiveauxyByAdmin().subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );

      this.formationService.getFormations().subscribe(

        res => this.formations = res,
        error => console.log(error),
      );
    }

    this.invitationForm = this.formBuilder.group({
      file: ['', Validators.required],
      profil: [this.role, Validators.required]
    });

  }

  searchByDepartement(departement) {

    if (departement == null || departement === 'all' || departement === 'departement') {

      console.log('alllll');

      this.ngOnInit();

       } else {

            if (this.profil === 'ResponsableFormation') {

              this.enseignantService.getEnseignantsByDepartementByResponsable(this.userable_id, departement).subscribe(
                res => this.enseignants = res,

                err => console.log(err),
              );
            } else if (this.profil === 'Administrateur') {
              this.enseignantService.getEnseignantsByDepartement(departement).subscribe(
                res => this.enseignants = res,
                err => console.log(err),
              );
            }

      }
  }


  searchByFormtion(formation) {

    if (formation == null || formation === 'all' || formation === 'formation') {

      this.formation = 'all';

      console.log('alllll');

      this.ngOnInit();

       } else {

            this.formation = formation;

            this.enseignantService.getEnseignantsByFormation(formation).subscribe(
                res => this.enseignants = res,
                err => console.log(err),
              );

            this.niveauService.getNiveauxByFormation(formation).subscribe(
                res => this.niveaux = res,
                err => console.log(err),
              );

      }
  }



  searchByNiveau(niveau) {

    if (this.formation === 'all' || this.formation === 'formation' || this.formation === null) {

        if (niveau == null || niveau === 'all' || niveau === 'niveau') {

          console.log('alllll');

          this.ngOnInit();

          } else {

                  this.enseignantService.getEnseignantsByNiveau(niveau).subscribe(
                    res => this.enseignants = res,
                    err => console.log(err),
                  );

          }
    } else {

        if (niveau == null || niveau === 'all' || niveau === 'niveau') {

          console.log('alllll');
          this.enseignantService.getEnseignantsByFormation(this.formation).subscribe(
              res => this.enseignants = res,
              err => console.log(err),
            );


          } else {

                  this.enseignantService.getEnseignantsByNiveau(niveau).subscribe(
                    res => this.enseignants = res,
                    err => console.log(err),
                  );

          }
    }

  }


  searchByNiveauResponsable(niveau) {

    if (niveau == null || niveau === 'all' || niveau === 'niveau') {

      this.enseignantService.getEnseignantsByResponsable(this.userable_id).subscribe(
        res => this.enseignants = res,
        err => console.log(err),
      );

    } else {

      this.enseignantService.getEnseignantsByNiveau(niveau).subscribe(
        res => this.enseignants = res,
        err => console.log(err),
      );

    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      console.log(event);
      const file = event.target.files[0];
      let testFile = this.invitationForm.get('file').setValue(file);
      console.log(file);
      console.log(testFile);
    }
  }

  get f() { return this.invitationForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.invitationForm.invalid) {
      this.error = 'invalide Form  !';
      console.log(this.invitationForm);
      return;
    }

    const formData = new FormData();
    const filetest = formData.append('file', this.invitationForm.get('file').value);
    console.log(formData.get('file'));
    const profil = formData.append('profil', this.role);
    console.log(formData.get('profil'));

    this.profilService.sendInvitation(formData).subscribe(
      (res) => {
        console.log(res)
        this.uploadResponse = res;
        this.i = this.i + 1;
        console.log(res);
        console.log(this.i);
        if (this.i === 4) {
          this.showNotification('top', 'left', 'Les invitation ont été <b>bien envoyé</b>.')
        }
      },
      (err) => this.error = err
    );

  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

    delete(id) {

      if (confirm('Êtes-vous sûr de vouloir supprimer cet enseignant?.')) {

        this.enseignantService.deleteEnseignant(id).subscribe(
          (data) => {
            this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
            // window.location.reload();
            this.ngOnInit();
          },
          (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

        );
      }

    }
}
