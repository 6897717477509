import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RessourceService } from '../../services/ressource.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-ressource',
  templateUrl: './create-ressource.component.html',
  styleUrls: ['./create-ressource.component.scss']
})
export class CreateRessourceComponent implements OnInit {


  idCours = this.route.snapshot.paramMap.get('id');
  lienForm: FormGroup;
  error: string;
  idUser = localStorage.getItem('id');
  success: string;
  submitted = false;
  message: '';
  selectedRestriction = 'selectedRestriction';


   constructor(
       private ressourceService: RessourceService,
       private router: Router,
       private formBuilder: FormBuilder,
       private route: ActivatedRoute,
       private toastr: ToastrService

     ) {

     }


   ngOnInit() {

     this.success = null;
     this.error = null;
     this.lienForm = this.formBuilder.group({
       titre: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
       lien: [null, [Validators.required]],
       cour_id: [this.idCours, Validators.required],
       visibility: [null, Validators.required],
       description: [null, [Validators.maxLength(500)]],
     });

   }
   get f() { return this.lienForm.controls; }


   onSubmit() {

    if ( document.activeElement.id === 'passeToNextLien' || document.activeElement.id === 'finish' ) {

        this.submitted = true;

        if (this.lienForm.invalid || this.lienForm.get('visibility').value === 'selectedRestriction') {
          this.error = 'invalide Form  !';
          console.log(this.lienForm);
          return;
        } else {

            const formData = this.lienForm.value;

            this.success = null;
            this.error = null;
            this.ressourceService.createRessource(formData).subscribe(
              data => {
                if (document.activeElement.id === 'passeToNextLien') {
                  console.log('test dooooooooc');
                  this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>. Vous pouvez ajouter un autre ressource');

                  // this.router.navigate(['/createRessource', this.idCours]);
                  window.location.reload();
                } else if (document.activeElement.id === 'finish') {

                  this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>'),

                  // console.log('test cououuuurs');
                  this.router.navigate(['/consulterCours', this.idCours]);
                }
              },
              error => console.log(error),
            );
        }


        // let titre = formData.titre;

        // console.log('teeeeest ::' + titre);
        // this.router.navigate(['/createDocument']);
      }
   }
   showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}
