import { Component, OnInit } from '@angular/core';
import { ElementService } from '../../services/element.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Element } from '../../models/element.model';
import { ToastrService } from 'ngx-toastr';
import { ModuleService } from '../../services/module.service';
@Component({
  selector: 'app-gerer-element',
  templateUrl: './gerer-element.component.html',
  styleUrls: ['./gerer-element.component.scss']
})
export class GererElementComponent implements OnInit {


  elements: Element[] = [];
  idModule= this.route.snapshot.paramMap.get('id');
  var1 = false;
  var2 ;
  var3= false;
  Module;
  constructor(
    private elementService: ElementService,
    private moduleService: ModuleService,
    private router: Router,
    private route : ActivatedRoute,
    private toastr: ToastrService,

  ) {
   }

  ngOnInit() {

    this.elementService.getElements(this.idModule).subscribe(res => {

      this.elements = res;

    }, err => {
      console.log(err);
    });

    this.moduleService.getModuleNiveauFormation(this.idModule).subscribe(res => {

      this.Module = res;

    }, err => {
      console.log(err);
    });
  }

  delete(id){
    // this.elementService.deleteElement(id).subscribe();
    if (confirm('Êtes-vous sûr de vouloir supprimer cet element? \nLes données associés seront également supprimés.')) {

      this.elementService.deleteElement(id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          // window.location.reload();
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

      );
    }
  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

    toggleDetails(Element) {
      this.var3= false;
      console.log(this.var2 + " "+this.var1)
      this.var2 = Element.id;
      if(Element.id!= this.var2){
        this.var1 = true;
        this.var3= true;
      }else{
        this.var1 =! this.var1;
        this.var3= true;
      }
  
      }

}
