import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EtudiantService } from '../../services/etudiant.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from 'src/app/modules/formation/services/niveau.service';

@Component({
  selector: 'app-create-etudiant',
  templateUrl: './create-etudiant.component.html',
  styleUrls: ['./create-etudiant.component.scss']
})
export class CreateEtudiantComponent implements OnInit {

  public etudiantForm: FormGroup;
  public etudiantTypes: any = [
    { id: 0, title: "EN COURS" },
    { id: 1, title: "LAURÉAT" }
  ]
  error: string;
  success: string;
  isButton: boolean = true;
  niveaux;
  etudiant:
    {

    };
  profil = localStorage.getItem('profil');
  userable_id = localStorage.getItem('userable_id');


  submitted = false;
  cinExist: number;
  message: '';

  idUser = localStorage.getItem('id');
  idEnseignant = localStorage.getItem('userable_id');
  niveau_id = 'niveau_id';
  type: any = 0;
  formTitle: string = localStorage.getItem("type") == "0" ? "étudiant" : "lauréat"

  constructor(
    private etudiantService: EtudiantService,
    private niveauxService: NiveauService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {

    if (this.profil === 'Administrateur') {

      niveauxService.getNiveaux().subscribe(
        res => {
          console.log(res);
          if (localStorage.getItem("type") == "1") {
            this.niveaux = res.filter(niveau => niveau.designation.toLowerCase().indexOf("troi") > -1 
            || niveau.designation.toLowerCase().indexOf("cinq") > -1)
          } else
            this.niveaux = res;        },

        err => {
          console.log(err);
        }
      );
    } else if (this.profil === 'ResponsableFormation') {

      niveauxService.NiveauxByEnseignant(this.userable_id).subscribe(
        res => {
          console.log(res);
         this.niveaux=res
        },

        err => {
          console.log(err);
        }
      );
    }


  }

  ngOnInit() {

    // let email = localStorage.getItem('email');
    // console.log(email);

    this.success = null;
    this.error = null;
    this.etudiantForm = this.formBuilder.group({
      'cin': [null, [Validators.required]],
      'codeApogee': [null, [Validators.required]],
      'niveau_id': [null, Validators.required],
      'email': [null, [Validators.required,
      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      'cne': [null, [Validators.required]],
      'nom': [null, [Validators.required]],
      'prenom': [null, [Validators.required]],
      'villeResidence': [null, [Validators.required]],
      "type": [localStorage.getItem('type'), [Validators.required]],
      "cv": [""],
      'tel': [null, [Validators.required,
      Validators.minLength(9),
      Validators.maxLength(15)]],
    });
    this.type = localStorage.getItem("type");

  }


  get f() { return this.etudiantForm.controls; }

  createEtudiant() {

    if (document.activeElement.id === 'finish') {
      this.submitted = true;

      if (this.etudiantForm.invalid || this.etudiantForm.get('niveau_id').value === 'niveau_id') {
        this.error = 'invalide Form  !';
        console.log(this.etudiantForm);
        return;
      } else {

        const formData = this.etudiantForm.value;

        this.success = null;
        this.error = null;
        console.log(formData)
        this.etudiantService.createEtudiant(this.formDataObject(formData)).subscribe(

          (data) => {
            this.showNotification('top', 'left', 'L\'invitation a été <b>bien Envoyée</b>.'),
              this.router.navigate(['/etudiants', 'Etudiant']);
          },

          (error) => error
        );

      }

    }

  }
  formDataObject(object: any): FormData {
    var fd = new FormData();
    for (var key in object) {
      if (object.hasOwnProperty(key))
        if (object[key] != "" && object[key] != null) {
          fd.append(key, object[key]);
        }
    }
    return fd;
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const size = event.target.files[0].size;
      console.log(size);

      this.etudiantForm.get("cv").setValue(file);

    }
  }
  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' + align
    });
  }
}
