import { Component, OnInit } from '@angular/core';
import { OffresService } from '../../services/offre.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProfilService } from 'src/app/modules/profils/services/profil.service';
import { Offre } from '../../models/offre.model';

@Component({
  selector: 'app-create-offre',
  templateUrl: './create-offre.component.html',
  styleUrls: ['./create-offre.component.scss']
})
export class CreateOffreComponent implements OnInit {

  offre: Offre;
  offreForm: FormGroup;
  error: string;
  submitted = false;
  success: string;
  cinExist:number;
  message: '';
  constructor(
    private offresService: OffresService,
    private profilService:ProfilService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    ) { }

  ngOnInit() {

    this.offreForm = this.formBuilder.group({
      entreprise_nom: [null, [Validators.required]],
      description: [null, [Validators.required]],
      email_contact: [null, [Validators.required]],
      entreprise_lien: [null, Validators.required],
    });
  }

  get f() { return this.offreForm.controls; }

  createOffre() {


    this.submitted = true;


    if (this.offreForm.invalid ) {
          this.error = 'invalide Form  !';
          console.log(this.offreForm);
          return;
        } else {

          const formData = this.offreForm.value;

          this.success = null;
          this.error = null;
          this.offresService.createOffres(formData).subscribe(

            (data) => {
              this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>');
              if (document.activeElement.id === 'passeToNiv') {
                this.router.navigate(['/createNiveau', this.offreForm.get('code').value]);
              } else if (document.activeElement.id === 'finish') {
                this.router.navigate(['/gererOffre']);
              };
            },
            (error) => {
              console.log(error.message);
              this.showNotification('top', 'left', 'Code offre <b>dèja existe</b> , choisit un nouveau code');
          },

          );
        }
        


    }


    showNotification(from, align, message) {

      const color = Math.floor((Math.random() * 5) + 1);

      this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
        timeOut: 6000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-' + from + '-' +  align
         });
      }

      showNotificationSuccess(from, align) {

        const color = Math.floor((Math.random() * 5) + 1);

        this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span> Les données sont <b>bien Ajoutées</b>', '', {
          timeOut: 6000,
          closeButton: true,
          enableHtml: true,
          toastClass: 'alert alert-danger alert-with-icon',
          positionClass: 'toast-' + from + '-' +  align
           });
        }

}
