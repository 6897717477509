import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
	})
};

@Injectable({
  providedIn: 'root'
})
export class EtudiantService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }


  // get all Etudiants :
  getEtudiants(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Etudiant/List');
  }

      // get all Etudiants By Responsable :
      getEtudiantsByResponsable(id): Observable<any> {
        return this.http.get(environment.apiUrl + '/Etudiant/ListByEtudiant/' + id);
      }

  // Create Etudiants :
  createEtudiant(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/Etudiant/Save', data);
  }

  // Delete Etudiants :
  deleteEtudiant(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Etudiant/Delete/'+id);
  }

  // Edit Etudiants :
  editEtudiant(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/Etudiant/Update', data);
    }

    editProfil(data): Observable<any> {
      return this.http.post(environment.apiUrl + '/Etudiant/Edit', data);
      }


    public upload(data) {

    console.log(data.get('cne'));

    console.log('teeestt uplaod');
    console.log(data);

    return this.http.post<any>(environment.apiUrl + '/Etudiant/Edit', data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  // get Etudiant By id
  getEtudiantById(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Etudiant/' + id, httpOptions);
    }

        // get Image
  downloadImage(id) {
    return this.http.get(environment.apiUrl + '/Etudiant/images/' + id,
      { responseType: 'blob' ,
      headers: new HttpHeaders().append('content-type', 'image/jpeg')});
    }


  // get Etudiant By id
  getEtudiantsByFormation(formation): Observable<any> {
    return this.http.get(environment.apiUrl + '/Etudiant/ListByFormation/' + formation, httpOptions);
    }

  getEtudiantsByNiveau(idNiveau): Observable<any> {
    return this.http.get(environment.apiUrl + '/Niveau/Etudiants/' + idNiveau);
  }
  editSuspention(id): Observable<any> {
    console.log("into service");
    console.log(id);
    return this.http.put(environment.apiUrl + '/Etudiant/UpdateSuspention/' + id, httpOptions);

    }
}
