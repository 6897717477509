import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GestionModulesService {

  constructor(private http: HttpClient, private router: Router) { }

   // get all app module :
   getAppModules(): Observable<any> {

    return this.http.get(environment.apiUrl + '/AppModule/List');
  }


  // Create App Module:
  createAppModule(data): Observable<any> {

    return this.http.post(environment.apiUrl + '/AppModule/Save', data);
  }


  // Delete App Module :
  deleteAppModule(id): Observable<any> {
    //console.log(id + " id of cours service to delete");
    return this.http.delete(environment.apiUrl + '/AppModule/Delete/' + id);
  }


  // Edit App Module:
  editAppModule(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/AppModule/Update', data);
    }


  // get all Menus :
  getMenus(): Observable<any> {

    return this.http.get(environment.apiUrl + '/Menu/ListMenuComp');
  }


  // Create Menu:
  createMenu(data): Observable<any> {

    return this.http.post(environment.apiUrl + '/Menu/Save', data);
  }


  // Delete Menu :
  deleteMenu(id): Observable<any> {
    //console.log(id + " id of cours service to delete");
    return this.http.delete(environment.apiUrl + '/Menu/Delete/' + id);
  }


  // Edit Menu:
  editMenu(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Menu/Update', data);
    }

  // get Composants By Menu
    getComposantsByMenu(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/'+id+'/Menus');
    }
// get all Compsants :
getComposants(): Observable<any> {

  return this.http.get(environment.apiUrl + '/Composant/List');
}


// Create Composant:
createComposant(data): Observable<any> {

  return this.http.post(environment.apiUrl + '/Composant/Save', data);
}


// Delete Composant :
deleteComposant(id): Observable<any> {
  //console.log(id + " id of cours service to delete");
  return this.http.delete(environment.apiUrl + '/Composant/Delete/' + id);
}


// Edit Composant:
editComposant(data): Observable<any> {
  return this.http.put(environment.apiUrl + '/Composant/Update', data);
  }

}
