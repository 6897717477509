import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { TokenService } from '../services/token.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BeforeLoginGuard implements CanActivate {
	path: ActivatedRouteSnapshot[];
	route: ActivatedRouteSnapshot;

	result: any = [];
	key: string = 'token';
	key2: string = 'profil';
	/**
	 * Creates an instance of before login guard.
	 * @param _tokenService
	 * @param router
	 */
	constructor(
		private router: Router
	) { }


	canActivate(
		next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
	):
		boolean | Observable<boolean> | Promise<boolean> {
		return new Promise((resolve, reject) => {
			var val = localStorage.getItem(this.key);
			var val2 = localStorage.getItem(this.key2);

			if (val == null || val == undefined) {
				resolve(true);
			}

			else if (val2 == 'Etudiant') {
				//console.log(val);
				//console.log(val2);
				//console.log("You are etudiant");
				this.router.navigateByUrl('/dashboard');
				resolve(false);
			}

			else {
				//console.log(val);
				//console.log(val2);
				//console.log("You are already logged in");
				this.router.navigateByUrl('/dashboard');
				resolve(false);
			}
		});
	}

}
