import { Component, OnInit } from '@angular/core';
import { CoursService } from '../../services/cours.service';
import { Cours } from '../../models/cours.model';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-consulter-cours-by-module',
  templateUrl: './consulter-cours-by-module.component.html',
  styleUrls: ['./consulter-cours-by-module.component.scss']
})
export class ConsulterCoursByModuleComponent implements OnInit {

  static nb = 0; 
  des:String;
  cours: Cours [] = [];
  role: string = 'profil';
  myParam: string;
  constructor(private route: ActivatedRoute, private coursService: CoursService, private location: Location, private router: Router) {
  //  let niveau_designation = this.route.snapshot.paramMap.get('niveau_designation');
  let des = this.route.snapshot.paramMap.get('des');
 /*  this.route.params.subscribe(
   params => {
       let des = params['des'];*/
       console.log(des +"  des consulter by niveau" );
       console.log(des +"  this.des consulter by niveau" );
 /* if(des== null){
  coursService.getCours().subscribe(res => {
    console.log(this.des );
    console.log(res);
    
    this.cours = res;

  }, err => {
    console.log(err);


  });}else{*/
    /*coursService.getCoursByNiveau(des).subscribe(res => {
      console.log(des);
      console.log(res);
      this.cours = res;
  
    }, err => {
      console.log(err);

    });*/
 // }

 /*   }
);*/
  }
   
  public localStorageItem(role: string): string {
    const rolee = localStorage.getItem(this.role);
    console.log('After    ' + rolee);
    return rolee;
}
  ngOnInit() {
 
    //this.des = this.route.snapshot.paramMap.get('des');
    let des = this.route.snapshot.paramMap.get('des');
  }

}
