import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProjetService } from '../services/projet.service';
import { Projet } from '../models/projet.model';
import  saveAs from 'file-saver';

@Component({
  selector: 'app-ajou-rapport',
  templateUrl: './ajou-rapport.component.html',
  styleUrls: ['./ajou-rapport.component.scss']
})
export class AjouRapportComponent implements OnInit {

  rapportForm: FormGroup;
  error: string;
  userId: number = 1;
  uploadResponse = { status: '', message: '', filePath: '' };
  idEtudiant = localStorage.getItem('id');
  profil = localStorage.getItem('profil');


  i = 0;
  submitted = false;
  projets;
  public show:boolean = false;

  constructor(
    private projetService: ProjetService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,

  ) { }


  ngOnInit(): void {

    this.rapportForm = this.formBuilder.group({
      file: ['', Validators.required],
      idEtudiant: [this.idEtudiant, Validators.required]
    });

    this.projetService.rapportsByEtudiant(this.idEtudiant).subscribe(
      res => {
                this.projets = res;
                console.log(res);
      },
      error => console.log(error),
    );
  }


  toggleRapport(id) {
    this.show = true;
    console.log(id);
  }


 downloadFile(id, file_name) {
  this.projetService.downloadFile(id).subscribe(
    data => saveAs(data, file_name),
    error => console.error(error)
  );
}

  onFileChange(event) {
    if (event.target.files.length > 0) {
      console.log(event);
      const file = event.target.files[0];
      let testFile = this.rapportForm.get('file').setValue(file);
      console.log(file);
      console.log(testFile);
    }
  }

  get f() { return this.rapportForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.rapportForm.invalid) {
      this.error = 'invalide Form  !';
      console.log(this.rapportForm);
      return;
    }

    const formData = new FormData();
    const filetest = formData.append('file', this.rapportForm.get('file').value);
    console.log(formData.get('file'));
    const idEtudiant = formData.append('idEtudiant', this.idEtudiant);
    console.log(formData.get('idEtudiant'));

    this.projetService.sendRapport(formData).subscribe(
      (res) => {
        console.log(res)
        this.uploadResponse = res;
        this.i = this.i + 1;
        console.log(res);
        console.log(this.i);
        if (this.i === 4) {
          this.showNotification('top', 'left', 'Le rapport a été <b>bien envoyé</b>.')
        }
      },
      (err) => this.error = err
    );

  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}

