import { Component, OnInit } from '@angular/core';
import { Offre } from '../../models/offre.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OffresService } from '../../services/offre.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-Offre',
  templateUrl: './edit-Offre.component.html',
  styleUrls: ['./edit-Offre.component.scss']
})
export class EditOffreComponent implements OnInit {

  idOffre = this.route.snapshot.paramMap.get('id');
  Offre:Offre
  OffreForm: FormGroup
  error:string
  success:string
  cinExist:number;
  message: '';
  submitted = false;
  userable_id = localStorage.getItem('userable_id');
  profil = localStorage.getItem('profil');

  constructor(
    private OffresService: OffresService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,

    ) {


    }


    ngOnInit() {


      this.OffreForm = this.formBuilder.group({
        entreprise_nom: [null, [Validators.required]],
        description: [null, [Validators.required]],
        email_contact: [null, [Validators.required]],
        entreprise_lien: [null, Validators.required],
      });
      this.OffresService.getOffresById(this.idOffre).subscribe(res => {
        this.Offre= res;
        console.table(res);
          this.OffreForm.patchValue({
            entreprise_nom:this.Offre.entreprise_nom,
            description: this.Offre.description,
            email_contact: this.Offre.email_contact,
            entreprise_lien: this.Offre.entreprise_lien,
          });
        });
  
      }
  
    

  get f() { return this.OffreForm.controls; }


  editOffre() {

        this.submitted = true;
        if (this.OffreForm.invalid) {
          this.error = 'invalide Form  !';
          console.log(this.OffreForm);
          return;
        }

        const formData = this.OffreForm.value;

        this.success = null;
        this.error = null;

        this.OffresService.editOffres(this.idOffre,formData).subscribe(
              (data) => {

                  this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
                    this.router.navigate(['/gererOffre']);
              },
              (error) => {
                console.log(error.message);
                this.showNotification('top', 'left', 'Code Offre <b>dèja existe</b> , choisit un nouveau code');
            },
            );
    }


    showNotification(from, align, message) {

      const color = Math.floor((Math.random() * 5) + 1);

      this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
        timeOut: 6000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-' + from + '-' +  align
         });
      }


}
