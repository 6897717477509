import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Etudiant } from '../../models/etudiant.model';
import { EtudiantService } from '../../services/etudiant.service';

@Component({
  selector: 'app-image-etudiant',
  templateUrl: './image-etudiant.component.html',
  styleUrls: ['./image-etudiant.component.scss']
})
export class ImageEtudiantComponent implements OnInit {

  idEtudiant = this.route.snapshot.paramMap.get('id');
  etudiant : Etudiant;
  image;
  profil = localStorage.getItem('profil');
  imageDirectoryPath: any = 'http://localhost:8000/public/image/';

  constructor(
    private etudiantService: EtudiantService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    this.etudiantService.getEtudiantById(this.idEtudiant).subscribe(
      data => {
        console.log(this.idEtudiant);
        this.etudiant = data;
        console.log(this.etudiant);
      },

      error => console.log(error),

    )
  }

}
