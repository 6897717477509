import { Injectable } from '@angular/core';
import { HttpHeaders, HttpEventType, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
  })
};

@Injectable({
  providedIn: 'root'
})
export class RessourceService {

  constructor(private http: HttpClient, private router: Router, private httpClient: HttpClient) {}


  // get all Ressources :
  getRessources(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Ressource/List', httpOptions);
  }

    // get all Ressources :
    getRessourcesByUser(id, idUser): Observable<any> {
      return this.http.get(environment.apiUrl + '/Ressource/ListRessourcesByUser/' + id + '/' + idUser);
    }

    // get all documents :
    getAllRessourcesByUser(idUser): Observable<any> {
      return this.http.get(environment.apiUrl + '/Ressource/ListAllRessourcesByUser/' + idUser);
    }

  // get all documents :
  getAllRessourcesByEnseigant(idUser): Observable<any> {
    return this.http.get(environment.apiUrl + '/Ressource/AllRessourcesByEnseignant/' + idUser);
  }

  // Create Ressource :
  createRessource(data): Observable<any> {
    console.log(data.titre);
    console.log(data.description);
    return this.http.post(environment.apiUrl + '/Ressource/Save', data, httpOptions);
  }


  // Delete Ressource :
  deleteRessource(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Ressource/Delete/'+ id);
  }

    // Archive Ressource :
  archiveRessource(id): Observable<any> {
      return this.http.delete(environment.apiUrl + '/Ressource/Archive/'+ id);
    }

    // Restore ressource :
  restoreRessource(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Ressource/Restore/' + id);
  }

  // Edit Ressource :
  editRessource(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Ressource/Update', data, httpOptions);
    }

  // get Ressource By id
    getRessourceById(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Ressource/' + id, httpOptions);
      }

   // get Ressource By Niveau
   getRessourceByNiveau(des): Observable<any> {
    return this.http.get(environment.apiUrl + '/Ressource/ListByNiveau/'+des , httpOptions);
    }


  // get Mes Cours Archives - Enseignant
  getMesRessourcesArchives(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Ressource/MesRessourcesArchives/' + id);
   }

  // get Ressource By Module
   getRessourceByModule(des): Observable<any> {
    return this.http.get(environment.apiUrl + '/Ressource/ListByModule/'+ des , httpOptions);
    }

  // get Module By Ressource
  getModuleByRessource(id): Observable<any> {
    return this.http.put(environment.apiUrl + '/Ressource/Update', id, httpOptions);
    }

  // get Enseignants By Ressource
  getEnseignantsByRessource(id): Observable<any> {
    return this.http.put(environment.apiUrl + '/Ressource/Update', id, httpOptions);
    }

    // get Ressources By Ressource
  getRessource(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Ressource/'+id+'/Ressources', httpOptions);
    }

     // get Ressources By Ressource
  getRessourcess(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Ressource/'+id+'/Ressources', httpOptions);
    }

  // get Niveau By Ressource
  getNiveauByRessource(id): Observable<any> {
    return this.http.put(environment.apiUrl + '/Ressource/Update', id, httpOptions);
    }

}

