import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfilService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }


  // get all Administrateurs :
  getAdministrateurs(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Administrateur/List');
  }

  getEnseignantById(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Enseignant/' + id);
  }

  verifyEnseignant(cin): Observable<any> {
    return this.http.get(environment.apiUrl + '/Enseignant/verifyCin/' + cin);
  }

  // Create Administrateur :
  createAdministrateur(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/Administrateur/Save', data);
  }

  // Delete Administrateur :
  deleteAdministrateur(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Administrateur/Delete/'+ id);
  }

  // Edit Administrateur :
  editAdministrateur(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Administrateur/Update', data);
    }

    // Edit Profil Administrateur :
  editProfil(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Administrateur/Edit', data);
   }

     // Create Administrateur :
  editPassword(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/editPassword', data);
  }

  // get Administrateur By id
  getAdministrateurById(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Administrateur/' + id);
    }



  // Edit Administrateur :
  sendInvitation(data): Observable<any> {

    console.log(data.get('profil'));

    console.log('teeestt uplaod');

    return this.http.post<any>(environment.apiUrl + '/user/import', data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  responseInvitation(data) {

    console.log(data)
    return this.http.post(environment.apiUrl + '/user/responseInvitation', data);
  }
}
