import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormationService } from '../../formation/services/formation.service';
import { EnseignantService } from '../../profils/services/enseignant.service';
import { soutenanceExcel } from '../models/soutenanceExcel.model';
import { ExcelSoutenancesService } from '../services/excel-soutenances.service';
import { ProjetService } from '../services/projet.service';
import { SoutenanceService } from '../services/soutenance.service';

@Component({
  selector: 'app-gerer-soutenances',
  templateUrl: './gerer-soutenances.component.html',
  styleUrls: ['./gerer-soutenances.component.scss']
})
export class GererSoutenancesComponent implements OnInit {


  idUser = localStorage.getItem('id');
  profil = localStorage.getItem('profil');
  name = localStorage.getItem('name');
  soutenances;
  //table = {key : 'sujet', key : };
  table;
  editButton;
  soutenance;
  error: string;
  projets;
  success: string;
  submitted = false;
  editSoutenanceForm: FormGroup;
  departement;
  departement2;
  enseignants;
  enseignants2;
  president;
  examinateur;
  excel = [];
  formation;
  formations;
  etudiants = '';
  juries = '';
  encadrants = '';

  constructor(
    private route: ActivatedRoute,
    private soutenanceService: SoutenanceService,
    private formationService: FormationService,
    private toastr: ToastrService,
    private enseignantService: EnseignantService,
    private projetService: ProjetService,
    private formBuilder: FormBuilder,
    private excelSoutenancesService: ExcelSoutenancesService


    ) { }

  ngOnInit() {

    this.soutenanceService.getSoutenanceByUser(this.idUser).subscribe(res => {
      console.log(this.idUser);
      console.log(res);
      this.soutenances = res;
      console.log(this.soutenances);
      this.soutenances.forEach(element => {

        element['isEdit'] = false;
        console.log(element.projet_id);
        this.projetService.getFormationByProjet(element.projet_id).subscribe(
          res => {
            console.log(res.code);
            element['formation_code'] = res.code;
          }
        );

      });
    }, err => {
      console.log(err);
    });
    if (this.profil === 'Administrateur') {

      this.formationService.getFormations().subscribe(

        res => this.formations = res,
        error => console.log(error),
      );
    }

    this.projetService.getProjetsByUser(this.idUser).subscribe(
      res => {
        this.projets = res;
        console.log( res);
      },
      error => console.log(error),
    );

    this.success = null;
    this.error = null;
    this.editSoutenanceForm = this.formBuilder.group({
     projet_id: [null, [Validators.required]],
     date: [null, [Validators.required]],
     heureDebut: [null, Validators.required],
     heureFin: [null, Validators.required],
     salle : [null, Validators.required],
     departement: [null, [Validators.required]],
     departement2: [null],
     president: [null, Validators.required],
     examinateur: [null],
     id: [null, Validators.required]
    });

    this.soutenanceService.getJSON(FormData).subscribe(data => {

      console.log(`testtttttttttttttttt`)
      this.table = data;
        console.log(data);
        console.log(this.table);
        /*
        let table: string[] = ['Apple', 'Orange', 'Banana'];
        console.log(this.table[0]);

        table.forEach(row => {

         // row['sujet'] = 'test';
         console.log(row);

        });
        */

      data.forEach(element => {
        console.log(element.sujet);
        console.log(this.table);
        console.log('testttt taable soutenances');
        this.table.fill(element);
   /*     this.table.forEach(row => {

          row.sujet = element.sujet;
          row.salle = element.salle;
          row.type = element.type;
          console.log('testttt taable table');

        });
*/



console.log(this.table)
          }),

          data.forEach(element => {

            // etudiants :::::::::
            element.etudiants.forEach(etudiant => {
              console.log(etudiant);
                this.etudiants = this.etudiants + etudiant.nom + ' ' + etudiant.prenom + ';';

                console.log(this.etudiants);

            });

            element['etudiants'] = this.etudiants;
            console.log(this.etudiants);

            // juries :::::::::::::::::::

            element.juries.forEach(jury => {
              console.log(jury);
                this.juries = this.juries + jury.nom + ' ' + jury.prenom + ';';

                console.log(this.juries);

            });

            element['juries'] = this.juries;
            console.log(this.juries);

            // encadrants :::::::::::::::::
            element.encadrants.forEach(encadrant => {
              console.log(encadrant);
                this.encadrants = this.encadrants + encadrant.nom + ' ' + encadrant.prenom + ';';

                console.log(this.encadrants);

            });

            element['encadrants'] = this.encadrants;
            console.log(this.encadrants);
            ////////////////////////////////

          });

          console.log(data)

          data.forEach(row => {
            console.log(`testtttttttttttttttt`)

        //row['etudiants'] = data.etudiants;
          console.log(row.encadrantExterne);
        this.excel.push(row);
      })
     });

  }


  exportAsXLSX(): void {
    this.excelSoutenancesService.exportAsExcelFile(this.excel, 'sample');
  }



close(data) {
  data.isEdit = false;
  //this.ngOnInit();
}

getSoutenanceById(data) {

  console.log(data);
  data.isEdit = true;
  console.log(data);


  this.soutenanceService.getJuriesBySoutenance(data.id).subscribe(res => {
    this.enseignants= res;
    console.log(this.enseignants);
    if(this.enseignants.length== 1){
      this.departement = this.enseignants[0].departement;
      this.selectEnseignantByDepartement(this.departement);
      this.president = this.enseignants[0].id;

    }else {
      console.log("2 encadrants");

      this.enseignants.forEach(element => {

        if (element.type_jury === 'president'){

          this.departement = element.departement;
          this.selectEnseignantByDepartement(this.departement);
          this.president = element.id;

        } else {
          this.departement2 = element.departement;
          this.selectEnseignantByDepartement2(this.departement2);
          this.examinateur= element.id;
        }

      });



    }
/*    this.encadrants.forEach(element => {
        this.departement = element.departement;
        this.selectEnseignantByDepartement(element.departement);
        this.encadrant = element.id;
        console.log(this.encadrant);
      }
    );*/

  }, err => {
    console.log(err);
    this.error = 'Erreur survenue lors de la recuperation des informations du lien !';
  });


}

get f() { return this.editSoutenanceForm.controls; }

selectEnseignantByDepartement(enseignant) {
  this.enseignantService.getEnseignantsByDepartement(enseignant).subscribe(
    res => this.enseignants = res,
    error => console.log(error),
  );
};


selectEnseignantByDepartement2(departement2) {
 this.enseignantService.getEnseignantsByDepartement(departement2).subscribe(

res => this.enseignants2 = res,
error => console.log(error),
 );
 };


onSubmit() {

  if (document.activeElement.id === 'finish') {

    this.submitted = true;
    if (this.editSoutenanceForm.invalid) {
      this.error = 'invalide Form  !';
      console.log(this.editSoutenanceForm);
      return;
    } else {

      const formData = this.editSoutenanceForm.value;

      this.success = null;
      this.error = null;

      this.soutenanceService.editSoutenance(formData).subscribe(
          data => {
            this.showNotification('top', 'left', 'Les données sont <b>bien Ajoutées</b>.');
            this.submitted = false;
            this.ngOnInit();
          },
          error => console.log(error),
        );
    }


  }


 }



////// delete soutenance ::

delete(id) {

  if (confirm('Êtes-vous sûr de vouloir supprimer ce soutenance?.')) {

    this.soutenanceService.deleteSoutenance(id).subscribe(
      (data) => {
        this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
        // window.location.reload();
        this.ngOnInit();
      },
      (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

    );
  }

}

searchByFormation(formation) {

  if (formation == null || formation === 'all' || formation === 'formation') {
      this.ngOnInit();
   } else {
      this.formation = formation;
      console.log(this.formation);
      this.soutenanceService.getSoutenancesByFormation(formation).subscribe(res => {
        this.soutenances = res;
        console.log(this.soutenances);
      }, err => {
        console.log(err);
      });
    }
}

searchByTypeAdmin(type) {

  if (this.formation == null || this.formation === 'all' || this.formation === 'formation') {

        if (type == null || type === 'all' || type === 'type') {

          this.soutenanceService.getSoutenances().subscribe(res => {
            console.log(this.idUser);
            console.log(res);
            this.soutenances = res;

            this.soutenances.forEach(element => {

              element['isEdit'] = false;

            });
          }, err => {
            console.log(err);
          }); } else {
            this.soutenanceService.getSoutenancesByType(this.idUser,type).subscribe(res => {
              console.log(this.idUser);
              console.log(res);
              this.soutenances = res;
            }, err => {
              console.log(err);
            });

          }
  } else {

    if (type == null || type === 'all' || type === 'type') {

      this.soutenanceService.getSoutenancesByFormation(this.formation).subscribe(res => {
        this.soutenances = res;
        console.log(this.soutenances);
      }, err => {
        console.log(err);
      });
    }

       else {

        this.soutenanceService.getSoutenancesByFormationAndType(this.formation, type).subscribe(res => {
          console.log(this.idUser);
          console.log(res);
          this.soutenances = res;
        }, err => {
          console.log(err);
        });
      }
  }

}

searchByTypeResponsable(type) {

  if (type == null || type === 'all' || type === 'type') {

    this.soutenanceService.getSoutenanceByUser(this.idUser).subscribe(res => {
      console.log(this.idUser);
      console.log(res);
      this.projets = res;
    }, err => {
      console.log(err);
    }); } else {
      this.soutenanceService.getSoutenancesByType(this.idUser,type).subscribe(res => {
        console.log(this.idUser);
        console.log(res);
        this.soutenances = res;
      }, err => {
        console.log(err);
      });

    }
}

editButtonOk() {
  this.editButton = 1;
  console.log(this.editButton);
}

showNotification(from, align, message) {

  const color = Math.floor((Math.random() * 5) + 1);

  this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
    timeOut: 6000,
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-danger alert-with-icon',
    positionClass: 'toast-' + from + '-' +  align
     });
  }

  editValidation(id) {

    this.soutenanceService.editValidation(id).subscribe(
      (data) => {

        console.log(data);
        this.showNotification('top', 'left', 'La Soutenance a été bien validée.');
        this.ngOnInit();
      },
      (error) => this.showNotification('top', 'left', 'Un erreur est produite lors la validation de la soutenance</b>.')

    );

}

}
