import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class TokenService {

  createUserUrl: string = '';
  loginUserUrl: string = '';

  iss: string[] = [this.createUserUrl, this.loginUserUrl];

  /**
   * Creates an instance of token service.
   */
  constructor() { }


  /**
   * Handles token service
   * @param token
   */
  handle(token: string): void {

    console.log("we are in the handle method !! ");
    this.set(token);

    console.log(this.isTokenValid());
        // this.payload(token);

  }


  /**
   * Sets token in the local storage
   * @param token
   */
    set(token: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('payload', this.payload(token));
    const payload = this.payload(token);
    localStorage.setItem('profil', payload.profil);
    localStorage.setItem('email', payload.email);
    localStorage.setItem('id', payload.id);
    localStorage.setItem('name', payload.name);
    localStorage.setItem('userable_id', payload.userable_id);


    let name: String = localStorage.getItem('name');
    let userable_id: String = localStorage.getItem('userable_id');

    let id = localStorage.getItem('id');
    let profil = localStorage.getItem('profil');



    // console.log('insiiide seeeeeeeeeeeeeeeeeet token')
    // console.log(profil + "payload profil");
    // console.log(name+ " payload name");

  }

  setPayload(token: string) {
    localStorage.setItem('payload', this.payload(token));

  }

  /**
   * Gets token service
   * @returns token
   */
  get(): string {
    return localStorage.getItem('token');

  }

  getPayload() {
    return localStorage.getItem('payload');

  }




  /**
   * Removes token from local storage
   */
  remove(): void {
    localStorage.removeItem('token');
  }

  /**
   * Determines whether token is valid or not
   * @returns
   */
  isTokenValid(): boolean {
    const token = this.get();
    console.log("I'm in is the Token valid function!");
    if (token) {
      const payload = this.payload(token);
    //  localStorage.setItem('payload', payload);


      if (payload) {
    return this.iss.indexOf(payload.iss) > -1;
      }
    }
  }

  /**
   * Payloads token service
   * @param token
   * @returns payload decoded
   */
  payload(token): any {
    console.log("I'm in payload function");
    let userable_id: String = localStorage.getItem('userable_id');

    console.log( 'userable_id ::::::: ' + userable_id);
    const payload = token.split('.')[1];

    return this.decode(payload);
    //return payload;
  }
  /**
   * Decodes token service
   * @param payload
   * @returns
   */

    decode(payload) {
    return JSON.parse(atob(payload));
  }

}
