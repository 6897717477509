import { Component, OnInit } from '@angular/core';
import { CoursService } from '../../services/cours.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Cours } from '../../models/cours.model';
import { ToastrService } from 'ngx-toastr';
import { ElementService } from '../../services/element.service';

@Component({
  selector: 'app-gerer-cours',
  templateUrl: './gerer-cours.component.html',
  styleUrls: ['./gerer-cours.component.scss']
})
export class GererCoursComponent implements OnInit {

  profil = localStorage.getItem('profil');
  email: String = localStorage.getItem('email');
  name: String = localStorage.getItem('name');
  id = localStorage.getItem('id');
  idElement = this.route.snapshot.paramMap.get('id');
  Element;
  test;
  cours;
  var1 = false;
  var2 ;
  var3= false;

  constructor(
    private coursService: CoursService,
    private elementService: ElementService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {

   

     
       this.coursService.getCoursByElement(this.idElement).subscribe(res => {

        console.log(res);

        this.cours = res;

        this.test = localStorage.getItem('name');
        console.log('tessssst ::::: ' + this.test);

      }, err => {
        console.log(err);


      });

      this.elementService.getElementModuleNiveauFormation(this.idElement).subscribe(res => {

        this.Element = res;
  
      }, err => {
        console.log(err);
      });
    
   }

  ngOnInit() { }

  delete(cour_id) {

    if (confirm('Êtes-vous sûr de vouloir supprimer cette cours? \nLes documents associés seront également supprimés.')) {

      this.coursService.deleteCours(cour_id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

      );
    }

  }


  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

    toggleDetails(cour) {
      this.var3= false;
      console.log(this.var2 + " "+this.var1)
      this.var2 = cour.id;
      if(cour.id!= this.var2){
        this.var1 = true;
        this.var3= true;
      }else{
        this.var1 =! this.var1;
        this.var3= true;
      }
      }
}
