import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing3',
  templateUrl: './landing3.component.html',
  styleUrls: ['./landing3.component.scss']
})
export class Landing3Component implements OnInit {
  constructor() { }
 
  ngOnInit() {
  }

}
