import { Component, OnInit } from '@angular/core';
import { CoursService } from '../../services/cours.service';
import { RessourceService } from '../../services/ressource.service';
import { Ressource } from '../../models/ressource.model';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gerer-ressource',
  templateUrl: './gerer-ressource.component.html',
  styleUrls: ['./gerer-ressource.component.scss']
})
export class GererRessourceComponent implements OnInit {
  ressources: Ressource [] = [];
  constructor(
    private coursService: CoursService,
    private ressourceService: RessourceService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    ) {

  }

  ngOnInit() {


    let id = this.route.snapshot.paramMap.get('id');
    this.coursService.getRessources(id).subscribe(res => {
      console.log(res);
      this.ressources = res;
    }, err => {
      console.log(err);
    });
  }

  archive(id) {
    
    if (confirm('Êtes-vous sûr de vouloir supprimer cette formation? \nLes documents associés seront également supprimés.')) {

      this.ressourceService.archiveRessource(id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          // window.location.reload();
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

      );
    }

  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}
