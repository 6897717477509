import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
	})
};

@Injectable({
  providedIn: 'root'
})
export class ElementService {

  constructor(private http: HttpClient, private router: Router) {}


  // get all elements :
  getElementsByEnseignant(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Element/Enseignant/'+ id, httpOptions);
  }

  getElements(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Element/List/'+ id, httpOptions);
  }

  getEnseignants(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Element/'+id+'/Enseignants', httpOptions);
  }

  Elements(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Element/List', httpOptions);
  }
  
  getElement(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Element/'+id, httpOptions);
  }

  getElementById(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Element/' + id, httpOptions);
  }
  getElementModuleNiveauFormation(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Element/ElementModuleNiveauFormation/' + id, httpOptions);
  }

  // Create element :
  createElement(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/Element/Save', data, httpOptions);
  }

  // Delete element :
  deleteElement(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Element/Delete/'+id);
  }

  // Edit element :
  editElement(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Element/Update', data, httpOptions);
    }

}
