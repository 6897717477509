import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProjetService } from '../services/projet.service';
import { Projet } from '../models/projet.model';

@Component({
  selector: 'app-import-rapport',
  templateUrl: './import-rapport.component.html',
  styleUrls: ['./import-rapport.component.scss']
})
export class ImportRapportComponent implements OnInit {

  rapportForm: FormGroup;
  error: string;
  userId: number = 1;
  uploadResponse = { status: '', message: '', filePath: '' };
  id = localStorage.getItem('userable_id');
  idRapport = this.route.snapshot.paramMap.get('idProjet');


  i = 0;
  submitted = false;
  projets;
  public show:boolean = false;

  constructor(
    private projetService: ProjetService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,

  ) { }


  ngOnInit(): void {

    this.rapportForm = this.formBuilder.group({
      rapport: ['', Validators.required],
      id: [this.idRapport, Validators.required]
    });
  }



  onFileChange(event) {
    if (event.target.files.length > 0) {
      console.log(event);
      const rapport = event.target.files[0];
      let testFile = this.rapportForm.get('rapport').setValue(rapport);
      console.log(rapport);
      console.log(testFile);
    }
  }

  get f() { return this.rapportForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.rapportForm.invalid) {
      this.error = 'invalide Form  !';
      console.log(this.rapportForm);
      return;
    }

    const formData = new FormData();
    const filetest = formData.append('rapport', this.rapportForm.get('rapport').value);
    console.log(formData.get('rapport'));
    formData.append('id', this.rapportForm.get('id').value);
    console.log(formData.get('id'));

    this.projetService.upload(formData).subscribe(
      (res) => {
        console.log(res)
        this.uploadResponse = res;
        this.i = this.i + 1;
        console.log(res);
        console.log(this.i);
        if (this.i === 4) {
          this.showNotification('top', 'left', 'Le rapport a été <b>bien envoyé</b>.')
        }
      },
      (err) => this.error = err
    );

  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }
}

