import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { ModuleListComponent } from './module-list/module-list.component';

import { CoursComponent } from './courss/cours/cours.component';
import { RouterModule } from '@angular/router';
import { ConsultationCoursComponent } from './courss/consultation-cours/consultation-cours.component';
import { ConsulterCoursByModuleComponent } from './courss/consulter-cours-by-module/consulter-cours-by-module.component';
import { ConsulterCoursByNiveauComponent } from './courss/consulter-cours-by-niveau/consulter-cours-by-niveau.component';
import { ConsulterCoursByEnseignantComponent } from './courss/consulter-cours-by-enseignant/consulter-cours-by-enseignant.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateCoursComponent } from './courss/create-cours/create-cours.component';
import { CreateDocumentComponent } from './document/create-document/create-document.component';
import { SppressionCoursComponent } from './courss/sppression-cours/sppression-cours.component';
import { HttpClientModule } from '@angular/common/http';
import { GererCoursComponent } from './courss/gerer-cours/gerer-cours.component';
import { CreateRessourceComponent } from './ressource/create-ressource/create-ressource.component';
import { EditCoursComponent } from './courss/edit-cours/edit-cours.component';
import { GererDocumentsComponent } from './document/gerer-documents/gerer-documents/gerer-documents.component';
import { EditDocumentComponent } from './document/edit-document/edit-document.component';
import { GererRessourceComponent } from './ressource/gerer-ressource/gerer-ressource.component';
import { EditRessourceComponent } from './ressource/edit-ressource/edit-ressource.component';
import { CreateFormationComponent } from './formation/create-formation/create-formation.component';
import { ListFormationComponent } from './formation/list-formation/list-formation.component';
import { CreateModuleComponent } from './module/create-module/create-module.component';
import { ListModuleComponent } from './module/list-module/list-module.component';
import { CreateNiveauComponent } from './niveau/create-niveau/create-niveau.component';
import { EditNiveauComponent } from './niveau/edit-niveau/edit-niveau.component';
import { GererNiveauComponent } from './niveau/gerer-niveau/gerer-niveau.component';
import { ConsultationNiveauComponent } from './niveau/consultation-niveau/consultation-niveau.component';
import { CreateElementComponent } from './element/create-element/create-element.component';
import { GererFormationComponent } from './formation/gerer-formation/gerer-formation.component';
import { EditFormationComponent } from './formation/edit-formation/edit-formation.component';
import { GererModuleComponent } from './module/gerer-module/gerer-module.component';
import { ConsultationModuleComponent } from './module/consultation-module/consultation-module.component';
import { EditModuleComponent } from './module/edit-module/edit-module.component';
import { GererElementComponent } from './element/gerer-element/gerer-element.component';
import { EditElementComponent } from './element/edit-element/edit-element.component';
import { MesCoursComponent } from './courss/mes-cours/mes-cours.component';
import { ListDocumentsComponent } from './document/list-documents/list-documents.component';
import { MesDocumentsComponent } from './document/mes-documents/mes-documents.component';
import { ListRessourcesComponent } from './ressource/list-ressources/list-ressources.component';
import { MesRessourcesComponent } from './ressource/mes-ressources/mes-ressources.component';
import { AjoutDocumentComponent } from './document/ajout-document/ajout-document.component';
import { GererMesCoursComponent } from './courss/gerer-mes-cours/gerer-mes-cours.component';
import { CoursArchivesComponent } from './courss/cours-archives/cours-archives.component';



@NgModule({
  declarations: [

    ModuleListComponent,

    CoursComponent,
    ConsultationCoursComponent,
    ConsulterCoursByModuleComponent,
    ConsulterCoursByNiveauComponent,
    ConsulterCoursByEnseignantComponent,
    CreateCoursComponent,
    CreateDocumentComponent,
    SppressionCoursComponent,
    GererCoursComponent,
    CreateRessourceComponent,
    EditCoursComponent,
    GererDocumentsComponent,
    EditDocumentComponent,
    GererRessourceComponent,
    EditRessourceComponent,
    CreateFormationComponent,
    ListFormationComponent,
    CreateModuleComponent,
    ListModuleComponent,
    CreateNiveauComponent,
    EditNiveauComponent,
    GererNiveauComponent,
    ConsultationNiveauComponent,
    CreateElementComponent,
    GererFormationComponent,
    EditFormationComponent,
    GererModuleComponent,
    ConsultationModuleComponent,
    EditModuleComponent,
    GererElementComponent,
    EditElementComponent,
    MesCoursComponent,
    ListDocumentsComponent,
    MesDocumentsComponent,
    ListRessourcesComponent,
    MesRessourcesComponent,
    AjoutDocumentComponent,
    GererMesCoursComponent,
    CoursArchivesComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule
  ],
  exports:  [

    SppressionCoursComponent
  ]
})
export class FormationModule { }
