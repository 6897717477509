import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from  'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
  })
};

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  SERVER_URL: string = "http://localhost:8000/api";

  constructor(private http: HttpClient, private router: Router, private httpClient: HttpClient) {}


  // get all documents :
  getDocuments(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Document/List', httpOptions);
  }

    // get all documents :
    getDocumentsByCours(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Cours/' + id + '/Documents', httpOptions);
    }


    // get all documents :
    getDocumentsByUser(id, idUser): Observable<any> {
      return this.http.get(environment.apiUrl + '/Document/ListDocumentsByUser/' + id + '/' + idUser);
    }



    // get all documents :
    getAllDocumentsByUser(idUser): Observable<any> {
      return this.http.get(environment.apiUrl + '/Document/ListAllDocumentsByUser/' + idUser);
    }

  // get all documents :
  getAllDocumentsByEnseigant(idUser): Observable<any> {
    return this.http.get(environment.apiUrl + '/Document/AllDocumentsByEnseignant/' + idUser);
  }

  // Create document :
  createDocument(data): Observable<any> {
    console.log(data);
    return this.http.post(environment.apiUrl + '/Document/Save', data, httpOptions);
  }

  public upload(data) {

    console.log(data.get('titre'));

    console.log('teeestt uplaod');

    return this.httpClient.post<any>(environment.apiUrl + '/Document/Save', data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }


  ////////////


  public updateDoc(data) {

    console.log(data.get('titre'));

    console.log('teeestt updaaaate');

    return this.httpClient.post<any>(environment.apiUrl + '/Document/Update', data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  // Delete document :
  deleteDocument(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Document/Delete/' + id);
  }
  // Archive document :
  archiveDocument(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Document/Archive/' + id);
  }
  
  // Restore document :
  restoreDocument(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Document/Restore/' + id);
  }

  // Edit document :
  editDocument(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Document/Update', data, httpOptions);
    }

  // get Document By id
    getDocumentById(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Document/' + id, httpOptions);
      }

  // get Cours By Niveau
  getDocumentsByNiveau(id,idNiveau): Observable<any> {
    return this.http.get(environment.apiUrl + '/Document/ListByNiveau/User/' +id+'/Niveau/'+idNiveau, httpOptions );
    }

  // get Cours By Module
   getDocumentsByModule(id,idModule): Observable<any> {
    return this.http.get(environment.apiUrl + '/Document/ListByModule/User/' + id+'/Module/'+idModule, httpOptions );
    }

  // get Module By Document
  getModuleByDocument(id): Observable<any> {
    return this.http.put(environment.apiUrl + '/Document/Update', id, httpOptions);
    }

  // get Enseignants By Document
  getEnseignantsByDocument(id): Observable<any> {
    return this.http.put(environment.apiUrl + '/Document/Update', id, httpOptions);
    }

    // get Documents By Document
  getDocument(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Document/'+id+'/Documents', httpOptions);
    }

     // get Documents By Document
  getRessources(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Document/'+id+'/Ressources', httpOptions);
    }

  // get Niveau By Document
  getNiveauByDocument(id): Observable<any> {
    return this.http.put(environment.apiUrl + '/Document/Update', id, httpOptions);
    }

    getDocumentsByElement(element, idUser): Observable<any> {
      return this.http.get(environment.apiUrl + '/Document/ListDocumentsByElement/' + element + '/' + idUser, httpOptions);
    }
       // get Mes Documents Archives - Enseignant
    getMesDocumentsArchives(id): Observable<any> {
      return this.http.get(environment.apiUrl + '/Document/MesDocumentsArchives/' + id);
    }

}

