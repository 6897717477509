import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ProfilService } from '../services/profil.service';
import { Etudiant } from '../models/etudiant.model';
import { EtudiantService } from '../services/etudiant.service';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from '../../formation/services/niveau.service';
import { FormationService } from '../../formation/services/formation.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { documentsUrl } from 'src/environments/environment';
//import { url } from 'inspector';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-etudiant',
  templateUrl: './etudiant.component.html',
  styleUrls: ['./etudiant.component.scss']
})
export class EtudiantComponent implements OnInit {

  invitationForm: FormGroup;
  error: string;
  public etudiantTypes:any=[
    {id:0,title:"EN COURS"},
    {id:1,title: "LAURÉAT"}
  ]
  userId: number = 1;
  uploadResponse = { status: '', message: '', filePath: '' };
  idUser = localStorage.getItem('id');
  role = this.route.snapshot.paramMap.get('profil');
  etudiants: Etudiant [] = [];
  i = 0;
  submitted = false;
  profil = localStorage.getItem('profil');
  userable_id = localStorage.getItem('userable_id');
  designation = 'designation';
  niveaux;
  formation = 'formation';
  formations;
  allStudents=[];
  etudiant : Etudiant;
  type="type";
  nom;
  imageDirectoryPath: any = 'http://localhost:8000/public/image/';
  documentUrl:string=documentsUrl;



  constructor(
    private profilService: ProfilService,
    //private toastr: ToastrService,
    private router : Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private etudiantService: EtudiantService,
    private toastr: ToastrService,
    private niveauService: NiveauService,
    private formationService: FormationService
  ) {
   }

  ngOnInit(): void {

    
    if (this.profil === 'ResponsableFormation') {

      this.etudiantService.getEtudiantsByResponsable(this.userable_id).subscribe(
        res => this.etudiants = res,

        err => console.log(err),
      );


      this.niveauService.getNiveauByRF(this.idUser).subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );


    } else if (this.profil === 'Administrateur') {

      this.etudiantService.getEtudiants().subscribe(
        res => {
          this.etudiants = res;
          this.allStudents=res
        },

        err => console.log(err),
      );


      this.formationService.getFormations().subscribe(

        res => this.formations = res,
        error => console.log(error),
      );


      this.niveauService.getNiveauxyByAdmin().subscribe(

        res => this.niveaux = res,
        error => console.log(error),
      );
    }

    this.invitationForm = this.formBuilder.group({
      file: ['', Validators.required],
      profil: [this.role, Validators.required]
    });

  }
  navigateToLauriatCreation(){
       localStorage.setItem("type","1")
       this.router.navigate(["/createEtudiant"]);
  }
  navigateToEtudiantCreation(){
    localStorage.setItem("type","0");

    this.router.navigate(["/createEtudiant"]);

  }
  searchEtudiantOrLauriat(){
    console.log(this.type);
    this.etudiants=this.allStudents.filter(student=>student.etudiant_type==this.type)
  }

  generatePdf(action = 'open',idEtudiant) {

    this.etudiantService.getEtudiantById(idEtudiant).subscribe(
      res => {

        this.etudiant = res;
        this.nom = this.etudiant.nom;
        console.log(this.nom)

        const documentDefinition = this.getDocumentDefinition(this.etudiant);
        switch (action) {
          case 'open': pdfMake.createPdf(documentDefinition).open(); break;
          case 'print': pdfMake.createPdf(documentDefinition).print(); break;
          case 'download': pdfMake.createPdf(documentDefinition).download(); break;
          default: pdfMake.createPdf(documentDefinition).open(); break;
        }
        console.log(res);


      },
      error => console.log(error),
    );

  }

  getDocumentDefinition(etudiant) {
    return {
      content: [
        {
          text: etudiant.nom + etudiant.prenom,
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [{
              text: 'Nom : ' + etudiant.nom,
              // image: [src] = (this.imageDirectoryPath+etudiant.image)
              style: 'test'
            },
            {
              text: 'Prenom : ' + etudiant.prenom,
            },
            {
              text: 'Code  : ' + etudiant.codeApogee,
            },
            {
              text: 'Code CNE : ' + this.etudiant.cne,
            },
            {
              text: 'Code CIN : ' + this.etudiant.cin,
            },
            {
              text: '    ',
            },
            ],

          ]
        },
        {
          text: 'Contact ',
          style: 'header'
        },

        {
          text: 'Tel : ' + this.etudiant.tel,
        },
        {
          text: 'Email : ' + this.etudiant.email,
        },
        {
          text: 'Image : ' + this.imageDirectoryPath + this.etudiant.image,
        },
      ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 20, 0, 10],
            decoration: 'underline'
          },
          name: {
            fontSize: 16,
            bold: true
          },
          test: {
            // background : url ='http://notrealdomain2.com/newbanner.png',
          },
          jobTitle: {
            fontSize: 14,
            bold: true,
            // italics: true
          },
          sign: {
            margin: [0, 50, 0, 10],
            alignment: 'right',
            italics: true
          },
          tableHeader: {
            bold: true,
          }
        }
    };
  }
/*

  getProfilePicObject() {
    if (this.etudiant.image) {
      return {
        image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJgAAACHCAYAAADqQ...AABJRU5ErkJggg==' ,
        width: 75,
        alignment : 'right'
      };
    }
    return {
      image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJgAAACHCAYAAADqQ...AABJRU5ErkJggg==' ,
      width: 75,
      alignment : 'right'
    };
  }

  */

  searchByFormtion(formation) {

    if (formation == null || formation === 'all' || this.formation === 'formation') {

      this.formation = 'all';

      console.log('alllll');

      this.ngOnInit();

       } else {

            this.formation = formation;

            this.etudiantService.getEtudiantsByFormation(formation).subscribe(
                res => this.etudiants = res,
                err => console.log(err),
              );

            this.niveauService.getNiveauxByFormation(formation).subscribe(
                res => this.niveaux = res,
                err => console.log(err),
              );

      }

  }


  searchByNiveauAdmin(designation) {


    if (this.formation === 'all' || this.formation === 'formation' || this.formation === null) {

      if (designation == null || designation === 'all' || designation === 'designation') {

        console.log('alllll');

        this.ngOnInit();

        } else {

                this.niveauService.getEtudiantsByNiveau(designation).subscribe(
                  res => this.etudiants = res,
                  err => console.log(err),
                );

        }
  } else {

      if (designation == null || designation === 'all' || designation === 'designation') {

        console.log('alllll');
        this.etudiantService.getEtudiantsByFormation(this.formation).subscribe(
            res => this.etudiants = res,
            err => console.log(err),
          );


        } else {

                this.niveauService.getEtudiantsByNiveau(designation).subscribe(
                  res => this.etudiants = res,
                  err => console.log(err),
                );

        }
  }

  }


  searchByNiveauResponsable(designation) {

    if (designation == null || designation === 'all' || designation === 'designation') {

        this.ngOnInit();

       } else {


        this.niveauService.getEtudiantsByNiveau(designation).subscribe(
          res => {
                console.log(res + 'Niveauuuuuuuuuuuuuuuuu');
                this.etudiants = res;
                console.log(this.etudiants + 'niveau_designation');

          },

          err => {
                console.log(err);
          }
        );

      }
  }






  onFileChange(event) {
    if (event.target.files.length > 0) {
      console.log(event);
      const file = event.target.files[0];
      let testFile = this.invitationForm.get('file').setValue(file);
      console.log(file);
      console.log(testFile);
    }
  }

  get f() { return this.invitationForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.invitationForm.invalid) {
      this.error = 'invalide Form  !';
      console.log(this.invitationForm);
      return;
    }

    const formData = new FormData();
    const filetest = formData.append('file', this.invitationForm.get('file').value);
    console.log(formData.get('file'));
    const profil = formData.append('profil', this.role);
    console.log(formData.get('profil'));

    this.profilService.sendInvitation(formData).subscribe(
      (res) => {
        console.log(res)
        this.uploadResponse = res;
        this.i = this.i + 1;
        console.log(res);
        console.log(this.i);
        if (this.i === 4) {
          this.showNotification('top', 'left', 'Les invitation ont été <b>bien envoyé</b>.')
        }
      },
      (err) => this.error = err
    );

  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }


    delete(id) {

      if (confirm('Êtes-vous sûr de vouloir supprimer cet etudiant?.')) {

        this.etudiantService.deleteEtudiant(id).subscribe(
          (data) => {
            this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
            // window.location.reload();
            this.ngOnInit();
          },
          (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

        );
      }

    }

    editSuspention(id) {

      this.etudiantService.editSuspention(id).subscribe(
        (data) => {

          console.log(data);
          this.showNotification('top', 'left', 'L\'état de gestion de cette formation a été <b>bien modifiée</b>.');
          // window.location.reload();
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produisé lors la suppression</b>.')

      );

  }
}
