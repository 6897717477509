import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LauriatService } from '../../services/lauriat.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NiveauService } from 'src/app/modules/formation/services/niveau.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-lauriat',
  templateUrl: './create-lauriat.component.html',
  styleUrls: ['./create-lauriat.component.scss']
})
export class CreateLauriatComponent implements OnInit {

  public lauriatForm: FormGroup;
  error: string;
  success: string;
  isButton: boolean = true;
  niveaux;
  lauriat:
    {

    };
  profil = localStorage.getItem('profil');
  userable_id = localStorage.getItem('userable_id');

  submitted = false;
  cinExist: number;
  message: '';
  etudiantInfo = {};

  idUser = localStorage.getItem('id');
  idEnseignant = localStorage.getItem('userable_id');
  niveau_id = 'niveau_id';

  constructor(
    private lauriatService: LauriatService,
    private niveauxService: NiveauService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
   
    if (this.profil === 'Administrateur') {

      niveauxService.getNiveaux().subscribe(
        res => {
          console.log(res);
          this.niveaux = res;
        },

        err => {
          console.log(err);
        }
      );
    } else if (this.profil === 'ResponsableFormation') {

      niveauxService.NiveauxByEnseignant(this.userable_id).subscribe(
        res => {
          console.log(res);
          this.niveaux = res;
        },

        err => {
          console.log(err);
        }
      );
    }


  }

  ngOnInit() {
       setTimeout(() => {
        this.lauriatService.getLauriatExperienceByEtudiantId(this.route.snapshot.params.id).subscribe((res: any) => {
          this.etudiantInfo = res[0];
        })
       }, 2000);
    // let email = localStorage.getItem('email');
    // console.log(email);



    this.success = null;
    this.error = null;
    this.lauriatForm = this.formBuilder.group({
      'date_debut': [null, [Validators.required]],
      'date_fin': [null, [Validators.required]],
      'etudiant_id': [this.route.snapshot.params.id, Validators.required],
      'experience': [null, [Validators.required]],
      'description': [null, [Validators.required]],
      'outils': [null, [Validators.required]],
    });
  }



  get f() { return this.lauriatForm.controls; }
  
  test(){alert("hello")}
  createLauriat() {
    console.log("clicked")
    this.submitted = true;



    const formData = this.lauriatForm.value;

    this.success = null;
    this.error = null;
    console.log("hello")
    console.log(formData)
   this.lauriatService.createLauriat(formData).subscribe(

      (data) => {
        this.showNotification('top', 'left', 'L\'experience a était sauvugardé</b>.')
        this.router.navigate(["/lauriat"])

      },

      (error) => error
    );





  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);

    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' + align
    });
  }
}
