import { Component, OnInit } from '@angular/core';
import { Formation } from '../../models/formation.model';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormationService } from '../../services/formation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Enseignant } from 'src/app/modules/profils/models/enseignant.model';
import { ProfilService } from 'src/app/modules/profils/services/profil.service';
import { ToastrService } from 'ngx-toastr';
import { EnseignantService } from 'src/app/modules/profils/services/enseignant.service';

@Component({
  selector: 'app-edit-formation',
  templateUrl: './edit-formation.component.html',
  styleUrls: ['./edit-formation.component.scss']
})
export class EditFormationComponent implements OnInit {

  idFormation = this.route.snapshot.paramMap.get('id');
  formation:Formation
  formationForm: FormGroup
  error:string
  success:string
  enseignant:Enseignant;
  cinExist:number;
  message: '';
  submitted = false;
  userable_id = localStorage.getItem('userable_id');
  profil = localStorage.getItem('profil');
  departement;
  responsableFormation;
  enseignants;
  niveau;

  constructor(
    private formationService: FormationService,
    private profilService: ProfilService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private enseignantService: EnseignantService,

    ) {


    }

  ngOnInit() {

    this.formationForm = this.formBuilder.group({
      'id': [this.idFormation, Validators.required],
      'code': [null,[Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'designation': [null, [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
      'parcours': [null, Validators.required],
      'responsableFormation': [null, [Validators.required]],
      'droitResponsableFormation': [null, Validators.required],

    });




    this.formationService.getFormationById(this.idFormation).subscribe(res => {

      this.formation= res;
      console.log(this.formation.droitResponsableFormation);
      this.profilService.getEnseignantById(this.formation.enseignant_id).subscribe(res => {

        this.enseignant= res;


        this.enseignantService.getEnseignants().subscribe(

          res => this.enseignants = res,
          error => console.log(error),
        );

        console.log(res);
        console.log(this.formation.enseignant_id);
        console.log('-----------------------------');
        console.log(this.enseignant);
        this.formationForm.patchValue({
          code: this.formation.code,
          designation: this.formation.designation,
          parcours: this.formation.parcours,
          nom: this.enseignant.nom,
          prenom: this.enseignant.prenom,
          responsableFormation: this.enseignant.id,
          droitResponsableFormation: this.formation.droitResponsableFormation,

        });


      });



    /*  this.formationForm.patchValue({
        code: this.formation.code,
        designation: this.formation.designation,
        parcours: this.formation.parcours,
       // responsableFormation: this.enseignant.responsableFormation,
        droitResponsableFormation: this.formation.droitResponsableFormation,

      });*/
    }, err => {
      console.log(err);
      this.error = 'Erreur survenue lors de la recuperation des informations du lien !';
    });






  }

  selectEnseignantByDepartement(departement) {
    this.enseignantService.getEnseignantsByDepartement(departement).subscribe(

      res => this.enseignants = res,
      error => console.log(error),
    );
};

  get f() { return this.formationForm.controls; }


  editFormation() {

    if (document.activeElement.id === 'submit') {

        this.submitted = true;
     

        const formData = this.formationForm.value;

        this.success = null;
        this.error = null;


        this.formationService.editFormation(formData).subscribe(

              (data) => {

                  this.showNotification('top', 'left', 'Les données sont <b>bien Modifiées</b>');
                  if (this.profil === 'ResponsableFormation') {

                    console.log(this.userable_id);
                    this.router.navigate(['/gererFormation', this.userable_id]);
                  } else {
                    this.router.navigate(['/gererFormation']);
                  }

              },

              (error) => {
                console.log(error.message);
                this.showNotification('top', 'left', 'Code Formation <b>dèja existe</b> , choisit un nouveau code');
            },
            );

        // this.formationService.editFormation(formData).subscribe();

        // this.router.navigate(['/gererFormation']);
      }

    }


    showNotification(from, align, message) {

      const color = Math.floor((Math.random() * 5) + 1);

      this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
        timeOut: 6000,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-' + from + '-' +  align
         });
      }


}
