import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CoursService } from '../../services/cours.service';
import { DocumentService } from '../../services/document.service';
import { RessourceService } from '../../services/ressource.service';
import  saveAs from 'file-saver';

@Component({
  selector: 'app-cours-archives',
  templateUrl: './cours-archives.component.html',
  styleUrls: ['./cours-archives.component.scss']
})
export class CoursArchivesComponent implements OnInit {

  id = localStorage.getItem('id');
  fichiers;
  type;

  constructor(private coursService: CoursService,
              private documentService: DocumentService,
              private ressourceService: RessourceService,
              private router: Router,
              private toastr: ToastrService) {
              }

  ngOnInit() {
    this.type ="cours";
    this.coursService.getMesCoursArchives(this.id).subscribe(res => {
      console.log(res);
      this.fichiers = res;

    }, err => {
      console.log(err);
    });
  }

  downloadFile(id, file_name) {
    this.coursService.downloadFile(id).subscribe(
      data => saveAs(data, file_name),
      error => console.error(error)
    );
}


  searchBy(type){
    if(type == "cours")
    {
      this.type ="cours";
      this.coursService.getMesCoursArchives(this.id).subscribe(res => {
        console.log(res);
        this.fichiers = res;
      }, err => {
        console.log(err);
      });

    } else if(type == "document"){

      this.type ="document";
      this.documentService.getMesDocumentsArchives(this.id).subscribe(res => {
        console.log(res);
        this.fichiers = res;

      }, err => {
        console.log(err);
      });

    }else{
      this.type ="ressource";
      this.ressourceService.getMesRessourcesArchives(this.id).subscribe(res => {
        console.log(res);
        this.fichiers = res;

      }, err => {
        console.log(err);
      });

    }
  }
  deleteCours(id) {

    if (confirm('Êtes-vous sûr de vouloir supprimer ce cours de façon permanente? \n Les documents et les ressources associés seront également supprimés.')) {

      this.coursService.deleteCours(id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produite lors la suppression</b>.')
      );
    }

  }

  deleteDocument(id) {

    if (confirm('Êtes-vous sûr de vouloir supprimer ce document de façon permanente?')) {

      this.documentService.deleteDocument(id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produite lors la suppression</b>.')
      );
    }

  }
  deleteRessource(id) {

    if (confirm('Êtes-vous sûr de vouloir supprimer ce document de façon permanente?')) {

      this.ressourceService.deleteRessource(id).subscribe(
        (data) => {
          this.showNotification('top', 'left', 'Les données sont <b>bien Supprimées</b>.');
          this.ngOnInit();
        },
        (error) => this.showNotification('top', 'left', 'Un erreur a été produite lors la suppression</b>.')
      );
    }

  }

  restoreCours(id){
    this.coursService.restoreCours(id).subscribe(
      (data) => {
        this.showNotification('top', 'left', 'Les données sont <b>bien réstaurées</b>.');
        this.ngOnInit();
      },
      (error) => this.showNotification('top', 'left', 'Un erreur a été produite lors de la restauration</b>.')
    );

  }

  restoreDocument(id){
    this.documentService.restoreDocument(id).subscribe(
      (data) => {
        this.showNotification('top', 'left', 'Les données sont <b>bien réstaurées</b>.');
        this.ngOnInit();
      },
      (error) => this.showNotification('top', 'left', 'Un erreur a été produite lors de la restauration</b>.')
    );
  }

  restoreRessource(id){
    this.ressourceService.restoreRessource(id).subscribe(
      (data) => {
        this.showNotification('top', 'left', 'Les données sont <b>bien réstaurées</b>.');
        this.ngOnInit();
      },
      (error) => this.showNotification('top', 'left', 'Un erreur a été produite lors de la restauration</b>.')
    );

  }

  showNotification(from, align, message) {

    const color = Math.floor((Math.random() * 5) + 1);
    this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span>' + message, '', {
      timeOut: 6000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-' + from + '-' +  align
       });
    }

}
