import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  email:String =localStorage.getItem('email');
  name =localStorage.getItem('name');
  profil =localStorage.getItem('profil');
  constructor() { }

  ngOnInit() {
  }

}
