import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consulter-cours-by-enseignant',
  templateUrl: './consulter-cours-by-enseignant.component.html',
  styleUrls: ['./consulter-cours-by-enseignant.component.scss']
})
export class ConsulterCoursByEnseignantComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
