import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { PostsComponent } from './modules/posts/posts.component';
import { BeforeLoginGuard } from './guards/before-login.guard';
import { LoginComponent } from './login/login.component';
import { AfterLoginGuard } from './guards/after-login.guard';
import { RoleGuardService  } from './services/role-guard.service';
import { AdministrateurComponent } from './modules/profils/administrateur/administrateur.component';


import { EnseignantComponent } from './modules/profils/enseignant/enseignant.component';
import { EtudiantComponent } from './modules/profils/etudiant/etudiant.component';
import { CoursComponent } from './modules/formation/courss/cours/cours.component';
import { SppressionCoursComponent } from './modules/formation/courss/sppression-cours/sppression-cours.component';
import { ConsultationCoursComponent } from './modules/formation/courss/consultation-cours/consultation-cours.component';
import { ConsulterCoursByEnseignantComponent } from './modules/formation/courss/consulter-cours-by-enseignant/consulter-cours-by-enseignant.component';
import { ConsulterCoursByNiveauComponent } from './modules/formation/courss/consulter-cours-by-niveau/consulter-cours-by-niveau.component';
import { ConsulterCoursByModuleComponent } from './modules/formation/courss/consulter-cours-by-module/consulter-cours-by-module.component';
import { CreateCoursComponent } from './modules/formation/courss/create-cours/create-cours.component';
import { CreateDocumentComponent } from './modules/formation/document/create-document/create-document.component';
import { RequestPasswordRessetComponent } from './login/resetPassword/request-password-resset/request-password-resset.component';

import { ResponsePasswordResetComponent } from './login/responsePasswordReset/response-password-reset/response-password-reset.component';
import { GererCoursComponent } from './modules/formation/courss/gerer-cours/gerer-cours.component';
import { CreateRessourceComponent } from './modules/formation/ressource/create-ressource/create-ressource.component';
import { GererRessourceComponent } from './modules/formation/ressource/gerer-ressource/gerer-ressource.component';
import { EditRessourceComponent } from './modules/formation/ressource/edit-ressource/edit-ressource.component';
import { EditCoursComponent } from './modules/formation/courss/edit-cours/edit-cours.component';
import { GererDocumentsComponent } from './modules/formation/document/gerer-documents/gerer-documents/gerer-documents.component';
import { EditDocumentComponent } from './modules/formation/document/edit-document/edit-document.component';
import { SendInvitationComponent } from './modules/profils/send-invitation/send-invitation.component';
import { ResponseInvitationComponent } from './modules/profils/response-invitation/response-invitation.component';
import { CreateFormationComponent } from './modules/formation/formation/create-formation/create-formation.component';
import { CreateNiveauComponent } from './modules/formation/niveau/create-niveau/create-niveau.component';
import { GererNiveauComponent } from './modules/formation/niveau/gerer-niveau/gerer-niveau.component';
import { EditNiveauComponent } from './modules/formation/niveau/edit-niveau/edit-niveau.component';
import { ConsultationNiveauComponent } from './modules/formation/niveau/consultation-niveau/consultation-niveau.component';
import { CreateElementComponent } from './modules/formation/element/create-element/create-element.component';
import { GererElementComponent } from './modules/formation/element/gerer-element/gerer-element.component';
import { CreateModuleComponent } from './modules/formation/module/create-module/create-module.component';
import { GererFormationComponent } from './modules/formation/formation/gerer-formation/gerer-formation.component';
import { EditFormationComponent } from './modules/formation/formation/edit-formation/edit-formation.component';
import { GererModuleComponent } from './modules/formation/module/gerer-module/gerer-module.component';
import { EditModuleComponent } from './modules/formation/module/edit-module/edit-module.component';
import { ConsultationModuleComponent } from './modules/formation/module/consultation-module/consultation-module.component';
import { EditElementComponent } from './modules/formation/element/edit-element/edit-element.component';
import { MesCoursComponent } from './modules/formation/courss/mes-cours/mes-cours.component';
import { AdministratifComponent } from './modules/profils/administratif/administratif.component';
import { CreateEtudiantComponent } from './modules/profils/etudiant/create-etudiant/create-etudiant.component';
import { EditProfilComponent } from './modules/profils/edit-profil/edit-profil.component';
import { EditEtudiantComponent } from './modules/profils/etudiant/edit-etudiant/edit-etudiant.component';
import { CreateEnseignantComponent } from './modules/profils/enseignant/create-enseignant/create-enseignant.component';
import { EditEnseignantComponent } from './modules/profils/enseignant/edit-enseignant/edit-enseignant.component';
import { EditAdministratifComponent } from './modules/profils/administratif/edit-administratif/edit-administratif.component';
import { CreateAdministratifComponent } from './modules/profils/administratif/create-administratif/create-administratif.component';
import { ListDocumentsComponent } from './modules/formation/document/list-documents/list-documents.component';
import { MesDocumentsComponent } from './modules/formation/document/mes-documents/mes-documents.component';
import { ListRessourcesComponent } from './modules/formation/ressource/list-ressources/list-ressources.component';
import { MesRessourcesComponent } from './modules/formation/ressource/mes-ressources/mes-ressources.component';
import { AjoutDocumentComponent } from './modules/formation/document/ajout-document/ajout-document.component';
import { EditPasswordComponent } from './modules/profils/edit-password/edit-password.component';
import { EditProjetComponent } from './modules/projet/edit-projet/edit-projet.component';
import { CreateProjetComponent } from './modules/projet/create-projet/create-projet.component';
import { EditSoutenanceComponent } from './modules/projet/edit-soutenance/edit-soutenance.component';
import { CreateSoutenanceComponent } from './modules/projet/create-soutenance/create-soutenance.component';
import { GererProjetsComponent } from './modules/projet/gerer-projets/gerer-projets.component';
import { GererSoutenancesComponent } from './modules/projet/gerer-soutenances/gerer-soutenances.component';
import { AjouRapportComponent } from './modules/projet/ajou-rapport/ajou-rapport.component';
import { ImportRapportComponent } from './modules/projet/import-rapport/import-rapport.component';
import { ConsultationSoutenancesComponent } from './modules/projet/consultation-soutenances/consultation-soutenances.component';
import { ContactUsComponent } from './modules/contact-us/contact-us.component';
import { SavoirPlusComponent } from './modules/savoir-plus/savoir-plus.component';
import { GererMesCoursComponent } from './modules/formation/courss/gerer-mes-cours/gerer-mes-cours.component';
import { CoursArchivesComponent } from './modules/formation/courss/cours-archives/cours-archives.component';
import { ConsultationProjetsComponent } from './modules/projet/consultation-projets/consultation-projets.component';
import { ImageEtudiantComponent } from './modules/profils/etudiant/image-etudiant/image-etudiant.component';
import { LauriatComponent } from './modules/profils/lauriat/lauriat.component';
import {
    CreateLauriatComponent
} from './modules/profils/lauriat/create-lauriat/create-lauriat.component';
import {
    EditLauriatComponent
} from './modules/profils/lauriat/edit-lauriat/edit-lauriat.component';
import { LandingComponent } from './shared/components/landing/landing.component';
import { ListFormationComponent } from './modules/formation/formation/list-formation/list-formation.component';
import { GererOffreComponent } from './modules/profils/offre/offre/gerer-offre/gerer-offre.component';
import { EditOffreComponent } from './modules/profils/offre/offre/edit-offre/edit-offre.component';
import { CreateOffreComponent } from './modules/profils/offre/offre/create-offre/create-offre.component';
import { Landing2Component } from './shared/components/landing2/landing2.component';
import { Landing3Component } from './shared/components/landing3/landing3.component';
import { Landing4Component } from './shared/components/landing4/landing4.component';

const routes: Routes = [

//////// gestion login ::::::::::

{
  path: '',
  redirectTo: 'landing',
  pathMatch: 'full',
  canActivate: [BeforeLoginGuard]
},



{
  path: 'login',
  component: LoginComponent,
  canActivate: [BeforeLoginGuard]

  },
  {
    path: 'annonces',
    component: Landing2Component,
    canActivate: [BeforeLoginGuard]
  
    },
    {
      path: 'ensiliens',
      component: Landing4Component,
      canActivate: [BeforeLoginGuard]
    
      },
    {
      path: 'recruteurs',
      component: Landing3Component,
      canActivate: [BeforeLoginGuard]
    
      },
  {
    path: 'landing',
    component: LandingComponent,
    canActivate: [BeforeLoginGuard]
  
    },


  {
    path: 'requestPassword',
    component: RequestPasswordRessetComponent,
    canActivate: [BeforeLoginGuard]

  },

  {
    path: 'responsePassword',
    component: ResponsePasswordResetComponent,
    canActivate: [BeforeLoginGuard]
  },

  {
    path: 'responseInvitation',
    component: ResponseInvitationComponent,
    canActivate: [BeforeLoginGuard]

  },

      {
        path: 'sendInvitation/:profil',
        component: SendInvitationComponent,
        canActivate: [AfterLoginGuard],
        data: {
          expectedRole: ['Administrateur', 'ResponsableFormation']
        }
      },

// ------------------------------- End gestion login ::::::::::


  {
    path: '',
    component: DefaultComponent,
    children: [{
      path: 'dashboard',
      component: GererFormationComponent,
     canActivate: [AfterLoginGuard],
      data: {
        expectedRole: ['Administrateur', 'Enseignant', 'Etudiant', 'Administratif', 'ResponsableFormation']
      }

    },

    {
      path: 'posts',
      component: PostsComponent,
      canActivate: [AfterLoginGuard],
      data: {
        expectedRole: ['Administrateur']
      }
    },

    {
      path: 'contact-us',
      component: ContactUsComponent,
      canActivate: [AfterLoginGuard],
      data: {
        expectedRole: ['Administrateur', 'Enseignant', 'Etudiant', 'ResponsableFormation']
      }
    },

    {
      path: 'savoir-plus',
      component: SavoirPlusComponent,
      canActivate: [AfterLoginGuard],
      data: {
        expectedRole: ['Administrateur', 'Enseignant', 'Etudiant', 'ResponsableFormation']
      }
    },


    {
      path: 'editProfil',
      component: EditProfilComponent,
      canActivate: [AfterLoginGuard],
      data: {
        expectedRole: ['Administrateur', 'Enseignant','Etudiant','ResponsableFormation']
      }

      },

      {
        path: 'editPassword',
        component: EditPasswordComponent,
        canActivate: [AfterLoginGuard],
        data: {
          expectedRole: ['Administrateur', 'Enseignant','Etudiant','ResponsableFormation']
        }

        },



//////// gestion profils-Etudiant ::::::::::


  {
    path: 'etudiants/:profil',
    component: EtudiantComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'ResponsableFormation']
    }

    },

  {
    path: 'createEtudiant',
    component: CreateEtudiantComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'ResponsableFormation']
    }

  },

  {
    path: 'editEtudiant/:id',
    component: EditEtudiantComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'ResponsableFormation']
    }

  },
  {
    path: 'lauriat',
    component: LauriatComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'ResponsableFormation']
    }

  },

  {
    path: 'createLauriat/:id',
    component: CreateLauriatComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'ResponsableFormation']
    }

  },
  {
    path: 'editLauriat/:id',
    component: EditLauriatComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'ResponsableFormation']
    }

  },


  {
    path: 'etudiantImage/:id',
    component: ImageEtudiantComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'ResponsableFormation']
    }

  },

// ------------------------------- End gestion profils-Etudiant ::::::::::




//////// gestion profils-enseignants ::::::::::


{
  path: 'enseignants/:profil',
  component: EnseignantComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'ResponsableFormation']
  }

},

{
  path: 'createEnseignant',
  component: CreateEnseignantComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'ResponsableFormation']
  }

},

{
  path: 'editEnseignant/:id',
  component: EditEnseignantComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }

},

// ------------------------------- End gestion profils-enseignants ::::::::::




//////// gestion profils-administratifs ::::::::::


  {
    path: 'administratifs/:profil',
    component: AdministratifComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur']
    }

    },


    {
      path: 'createAdministratif',
      component: CreateAdministratifComponent,
      canActivate: [AfterLoginGuard],
      data: {
        expectedRole: ['Administrateur']
      }

    },

    {
      path: 'editAdministratif/:id',
      component: EditAdministratifComponent,
      canActivate: [AfterLoginGuard],
      data: {
        expectedRole: ['Administrateur']
      }

    },
// ------------------------------- End gestion profils-administratifs ::::::::::


//////// gestion cours ::::::::::

  {
    path: 'cours/:id',
    component: CoursComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'Enseignant', 'Etudiant', 'Administratif', 'ResponsableFormation']
    }

    },

    {
      path: 'cours',
      component: CoursComponent,
      canActivate: [AfterLoginGuard],
      data: {
        expectedRole: ['Administrateur', 'Enseignant', 'Etudiant', 'Administratif', 'ResponsableFormation']
      }

      },


    {
      path: 'createCours',
      component: CreateCoursComponent,
      canActivate: [AfterLoginGuard],
      data: {
        expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
      }

   },

   {
    path: 'editCours/:id',
    component: EditCoursComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
    }

 },

 {
  path: 'gestionCours/:id',
  component: GererCoursComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }
},

{
  path: 'gestionMesCours',
  component: GererMesCoursComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Enseignant','ResponsableFormation']
  }
},


{
  path: 'consulterCoursByModule',
  component: ConsulterCoursByModuleComponent,
  canActivate: [AfterLoginGuard]

},

{
  path: 'consulterCoursByNiveau',
  component: ConsulterCoursByNiveauComponent,
  canActivate: [AfterLoginGuard]

},

{
  path: 'consulterCoursByEnseignant',
  component: ConsulterCoursByEnseignantComponent,
  canActivate: [AfterLoginGuard]

  },

{
  path: 'consulterCours/:id',
  component: ConsultationCoursComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'Etudiant', 'Administratif', 'ResponsableFormation']
  }},

  {
    path: 'mesCours',
    component: MesCoursComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Etudiant']
    }

  },

  {
    path: 'MesCours',
    component: SppressionCoursComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
    }

    },

    {
      path: 'MesCoursArchives',
      component: CoursArchivesComponent,
      canActivate: [AfterLoginGuard],
      data: {
        expectedRole: ['Enseignant','ResponsableFormation']
      }

      },


// ------------------------------- End gestion cours ::::::::::


//////// gestion documents ::::::::::

   {
    path: 'createDocument/:titre/:id',
    component: CreateDocumentComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
    }

 },

 {
  path: 'AjoutDocument',
  component: AjoutDocumentComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }

},


 {
  path: 'createDocument/:titre/:idCours',
  component: CreateDocumentComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }
},

{
  path: 'gestionDocuments/:id',
  component: GererDocumentsComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }
},

{
  path: 'listeDocuments',
  component: ListDocumentsComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation', 'Etudiant']
  }
},

{
  path: 'mesDocuments',
  component: MesDocumentsComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Enseignant', 'ResponsableFormation']
  }
},

{
  path: 'editDocument/:id',
  component: EditDocumentComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }

},

// ------------------------------- End gestion cours ::::::::::


//////// gestion ressources ::::::::::

 {
  path: 'createRessource/:titre/:id',
  component: CreateRessourceComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }

},

 {
  path: 'createRessource/:id',
  component: CreateRessourceComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }

},
{
  path: 'gererRessource/:id',
  component: GererRessourceComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }

},


{
  path: 'listeRessources',
  component: ListRessourcesComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation', 'Etudiant']
  }
},

{
  path: 'mesRessources',
  component: MesRessourcesComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Enseignant', 'ResponsableFormation']
  }
},

{
  path: 'editRessource/:id',
  component: EditRessourceComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }

},

// ------------------------------- End gestion ressources ::::::::::


//////// gestion formations ::::::::::


{
    path: 'createFormation',
    component: CreateFormationComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur']
    }

},

{
  path: 'gererFormation',
  component: GererFormationComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur']
},

},
{
  path: 'gererFormation/:id',
  component: GererFormationComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['ResponsableFormation']
},

},

{
  path: 'editFormation/:id',
  component: EditFormationComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur']
  }

},



// ------------------------------- End gestion formations ::::::::::

//////// gestion offres ::::::::::


{
  path: 'createOffre',
  component: CreateOffreComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur']
  }

},

{
path: 'gererOffre',
component: GererOffreComponent,
canActivate: [AfterLoginGuard],
data: {
  expectedRole: ['Administrateur']
},

},
{
path: 'gererOffre/:id',
component: GererOffreComponent,
canActivate: [AfterLoginGuard],
data: {
  expectedRole: ['Administrateur']
},

},

{
path: 'editOffre/:id',
component: EditOffreComponent,
canActivate: [AfterLoginGuard],
data: {
  expectedRole: ['Administrateur']
}

},



// ------------------------------- End gestion Offres ::::::::::
//////// gestion niveaux ::::::::::

{
  path: 'createNiveau/:code',
  component: CreateNiveauComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur']
  }
},

{
  path: 'createNiveau/:code/:id',
  component: CreateNiveauComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur']
  }
},

{
  path: 'gererNiveau/:idFormation',
  component: GererNiveauComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'ResponsableFormation']
  }

},

{
  path: 'editNiveau/:id',
  component: EditNiveauComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'ResponsableFormation']
  }

},

{
  path: 'consulterNiveaux/:id',
  component: ConsultationNiveauComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'Etudiant', 'Administratif', 'ResponsableFormation']
  }

},

// ------------------------------- End gestion niveaux ::::::::::


//////// gestion modules ::::::::::

{
  path: 'createModule/:id',
  component: CreateModuleComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'ResponsableFormation']
  }
},

{
  path: 'gestionModules/:idNiveau',
  component: GererModuleComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'ResponsableFormation']
  }

},

{
  path: 'editModule/:id',
  component: EditModuleComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'ResponsableFormation']
  }

},

{
  path: 'consulterModule/:id',
  component: ConsultationModuleComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'Etudiant', 'Administratif', 'ResponsableFormation']
  }

},

// ------------------------------- End gestion modules ::::::::::

//////// gestion elements ::::::::::

{
  path: 'createElement/:code',
  component: CreateElementComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'ResponsableFormation']
  }
},

{
  path: 'gererElement/:id',
  component: GererElementComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }
},

{
  path: 'editElement/:id',
  component: EditElementComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }

},

{
  path: 'consulterElement/:id',
  component: CreateElementComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'Etudiant', 'Administratif', 'ResponsableFormation']
  }

},

// ------------------------------- End gestion elements ::::::::::







//////// gestion Projets ::::::::::


{
  path: 'createProjet',
  component: CreateProjetComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Etudiant', 'ResponsableFormation', 'Administrateur']
  }

},


{
path: 'editProjet/:id',
component: EditProjetComponent,
canActivate: [AfterLoginGuard],
data: {
  expectedRole: ['Etudiant', 'ResponsableFormation', 'Administrateur']
}

},


{
  path: 'gererProjets',
  component: GererProjetsComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }

  },

  {
    path: 'importerProjet/:idProjet',
    component: ImportRapportComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Etudiant']
    }

    },



{
  path: 'AjoutRapport',
  component: AjouRapportComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Etudiant']
  }

},

{
  path: 'consultationProjets',
  component: ConsultationProjetsComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }

  },

//// ---------------- Soutenances


{
  path: 'createSoutenance',
  component: CreateSoutenanceComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'ResponsableFormation', 'Enseignant']
  }

},


{
path: 'editSoutenance/:id',
component: EditSoutenanceComponent,
canActivate: [AfterLoginGuard],
data: {
  expectedRole: ['Administrateur', 'ResponsableFormation', 'Enseignant']
}

},


{
  path: 'gererSoutenances',
  component: GererSoutenancesComponent,
  canActivate: [AfterLoginGuard],
  data: {
    expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation']
  }

  },


  {
    path: 'consultationSoutenances',
    component: ConsultationSoutenancesComponent,
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'Enseignant', 'ResponsableFormation','Etudiant']
    }

    },
// ------------------------------- End gestion Projets ::::::::::


    {
      path: 'administrateur',
      component: AdministrateurComponent,
      canActivate: [AfterLoginGuard]

      },



    ],
    canActivate: [AfterLoginGuard],
    data: {
      expectedRole: ['Administrateur', 'Enseignant', 'Etudiant', 'Administratif', 'ResponsableFormation']
    }
  },

  {
    path: '**',
    redirectTo: 'login',
    canActivate: [BeforeLoginGuard]

  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
