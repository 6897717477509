
export const FORMATION = "formaiton";
export const NIVEAU = "niveau";
export const LAURIAT = "lauriat";

let filterSubscribers = [];



export const setFilterItemInStorage = (filterItemName, filterItemValue) => {
    if (!checkItemInArray(filterSubscribers, filterItemName))
        filterSubscribers.push(filterItemName);
    localStorage.setItem(filterItemName, filterItemValue);
}

export const getFilterItemInStorage = (filterItemName) => {
    return localStorage.getItem(filterItemName) ? localStorage.getItem(filterItemName) : undefined;
}


export const unsubscribeFromFilterStorage = () => {
    for (let filterItem of filterSubscribers) {
        localStorage.removeItem(filterItem);
    }
}

const checkItemInArray = (array, itemToCheck) => {
    for (let item of array) {
        if (item == itemToCheck) {
            return true;
        }
    }
    return false;
}