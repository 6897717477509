import { Component, OnInit } from '@angular/core';
import { CoursService } from '../../services/cours.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Cours } from '../../models/cours.model';
import { HttpModule } from '@angular/http';
@Component({
  selector: 'app-mes-cours',
  templateUrl: './mes-cours.component.html',
  styleUrls: ['./mes-cours.component.scss']
})
export class MesCoursComponent implements OnInit {

  id= localStorage.getItem('userable_id');
  cours: Cours [] = [];
  constructor(private coursService: CoursService,
    private router: Router,
   // private moduleService: ModuleService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    // private location: Location
    ) { }

  ngOnInit() {
    this.coursService.getCoursByEtudiant(this.id).subscribe(res => {
      //console.log(designation);
      console.log(this.id + "get mes cours argument");

      console.log(res);

      this.cours = res;

    }, err => {
      console.log(err);


    });
  }

  onSelect(cour) {

    this.router.navigate(['/consulterCours', cour.id]);

  }

}
