import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
	})
};

@Injectable({
  providedIn: 'root'
})
export class AdministratifService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }


  // get all Administratifs :
  getAdministratifs(): Observable<any> {
    return this.http.get(environment.apiUrl + '/Administratif/List');
  }


  // Create Administratifs :
  createAdministratif(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/Administratif/Save', data);
  }

  // Delete Administratifs :
  deleteAdministratif(id): Observable<any> {
    return this.http.delete(environment.apiUrl + '/Administratif/Delete/'+ id);
  }

  // Edit Administratifs :
  editAdministratif(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/Administratif/Update', data);
    }

    editProfil(data): Observable<any> {
      return this.http.put(environment.apiUrl + '/Administratif/Edit', data);
      }

  // get Administratif By id
  getAdministratifById(id): Observable<any> {
    return this.http.get(environment.apiUrl + '/Administratif/' + id, httpOptions);
    }

}
